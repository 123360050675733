import { Chip, IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as datefns from "date-fns";
import { useState } from "react";
import { Consortium } from "../../API";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
interface Props {
  consortium: Consortium;
  children?: React.ReactNode;
}

export default function GatewayAppBar({ consortium, children }: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div className="gateway-app-bar-scrollable">
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Tipo</th>
              <th>Batteria</th>
              <th>Segnale</th>
              <th>Firmware</th>

              <th>AutoTest</th>
              <th>Tens.Batteria</th>
              <th>Corr.Carico</th>
              <th>Tens.Solare</th>
              <th>Corr.Solare</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {consortium?.gatewayLatestComm &&
                datefns.differenceInMinutes(
                  new Date(),
                  new Date(consortium.gatewayLatestComm)
                ) < 10 ? (
                  <Chip color="success" label={consortium.gatewayStatus} />
                ) : (
                  <Chip color="warning" label="Offline" />
                )}
              </td>
              <td>
                <div>{consortium.gatewayConnectionType}</div>
              </td>
              <td>
                <div>{consortium.gatewayBattery}</div>
              </td>
              <td>
                <div>{consortium.gatewaySignal}</div>
              </td>
              <td>
                <div>{consortium.gatewayLocalFirmware}</div>
              </td>
              <td>
                <div>{consortium.gatewayAutotest}</div>
              </td>
              <td>
                <div>{consortium.gatewayBatteryTension}</div>
              </td>
              <td>
                <div>{consortium.gatewayBatteryCurrent}</div>
              </td>
              <td>
                <div>{consortium.gatewaySolarTension}</div>
              </td>
              <td>
                <div>{consortium.gatewaySolarCurrent}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <ExpandMore
        expand={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <Icon icon="eva:arrow-ios-downward-outline" />
      </ExpandMore>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <table>
          <thead>
            <tr />
          </thead>
          <tbody>
            <tr />
          </tbody>
        </table>
        {children}
      </Collapse> */}
    </div>
  );
}
