/* eslint-disable react/jsx-props-no-spreading */
import arrowRightFill from "@iconify/icons-eva/arrow-ios-back-fill";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as datefns from "date-fns";
import it from "date-fns/locale/it";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import type {
  CreateNodeOpeningsMutation,
  CreateNodeOpeningsMutationVariables,
  CreateProgramMutation,
  CreateProgramMutationVariables,
  DeleteProgramMutationVariables,
  Node,
  NodeOpenings,
  Program,
} from "../../../API";
import {
  createNodeOpenings,
  createProgram,
  deleteProgram,
} from "../../../graphql/mutations";
import type { RootState } from "../../../store";
import { gqlOperation, setDefaultProgram } from "../../../store/queries";
import useShowMessage from "../../../utils/hooks";
import { GridColumn } from "../../../utils/types";

interface SingleProps {
  outlinedNode: NewNodeOpeningsType | undefined;
  onClose: () => any;
  onAddNewOpeningHours: (hours: string[]) => any;
}

interface RowType {
  id: string;
}

const columns: GridColumn<RowType>[] = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   align: "center",
  //   headerAlign: "center",
  //   hideSortIcons: true,
  // },
  {
    field: "id",
    headerName: "Orario",
    align: "center",
    headerAlign: "center",
    hideSortIcons: true,
    renderCell: ({ row }) => row.id,
  },
  {
    field: "",
    headerName: "Durata",
    align: "center",
    headerAlign: "center",
    hideSortIcons: true,
    renderCell: ({ row }) => {
      const op = row.id.split("-");
      const diffInMinutes = datefns.differenceInMinutes(
        datefns.parse(op[1], "HH:mm", new Date()),
        datefns.parse(op[0], "HH:mm", new Date())
      );
      return `${diffInMinutes} min`;
    },
  },
];

function SingleNodeDrawer({
  outlinedNode,
  onClose,
  onAddNewOpeningHours,
}: SingleProps) {
  const [openings, setOpenings] = useState<string[]>(
    outlinedNode?.nodeProgram || []
  );
  const showMessage = useShowMessage();
  const formik = useFormik({
    initialValues: {
      start: null,
      end: null,
    },
    validationSchema: Yup.object({
      start: Yup.date().required(),
      end: Yup.date()
        .required()
        .when(["start"], (start) =>
          Yup.date().test({
            message:
              "Orario fine deve essere dopo l'inizio e con minima durata di 15min.",
            test: (val) => {
              try {
                if (!val) return true;

                // return (
                //   timeEnd.isValid() &&
                //   timeStart.isValid() &&
                //   timeEnd.isAfter(timeStart) &&
                //   timeEnd.diff(timeStart, "minutes") >= 15
                // );

                return (
                  datefns.isAfter(val, start) &&
                  datefns.differenceInMinutes(val, start) >= 15
                );
              } catch {
                return false;
              }
            },
          })
        ),
    }),
    onSubmit: (values, actions) => {
      try {
        const newOp = `${datefns.format(
          values.start!,
          "HH:mm"
        )}-${datefns.format(values.end!, "HH:mm")}`;
        if (openings.includes(newOp)) {
          // actions.setFieldError("start", "Orario già esistente");
          showMessage("Apertura già esistente", "error");
          return;
        }
        setOpenings(openings.concat([newOp]));
        // setTimeout(() => {
        showMessage("Orario aggiunto con successo", "success");
      } finally {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    },
  });
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <>
      <Stack direction="row" justifyContent="flex-start">
        <IconButton
          onClick={() => {
            setOpenings([]);
            onClose();
          }}
          color="primary"
          size="medium"
          style={{ width: "50px", height: "50px" }}
          sx={{ m: 1 }}
        >
          <Icon icon={arrowRightFill} />
        </IconButton>
        <CardHeader
          title={outlinedNode?.node?.title}
          subheader="I seguenti orari sono le aperture e chiusure programmate"
        />
      </Stack>
      {outlinedNode && (
        <CardContent>
          <DataGrid
            rows={openings.map((oh) => ({ id: oh }))}
            columns={[
              ...columns,
              {
                field: "delete",
                align: "center",
                headerAlign: "center",
                headerName: "",
                width: 50,
                hideSortIcons: true,
                renderCell: ({ row }) => (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenings(openings.filter((o) => o !== row.id));
                    }}
                  >
                    <Icon icon="dashicons:trash" />
                  </IconButton>
                ),
              },
            ]}
            getRowId={(row) => row.id}
            disableColumnFilter
            disableColumnMenu
            autoHeight
            disableColumnSelector
            disableSelectionOnClick
            disableDensitySelector
            showCellRightBorder
            hideFooter
          />

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <FieldArray name="openings" />
              <Stack sx={{ mt: 4, mb: 4 }} spacing={2} direction="row">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <DesktopTimePicker
                    ampm={false}
                    value={values.start}
                    onChange={(newValue: Date | null) => {
                      if (!newValue) return;
                      setFieldValue("start", newValue);
                      // check if newValue is <= 23:00
                      if (newValue.getHours() < 23) {
                        setFieldValue(
                          "end",
                          datefns.add(newValue, { hours: 1 })
                        );
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Inizio"
                        fullWidth
                        size="small"
                        error={Boolean(touched.start && errors.start)}
                        helperText={touched.start && errors.start}
                      />
                    )}
                  />
                  <DesktopTimePicker
                    ampm={false}
                    value={values.end}
                    onChange={(newValue: Date | null) => {
                      if (!newValue) return;
                      if (newValue.getHours() === 0)
                        setFieldValue("end", "23:59");
                      else setFieldValue("end", newValue);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Fine"
                        fullWidth
                        size="small"
                        error={Boolean(touched.end && errors.end)}
                        helperText={touched.end && errors.end}
                      />
                    )}
                  />
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                  />
                </LocalizationProvider>
                {/* <Typography>
                  Durata{" "}
                  {dayjs(values.end, "HH:mm").diff(
                    dayjs(values.start, "HH:mm"),
                    "m"
                  )}
                  min
                </Typography> */}
              </Stack>

              <ButtonGroup fullWidth>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  // startIcon={<Icon icon={plusFill} />}
                  loading={isSubmitting}
                  onClick={() => {
                    onAddNewOpeningHours(openings);
                    // setOpenings([]);
                  }}
                >
                  Salva
                </LoadingButton>
                <Button
                  variant="outlined"
                  startIcon={<Icon icon="dashicons:trash" />}
                  onClick={() => {
                    setOpenings([]);
                  }}
                >
                  Resetta
                </Button>
              </ButtonGroup>
            </Form>
          </FormikProvider>
        </CardContent>
      )}
    </>
  );
}

interface NewNodeOpeningsType
  extends Clear<Omit<NodeOpenings, "nodeProgram" | "node">> {
  nodeProgram: string[];
  node: { id: string; idxSensor: number; title: string };
  idx: number;
}

export interface NewProgramType
  extends DeepPartial<Omit<Program, "nodesOpenings">> {
  nodesOpenings: NewNodeOpeningsType[];
  isDefault: boolean;
}

interface Props {
  onClose: any;
  outlinedObject?: Partial<NewProgramType>;
}

// function singleNodeFormikOptions(
//   openings: string[],
//   showMessage: any,
//   setOpenings: any
// ): FormikConfig<any> {
//   return {}
// }

export default function NewProgramDrawer({ onClose, outlinedObject }: Props) {
  const showMessage = useShowMessage();
  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  )!;
  const ProgramSchema = Yup.object().shape({
    title: Yup.string().required("Inserire titolo"),
    isDefault: Yup.boolean().required(""),
  });

  const formik = useFormik<NewProgramType>({
    initialValues: {
      title: "1 ora al giorno",
      isRunning: false,
      isDefault: false,
      nodesOpenings: [],
      ...outlinedObject,
    },
    validationSchema: ProgramSchema,
    onSubmit: async (values, actions) => {
      try {
        console.log("Submitted  form", values);
        if (values.id) {
          console.log("Deleting program to recreate it...");
          await gqlOperation(deleteProgram, {
            input: {
              id: values.id,
            },
          } as DeleteProgramMutationVariables);
        }
        const savedProgram = await gqlOperation(
          createProgram as CreateProgramMutation,
          {
            input: {
              // id: values.id,
              title: values.title,
              isRunning: values.isRunning,
              // isDefault: values.isDefault,
              consortiumID: currentConsortium.id,
            },
          } as CreateProgramMutationVariables
        );

        await Promise.all(
          values.nodesOpenings.map(async (nop) => {
            const savedNodeOpening = await gqlOperation(
              createNodeOpenings as CreateNodeOpeningsMutation,
              {
                input: {
                  nodeOpeningsNodeId: nop?.node?.id,
                  nodeProgram: nop.nodeProgram,
                  programID: savedProgram.createProgram?.id,
                },
              } as CreateNodeOpeningsMutationVariables
            );
          })
        );

        console.log("savvedProgram", savedProgram, values.isDefault, {
          id: currentConsortium.id,
          consortiumDefaultProgramId: savedProgram?.createProgram?.id,
        });

        // if default disable all others
        if (values.isDefault && savedProgram) {
          setDefaultProgram(savedProgram?.createProgram?.id!);
        } else if (currentConsortium.defaultProgram?.id === values.id) {
          setDefaultProgram(null);
        }

        showMessage("Programma memorizzato con successo", "success");
        actions.resetForm();
      } finally {
        actions.setSubmitting(false);
        onClose();
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  const [outlinedNodeOpening, setOutlinedNodeOpening] = useState<
    Clear<NewNodeOpeningsType> | undefined
  >(undefined);

  const nodes = (currentConsortium.nodes!.items as Node[]).map((n) => ({
    label: `${n.idxSensor} - ${n.title}`,
    ...n,
  }));

  return (
    <>
      <Drawer
        anchor="right"
        variant="temporary"
        open={!!outlinedNodeOpening}
        keepMounted={false}
      >
        {outlinedNodeOpening && (
          <SingleNodeDrawer
            outlinedNode={outlinedNodeOpening}
            onClose={() => {
              // setShowingSingle(false);
              setOutlinedNodeOpening(undefined);
            }}
            onAddNewOpeningHours={(hours) => {
              console.log("Saving hours", hours, outlinedNodeOpening);
              setFieldValue(
                `nodesOpenings.${outlinedNodeOpening!.idx}.nodeProgram`,
                hours
              );
              setOutlinedNodeOpening(undefined);
            }}
          />
        )}
      </Drawer>
      <CardHeader
        title="Nuovo programma irriguo"
        subheader="Registra un nuovo programma irriguo nel sistema ABIoT"
        action={
          <IconButton
            onClick={() => {
              resetForm();
              onClose();
            }}
            color="primary"
            size="medium"
          >
            <Icon icon="bi:x-lg" />
          </IconButton>
        }
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Titolo"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />

              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps("isDefault")}
                    checked={!!values.isDefault}
                    size="small"
                  />
                }
                label="Predefinito"
              />

              {values.nodesOpenings?.length > 0 && (
                <Divider>Nodi in questo programma</Divider>
              )}
              <List>
                <FieldArray name="nodesOpenings">
                  {({ insert, remove, push }) => (
                    <>
                      {values.nodesOpenings?.map((nodeOpening, idx) => (
                        <ListItem
                          key={`${nodeOpening.node?.id}-node-program`}
                          divider
                          style={{ justifyContent: "space-between" }}
                          sx={{ p: 3 }}
                        >
                          {nodeOpening.node?.idxSensor} -{" "}
                          {nodeOpening?.node?.title} (
                          {nodeOpening?.nodeProgram?.length} Orari)
                          <ButtonGroup>
                            <Button
                              sx={{ pl: 0 }}
                              endIcon={<Icon icon="dashicons:edit" />}
                              color="inherit"
                              onClick={() => {
                                setOutlinedNodeOpening({ ...nodeOpening, idx });
                              }}
                            />
                            <Button
                              sx={{ pl: 0 }}
                              endIcon={<Icon icon="dashicons:trash" />}
                              color="error"
                              onClick={() => {
                                remove(idx);
                              }}
                            />
                          </ButtonGroup>
                        </ListItem>
                      ))}
                      <Divider sx={{ mb: 1 }} />

                      <Autocomplete
                        disablePortal
                        options={nodes}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icon icon={plusFill} />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Aggiungi nodo"
                          />
                        )}
                        // @ts-ignore
                        value={null}
                        onChange={(e, newNode) => {
                          if (
                            !newNode?.id ||
                            values.nodesOpenings.find(
                              (nop) => nop?.node?.id === newNode?.id
                            )
                          ) {
                            showMessage(
                              "Il nodo selezionato esiste già nel programma.",
                              "warning"
                            );
                            return;
                          }
                          // console.log("selected", e, newNode);
                          const newNodeOpening: NewNodeOpeningsType = {
                            node: newNode,
                            nodeProgram: [],
                            idx: values.nodesOpenings.length,
                          };
                          push(newNodeOpening);
                          setOutlinedNodeOpening(newNodeOpening);
                        }}
                      />
                    </>
                  )}
                </FieldArray>
              </List>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salva
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </>
  );
}
