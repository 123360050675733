/* eslint-disable import/prefer-default-export */
export const AB_SERVICE_LATLNG = { lat: 46.3263191, lng: 10.8710145 };
export const DATETIME_FORMAT_UI = "HH:mm:ss dd/MM/yyyy";
export const DATE_FORMAT_UI = "dd/MM/yyyy";
export const DATE_FORMAT_APEX = "MM/dd/yyyy";
export const SENSOR_LABELS = {
  temperature: "Temperatura",
  humidity: "Umidità",
  temperatureGround: "Temperatura terreno",
  tensiometer1: "Tensiometro 1",
  tensiometer2: "Tensiometro 2",
  tensiometer3: "Tensiometro 3",
  pluvioMeter: "Pluviometro",
  waterMark1: "Livello Acqua 1",
  waterMark2: "Livello Acqua 2",
  waterMark3: "Livello Acqua 3",
  waterMark4: "Livello Acqua 4",
  battery: "Batteria",
  signal: "Segnale",
  flowState: "Pressostato",
};

export const SENSOR_UNITS = {
  temperature: "°C",
  humidity: "%",
  temperatureGround: "°C",
  tensiometer1: "hPa",
  tensiometer2: "hPa",
  tensiometer3: "hPa",
  pluvioMeter: "mm",
  waterMark1: "kPa",
  waterMark2: "kPa",
  waterMark3: "kPa",
  waterMark4: "kPa",
  battery: "%",
  signal: "dBm",
  flowState: "",
};
