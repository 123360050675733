import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { MHidden } from "../../components/@material-extend";
import { useRoleChecker } from "../../utils/hooks";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import sidebarConfig from "./SidebarConfig";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isForUser = useRoleChecker();

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <MHidden width="lgDown">
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      </MHidden>

      {/* bottom navigation for mobile devices */}
      <MHidden width="lgUp">
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            backdropFilter: "blur(6px)",
            WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
            backgroundColor: "rgba(255, 255, 255, 0.72)",
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={window.location.pathname}
            onChange={(e, v) => {
              navigate(v);
            }}
          >
            {sidebarConfig.map(
              (item) =>
                isForUser(item.role) && (
                  <BottomNavigationAction
                    key={item.title}
                    label={item.title}
                    value={item.path}
                    icon={item.icon}
                  />
                )
            )}
          </BottomNavigation>
        </Paper>
      </MHidden>

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
