/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  CreateEndUserMutation,
  CreateEndUserMutationVariables,
  EndUser,
  UserType,
} from "../../../API";
import {
  createCognitoEndUser,
  createEndUser,
} from "../../../graphql/mutations";
import { RootState } from "../../../store";
import { gqlOperation, shallowUpdateConsortium } from "../../../store/queries";
import useShowMessage from "../../../utils/hooks";

interface Props {
  open: boolean;
  onClose: any;
}

export default function NewUserDrawer({ open, onClose }: Props) {
  const showMessage = useShowMessage();
  const [createdUser, setCreatedUser] = useState<
    | {
        email: string;
        password: string;
      }
    | undefined
  >();
  const dispatch = useDispatch();

  const UserSchema = Yup.object().shape({
    firstName: Yup.string().required("Il nome è obbligatorio"),
    lastName: Yup.string().required("Il cognome è obbligatorio"),
    email: Yup.string()
      .email("L'email deve essere valida")
      .required("L'email è obbligatoria"),
    userType: Yup.string().required("Il tipo utente è obbligatorio"),
  });

  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium!
  );

  const formik = useFormik<Partial<EndUser>>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userType: UserType.BASE,
    },
    validationSchema: UserSchema,
    onSubmit: async (values, actions) => {
      console.log("Submitted  form", values);
      try {
        if (
          currentConsortium.endUsers!.items.find(
            (u) => u!.email === values.email
          )
        ) {
          showMessage(
            `Utente ${values.email} già presente nel sistema`,
            "error"
          );
          return;
        }

        // generate a password and
        const password = Math.random().toString(36).slice(-8);

        await Auth.signUp({
          username: values.email!,
          password,
          attributes: {
            name: values.firstName,
            family_name: values.lastName,
            "custom:consortiumID": currentConsortium!.id!,
          },
        });

        const response: any = await gqlOperation(createCognitoEndUser, {
          consortiumID: currentConsortium!.id!,
          user: JSON.stringify({
            ...values,
          }),
        });

        if (response.createCognitoEndUser.includes("statusCode=400")) {
          showMessage("Errore durante la creazione dell'utente", "error");
          console.log("Cognito end user response: ", response);

          actions.setSubmitting(false);
          return;
        }

        const createdItem = await gqlOperation(
          createEndUser as CreateEndUserMutation,
          {
            input: {
              ...values,
              consortiumID: currentConsortium!.id!,
            },
          } as CreateEndUserMutationVariables
        );

        showMessage("Utente aggiunto con successo", "success");

        shallowUpdateConsortium("endUsers", (v) => ({
          items: [...v.items, createdItem.createEndUser],
        }));
        setCreatedUser({ email: values.email!, password });

        actions.resetForm();
        onClose();
      } catch (exception) {
        const message = JSON.stringify(exception);
        if (message.includes("UsernameExistsException")) {
          showMessage("Utente già presente nel sistema", "error");
        } else {
          showMessage("Errore durante la creazione dell'utente", "error");
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return createdUser ? (
    <Dialog onClose={() => setCreatedUser(undefined)} open maxWidth="lg">
      <CardHeader
        title="Credenziali utente creato"
        subheader="Alla chiusura di questa finestra, questi dati non saranno più accessibili."
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10 }} variant="middle">
              Credenziali accesso portale
            </Divider>
            <Typography variant="caption">
              <b>Attenzione:</b> le credenziali sono visibili solo una volta.
            </Typography>
            <TextField
              fullWidth
              size="small"
              multiline
              value={`Email: ${createdUser.email} Password: ${createdUser.password}`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Dialog>
  ) : (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      keepMounted={false}
      //   onBlur={onClose}
      PaperProps={{
        sx: {
          // minWidth: 600,
        },
      }}
    >
      <CardHeader
        title="Nuovo utente"
        subheader="Registra un nuovo utente finale nel sistema ABIoT"
        action={
          <IconButton onClick={onClose} color="primary" size="medium">
            <Icon icon="bi:x-lg" />
          </IconButton>
        }
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack spacing={3} direction="row">
                <TextField
                  fullWidth
                  label="Nome"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  label="Cognome"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
              <TextField
                fullWidth
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              {/* Add a dropdown to select whether the user is a moderator or only base */}
              <FormControl fullWidth>
                <InputLabel>Tipo Utente</InputLabel>
                <Select
                  fullWidth
                  label="Tipo Utente"
                  {...getFieldProps("userType")}
                  error={Boolean(touched.userType && errors.userType)}
                >
                  <MenuItem value={UserType.BASE}>Base</MenuItem>
                  <MenuItem value={UserType.TECHNICIAN}>Tecnico</MenuItem>
                </Select>
              </FormControl>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salva
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Drawer>
  );
}
