/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCognitoEndUser = /* GraphQL */ `mutation CreateCognitoEndUser($consortiumID: ID!, $user: String!) {
  createCognitoEndUser(consortiumID: $consortiumID, user: $user)
}
` as GeneratedMutation<
  APITypes.CreateCognitoEndUserMutationVariables,
  APITypes.CreateCognitoEndUserMutation
>;
export const createConsortium = /* GraphQL */ `mutation CreateConsortium(
  $input: CreateConsortiumInput!
  $condition: ModelConsortiumConditionInput
) {
  createConsortium(input: $input, condition: $condition) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConsortiumMutationVariables,
  APITypes.CreateConsortiumMutation
>;
export const updateConsortium = /* GraphQL */ `mutation UpdateConsortium(
  $input: UpdateConsortiumInput!
  $condition: ModelConsortiumConditionInput
) {
  updateConsortium(input: $input, condition: $condition) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConsortiumMutationVariables,
  APITypes.UpdateConsortiumMutation
>;
export const deleteConsortium = /* GraphQL */ `mutation DeleteConsortium(
  $input: DeleteConsortiumInput!
  $condition: ModelConsortiumConditionInput
) {
  deleteConsortium(input: $input, condition: $condition) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConsortiumMutationVariables,
  APITypes.DeleteConsortiumMutation
>;
export const createNode = /* GraphQL */ `mutation CreateNode(
  $input: CreateNodeInput!
  $condition: ModelNodeConditionInput
) {
  createNode(input: $input, condition: $condition) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeMutationVariables,
  APITypes.CreateNodeMutation
>;
export const updateNode = /* GraphQL */ `mutation UpdateNode(
  $input: UpdateNodeInput!
  $condition: ModelNodeConditionInput
) {
  updateNode(input: $input, condition: $condition) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeMutationVariables,
  APITypes.UpdateNodeMutation
>;
export const deleteNode = /* GraphQL */ `mutation DeleteNode(
  $input: DeleteNodeInput!
  $condition: ModelNodeConditionInput
) {
  deleteNode(input: $input, condition: $condition) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeMutationVariables,
  APITypes.DeleteNodeMutation
>;
export const createNodeDataRecord = /* GraphQL */ `mutation CreateNodeDataRecord(
  $input: CreateNodeDataRecordInput!
  $condition: ModelNodeDataRecordConditionInput
) {
  createNodeDataRecord(input: $input, condition: $condition) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeDataRecordMutationVariables,
  APITypes.CreateNodeDataRecordMutation
>;
export const updateNodeDataRecord = /* GraphQL */ `mutation UpdateNodeDataRecord(
  $input: UpdateNodeDataRecordInput!
  $condition: ModelNodeDataRecordConditionInput
) {
  updateNodeDataRecord(input: $input, condition: $condition) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeDataRecordMutationVariables,
  APITypes.UpdateNodeDataRecordMutation
>;
export const deleteNodeDataRecord = /* GraphQL */ `mutation DeleteNodeDataRecord(
  $input: DeleteNodeDataRecordInput!
  $condition: ModelNodeDataRecordConditionInput
) {
  deleteNodeDataRecord(input: $input, condition: $condition) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeDataRecordMutationVariables,
  APITypes.DeleteNodeDataRecordMutation
>;
export const createNodeStatisticsDaily = /* GraphQL */ `mutation CreateNodeStatisticsDaily(
  $input: CreateNodeStatisticsDailyInput!
  $condition: ModelNodeStatisticsDailyConditionInput
) {
  createNodeStatisticsDaily(input: $input, condition: $condition) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeStatisticsDailyMutationVariables,
  APITypes.CreateNodeStatisticsDailyMutation
>;
export const updateNodeStatisticsDaily = /* GraphQL */ `mutation UpdateNodeStatisticsDaily(
  $input: UpdateNodeStatisticsDailyInput!
  $condition: ModelNodeStatisticsDailyConditionInput
) {
  updateNodeStatisticsDaily(input: $input, condition: $condition) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeStatisticsDailyMutationVariables,
  APITypes.UpdateNodeStatisticsDailyMutation
>;
export const deleteNodeStatisticsDaily = /* GraphQL */ `mutation DeleteNodeStatisticsDaily(
  $input: DeleteNodeStatisticsDailyInput!
  $condition: ModelNodeStatisticsDailyConditionInput
) {
  deleteNodeStatisticsDaily(input: $input, condition: $condition) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeStatisticsDailyMutationVariables,
  APITypes.DeleteNodeStatisticsDailyMutation
>;
export const createNodeStatisticsMonthly = /* GraphQL */ `mutation CreateNodeStatisticsMonthly(
  $input: CreateNodeStatisticsMonthlyInput!
  $condition: ModelNodeStatisticsMonthlyConditionInput
) {
  createNodeStatisticsMonthly(input: $input, condition: $condition) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeStatisticsMonthlyMutationVariables,
  APITypes.CreateNodeStatisticsMonthlyMutation
>;
export const updateNodeStatisticsMonthly = /* GraphQL */ `mutation UpdateNodeStatisticsMonthly(
  $input: UpdateNodeStatisticsMonthlyInput!
  $condition: ModelNodeStatisticsMonthlyConditionInput
) {
  updateNodeStatisticsMonthly(input: $input, condition: $condition) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeStatisticsMonthlyMutationVariables,
  APITypes.UpdateNodeStatisticsMonthlyMutation
>;
export const deleteNodeStatisticsMonthly = /* GraphQL */ `mutation DeleteNodeStatisticsMonthly(
  $input: DeleteNodeStatisticsMonthlyInput!
  $condition: ModelNodeStatisticsMonthlyConditionInput
) {
  deleteNodeStatisticsMonthly(input: $input, condition: $condition) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeStatisticsMonthlyMutationVariables,
  APITypes.DeleteNodeStatisticsMonthlyMutation
>;
export const createNodeOpenings = /* GraphQL */ `mutation CreateNodeOpenings(
  $input: CreateNodeOpeningsInput!
  $condition: ModelNodeOpeningsConditionInput
) {
  createNodeOpenings(input: $input, condition: $condition) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeOpeningsMutationVariables,
  APITypes.CreateNodeOpeningsMutation
>;
export const updateNodeOpenings = /* GraphQL */ `mutation UpdateNodeOpenings(
  $input: UpdateNodeOpeningsInput!
  $condition: ModelNodeOpeningsConditionInput
) {
  updateNodeOpenings(input: $input, condition: $condition) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeOpeningsMutationVariables,
  APITypes.UpdateNodeOpeningsMutation
>;
export const deleteNodeOpenings = /* GraphQL */ `mutation DeleteNodeOpenings(
  $input: DeleteNodeOpeningsInput!
  $condition: ModelNodeOpeningsConditionInput
) {
  deleteNodeOpenings(input: $input, condition: $condition) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeOpeningsMutationVariables,
  APITypes.DeleteNodeOpeningsMutation
>;
export const createProgram = /* GraphQL */ `mutation CreateProgram(
  $input: CreateProgramInput!
  $condition: ModelProgramConditionInput
) {
  createProgram(input: $input, condition: $condition) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProgramMutationVariables,
  APITypes.CreateProgramMutation
>;
export const updateProgram = /* GraphQL */ `mutation UpdateProgram(
  $input: UpdateProgramInput!
  $condition: ModelProgramConditionInput
) {
  updateProgram(input: $input, condition: $condition) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProgramMutationVariables,
  APITypes.UpdateProgramMutation
>;
export const deleteProgram = /* GraphQL */ `mutation DeleteProgram(
  $input: DeleteProgramInput!
  $condition: ModelProgramConditionInput
) {
  deleteProgram(input: $input, condition: $condition) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProgramMutationVariables,
  APITypes.DeleteProgramMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createNodeLog = /* GraphQL */ `mutation CreateNodeLog(
  $input: CreateNodeLogInput!
  $condition: ModelNodeLogConditionInput
) {
  createNodeLog(input: $input, condition: $condition) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNodeLogMutationVariables,
  APITypes.CreateNodeLogMutation
>;
export const updateNodeLog = /* GraphQL */ `mutation UpdateNodeLog(
  $input: UpdateNodeLogInput!
  $condition: ModelNodeLogConditionInput
) {
  updateNodeLog(input: $input, condition: $condition) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeLogMutationVariables,
  APITypes.UpdateNodeLogMutation
>;
export const deleteNodeLog = /* GraphQL */ `mutation DeleteNodeLog(
  $input: DeleteNodeLogInput!
  $condition: ModelNodeLogConditionInput
) {
  deleteNodeLog(input: $input, condition: $condition) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeLogMutationVariables,
  APITypes.DeleteNodeLogMutation
>;
export const createAlarm = /* GraphQL */ `mutation CreateAlarm(
  $input: CreateAlarmInput!
  $condition: ModelAlarmConditionInput
) {
  createAlarm(input: $input, condition: $condition) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAlarmMutationVariables,
  APITypes.CreateAlarmMutation
>;
export const updateAlarm = /* GraphQL */ `mutation UpdateAlarm(
  $input: UpdateAlarmInput!
  $condition: ModelAlarmConditionInput
) {
  updateAlarm(input: $input, condition: $condition) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlarmMutationVariables,
  APITypes.UpdateAlarmMutation
>;
export const deleteAlarm = /* GraphQL */ `mutation DeleteAlarm(
  $input: DeleteAlarmInput!
  $condition: ModelAlarmConditionInput
) {
  deleteAlarm(input: $input, condition: $condition) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlarmMutationVariables,
  APITypes.DeleteAlarmMutation
>;
export const createNotificationSubscriber = /* GraphQL */ `mutation CreateNotificationSubscriber(
  $input: CreateNotificationSubscriberInput!
  $condition: ModelNotificationSubscriberConditionInput
) {
  createNotificationSubscriber(input: $input, condition: $condition) {
    subscription
    userGroup
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationSubscriberMutationVariables,
  APITypes.CreateNotificationSubscriberMutation
>;
export const updateNotificationSubscriber = /* GraphQL */ `mutation UpdateNotificationSubscriber(
  $input: UpdateNotificationSubscriberInput!
  $condition: ModelNotificationSubscriberConditionInput
) {
  updateNotificationSubscriber(input: $input, condition: $condition) {
    subscription
    userGroup
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationSubscriberMutationVariables,
  APITypes.UpdateNotificationSubscriberMutation
>;
export const deleteNotificationSubscriber = /* GraphQL */ `mutation DeleteNotificationSubscriber(
  $input: DeleteNotificationSubscriberInput!
  $condition: ModelNotificationSubscriberConditionInput
) {
  deleteNotificationSubscriber(input: $input, condition: $condition) {
    subscription
    userGroup
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationSubscriberMutationVariables,
  APITypes.DeleteNotificationSubscriberMutation
>;
export const createEndUser = /* GraphQL */ `mutation CreateEndUser(
  $input: CreateEndUserInput!
  $condition: ModelEndUserConditionInput
) {
  createEndUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEndUserMutationVariables,
  APITypes.CreateEndUserMutation
>;
export const updateEndUser = /* GraphQL */ `mutation UpdateEndUser(
  $input: UpdateEndUserInput!
  $condition: ModelEndUserConditionInput
) {
  updateEndUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEndUserMutationVariables,
  APITypes.UpdateEndUserMutation
>;
export const deleteEndUser = /* GraphQL */ `mutation DeleteEndUser(
  $input: DeleteEndUserInput!
  $condition: ModelEndUserConditionInput
) {
  deleteEndUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEndUserMutationVariables,
  APITypes.DeleteEndUserMutation
>;
