import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ClimbingBoxLoader } from "react-spinners";
import { Node, NodeStatus, NodeType, Notification } from "../../API";
import Page from "../../components/Page";
import { AppErrorNodes, AppTotalNodes } from "../../components/_dashboard/app";
import AppSensorNodes from "../../components/_dashboard/app/AppClosedNodes";
import AppValveNodes from "../../components/_dashboard/app/AppOpenNodes";
import NodesMap from "../../components/_dashboard/map/NodesMap";
import { RootState } from "../../store";
import AppActiveAlarms from "../../components/_dashboard/app/AppActiveAlarms";

function Dashboard() {
  // const [nodes, setNodes] = useState<Node[]>([]);
  const [isLoading, setLoading] = useState(false);
  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  )!;
  const nodes = currentConsortium.nodes!.items as Node[];
  const notifications = currentConsortium.notifications!
    .items as Notification[];
  return (
    <Page title="Dashboard | ABIoT">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Dashboard - {currentConsortium.name}
          </Typography>
        </Box>
        {isLoading ? (
          <ClimbingBoxLoader
            color="#00AB55"
            css={`
              position: absolute;
              top: 50%;
              left: 50%;
            `}
            speedMultiplier={1.5}
          />
        ) : (
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item md={2}>
              <AppTotalNodes quantity={nodes.length} />
            </Grid>

            <Grid item md={2}>
              <AppActiveAlarms
                quantity={
                  currentConsortium.alarms!.items?.filter(
                    (alarm) => alarm?.currentlyFiringNodes?.length! > 0
                  )?.length || 0
                }
              />
            </Grid>
            <Grid item md={2}>
              <AppErrorNodes
                quantity={
                  nodes.filter((node) => node.status === NodeStatus.ERROR)
                    .length
                }
              />
            </Grid>
            <Grid item md={2}>
              <AppValveNodes
                quantity={
                  nodes.filter((node) => node.nodeType === NodeType.VALVE)
                    .length
                }
              />
            </Grid>
            <Grid item md={2}>
              <AppSensorNodes
                quantity={
                  nodes.filter((node) => node.nodeType === NodeType.SENSOR)
                    .length
                }
              />
            </Grid>
            <Grid item xs={12}>
              <NodesMap consortium={currentConsortium} nodes={nodes} />
            </Grid>

            {/* <Grid item xs={12}>
              <NotificationsList notifications={notifications} />
            </Grid> */}
          </Grid>
        )}
      </Container>
    </Page>
  );
}

export default Dashboard;
