import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
import doneFill from "@iconify/icons-eva/checkmark-fill";
import { Icon } from "@iconify/react";
import { formatDistanceToNow } from "date-fns";
import { it } from "date-fns/locale";
import { useRef, useState } from "react";
// material
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// utils
// components
import { useDispatch, useSelector } from "react-redux";
import {
  CreateNotificationMutation,
  CreateNotificationMutationVariables,
  DeleteNotificationMutation,
  Notification,
  NotificationStatus,
} from "../../API";
import MenuPopover from "../../components/MenuPopover";
import Scrollbar from "../../components/Scrollbar";
import {
  createNotification,
  deleteNotification,
} from "../../graphql/mutations";
import { RootState } from "../../store";
import { setCurrentConsortium } from "../../store/appConfigSlice";
import { gqlOperation } from "../../store/queries";
import { useRoleChecker } from "../../utils/hooks";

function renderContent(notification: Notification) {
  const title = (
    <Typography variant="subtitle2">
      {/* {notification.title} */}
      Allarme
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  // if (notification.type === "order_placed") {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="/static/icons/ic_notification_package.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === "order_shipped") {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="/static/icons/ic_notification_shipping.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === "mail") {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="/static/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === "chat_message") {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="/static/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    avatar: (
      <img
        alt={notification.message!}
        src="/static/icons/ic_notification_chat.svg"
      />
    ),
    title,
  };
}

function NotificationItem({
  notification,
  handleMarkAsRead,
}: {
  notification: Notification;
  handleMarkAsRead?: () => void;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItem
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        // ...(notification.isUnRead && {
        bgcolor: "action.selected",
        // }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.createdAt!), {
              locale: it,
            })}
          </Typography>
        }
      />
      {handleMarkAsRead && (
        <IconButton color="primary" onClick={() => handleMarkAsRead()}>
          <Icon icon={doneFill} width={15} height={15} />
        </IconButton>
      )}
    </ListItem>
  );
}

async function markAsRead(n: Notification) {
  await gqlOperation(
    createNotification as CreateNotificationMutation,
    {
      input: {
        message: n.message,
        priority: n.priority,
        status: NotificationStatus.READ,
        consortiumID: n.consortiumID,
        nodeID: n.nodeID,
      },
    } as CreateNotificationMutationVariables
  );
  // Then delete the notification
  await gqlOperation(deleteNotification as DeleteNotificationMutation, {
    input: { id: n.id },
  });
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  )!;
  const dispatch = useDispatch();
  const notifications = (
    currentConsortium.notifications!.items as Notification[]
  ).filter((n) => n.status === NotificationStatus.UNREAD);

  const totalUnRead = notifications.length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    await Promise.all(notifications.map(markAsRead));

    dispatch(
      setCurrentConsortium({
        ...currentConsortium,
        notifications: {
          __typename: "ModelNotificationConnection",
          ...currentConsortium.notifications,
          items: [],
        },
      })
    );
  };

  const roleCheck = useRoleChecker();

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 2,
            px: 2.5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifiche</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Hai {totalUnRead} messaggi non letti
            </Typography>
          </Box>

          {totalUnRead > 0 && roleCheck("manager") && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={
                  roleCheck("manager")
                    ? async () => {
                        await markAsRead(notification);

                        dispatch(
                          setCurrentConsortium({
                            ...currentConsortium,
                            notifications: {
                              __typename: "ModelNotificationConnection",
                              items:
                                currentConsortium.notifications!.items!.filter(
                                  (n) => n!.id !== notification.id
                                ),
                            },
                          })
                        );
                      }
                    : undefined
                }
              />
            ))}
          </List>
        </Scrollbar>

        {/* <Divider /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            Di più
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
