import { Icon } from "@iconify/react";
import { Box, Card, CardHeader, Stack } from "@mui/material";
import React, { useMemo } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";

import { Link } from "react-router-dom";
import { Consortium, Node } from "../../../API";
import { MAP_GATEWAY_ICON, nodeToMapIcon } from "../../../utils";
import "./NodesMap.scss";

type Props = {
  consortium: Consortium;
  nodes: Node[];
};

export default function NodesMap({ consortium, nodes }: Props) {
  const currentlyFiringNodes: string[] = useMemo(
    () =>
      (consortium.alarms?.items
        ?.map((alarm) => alarm?.currentlyFiringNodes)
        .flat() as string[]) || [],
    [consortium.alarms]
  );
  return (
    <Card>
      <CardHeader
        title="Mappa"
        subheader="Qui puoi visualizzare i nodi registrati nel sistema"
      />
      <Box sx={{ p: 0, pb: 1, pt: 1 }} dir="ltr">
        <MapContainer
          center={[consortium.lat, consortium.lng]}
          zoom={15}
          scrollWheelZoom
          style={{
            height: 550,
            cursor: "pointer",
            // boxShadow: "0 0 10px grey",
            // borderRadius: 7,
            // border: "1px solid grey",
          }}
          attributionControl={false}
          whenCreated={(map) => {
            /* map.on('click', (ev: Leaflet.LeafletMouseEvent) => {
            if (isLocating.current) {
              isLocating.current = false;
              message.destroy('newSensorMessage');

              // update initial values of NewSensorForm and show it in modal
              newSensor.current = { ...ev.latlng };
              setCreating(true);
            }
          });
          map.on('mousemove', (ev: Leaflet.LeafletMouseEvent) => {
            if (isLocating.current) {
              // console.log('over', isLocating);
              newSensorMarker.current?.setLatLng(ev.latlng);
              // setNewSensorLocation(ev.latlng);
            }
          }); */
          }}
        >
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={["mt1", "mt2", "mt3"]}
          />

          <Marker position={consortium} icon={MAP_GATEWAY_ICON}>
            <Popup>
              Gateway
              <br />
            </Popup>
          </Marker>

          {nodes.map((node) => (
            <React.Fragment key={node.id}>
              <Polyline
                color="#00ab55"
                positions={[consortium, [node.lat, node.lng]]}
              />
              <Marker
                icon={nodeToMapIcon(node, consortium, currentlyFiringNodes)}
                position={[node.lat, node.lng]}
              >
                <Popup minWidth={100}>
                  <Link to={`/dashboard/nodes/${node.id}`}>Dettagli Nodo</Link>
                </Popup>
              </Marker>
            </React.Fragment>
          ))}
        </MapContainer>
        <Stack direction="row" spacing={3} justifyContent="center" mt={2}>
          {/* <div className="legend">
            <span className="gateway-icon">
              <Icon icon="emojione-monotone:satellite-antenna" />
            </span>
            Gateway
          </div> */}
          <div className="legend">
            <span className="sensor-icon OK">
              <Icon icon="carbon:iot-platform" />
            </span>
            OK
          </div>
          <div className="legend">
            <span className="sensor-icon ALARM">
              <Icon icon="carbon:iot-platform" />
            </span>
            In Allarme
          </div>
          <div className="legend">
            <span className="sensor-icon ERROR">
              <Icon icon="iconoir:cpu-warning" />
            </span>
            Errore
          </div>
        </Stack>
      </Box>
    </Card>
  );
}
