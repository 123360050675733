import {
  GetObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Page from "../../../components/Page";
import AccountPopover from "../../../layouts/dashboard/AccountPopover";
import { RootState } from "../../../store";
import useShowMessage from "../../../utils/hooks";
import "./AdminDashboard.scss";
import CardConsortiums from "./CardConsortiums";
import CardGlobalNotifications from "./CardGlobalNotifications";
import { Consortium, OnChangeConsortiumsSubscription } from "../../../API";
import { onChangeConsortiums } from "../../../graphql/subscriptions";
import { setLoading } from "../../../store/appConfigSlice";
import {
  gqlUnsubscribeAll,
  gqlObserve,
  listConsortiums,
} from "../../../store/queries";

function AdminDashboard() {
  const userInfo = useSelector(
    (state: RootState) => state.appConfig.currentUserInfo
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [currentVersion, setCurrentVersion] = useState("");
  const showMessage = useShowMessage();
  const [consortiums, setConsortiums] = useState<Consortium[]>([]);

  useEffect(() => {
    fetchCurrentVersion(setCurrentVersion);
  }, []);

  useEffect(() => {
    gqlUnsubscribeAll();

    const fetchData = async () => {
      setLoading(true);
      console.log("Loading data ConsortiumsCard");
      const loadedConsortiums = await listConsortiums();
      console.log(loadedConsortiums);
      setConsortiums(loadedConsortiums);
      setLoading(false);
    };
    fetchData();

    gqlObserve(
      "admin-consortium-cardsrefresh",
      onChangeConsortiums as OnChangeConsortiumsSubscription,
      fetchData
    );
  }, []);

  return (
    <Page title="Admin | ABIoT">
      <Divider sx={{ mt: "118px" }} />
      <Stack
        direction="row"
        alignItems="center"
        className="accountPopover"
        spacing={1}
        sx={{ scale: [0.2, 1] }}
      >
        <AccountPopover />
        <Box>
          <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
            {userInfo.email}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {userInfo["cognito:groups"]}
          </Typography>
        </Box>
      </Stack>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12} xl={7}>
          <CardConsortiums consortiums={consortiums} />
          <Card sx={{ mt: 2.5 }}>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={2}>
                <p>Aggiorna firmware dei gateway:</p>
                {/* Upload input to select .bin file and upload to S3 using cognito credentials */}
                <TextField
                  size="small"
                  placeholder="0.1.0"
                  value={currentVersion}
                  onChange={(ev: any) => setCurrentVersion(ev.target.value)}
                />
                <Input
                  type="file"
                  placeholder="firmware.bin"
                  onChange={(ev: any) => {
                    // console.log(ev.target);
                    setSelectedFile(ev.target.files[0]);
                  }}
                  sx={{ ml: 2.5, mr: 2.5 }}
                />
                <Button
                  disabled={!selectedFile || !currentVersion}
                  variant="outlined"
                  onClick={async () => {
                    if (!selectedFile || !currentVersion) return;

                    console.log(selectedFile);
                    // use cognito to upload file to s3
                    const info = await Auth.currentCredentials();
                    const s3 = new S3Client({
                      region: "eu-central-1",
                      credentials: Auth.essentialCredentials(info),
                    });

                    // upload file to s3
                    const params = {
                      Bucket: "abiot-gateway-updates",
                      Key: `firmware-${currentVersion}.bin`,
                      Body: selectedFile,
                    };
                    const putObjectCommand = new PutObjectCommand(params);
                    const firmwareUpload = await s3.send(putObjectCommand);

                    // update latest.json
                    const latest = {
                      version: currentVersion,
                    };
                    const params2 = {
                      Bucket: "abiot-gateway-updates",
                      Key: "latest.json",
                      Body: JSON.stringify(latest),
                    };
                    const latestUpload = await s3.send(
                      new PutObjectCommand(params2)
                    );

                    showMessage("Firmware aggiornato con successo");
                    setSelectedFile(null);
                  }}
                >
                  Upload
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} xl={5}>
          <CardGlobalNotifications
            consortiums={consortiums.reduce((acc, consortium) => {
              acc[consortium.id] = consortium;
              return acc;
            }, {} as any)}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

async function fetchCurrentVersion(setCurrentVersion: any) {
  // get current version from s3
  const info = await Auth.currentCredentials();
  const s3 = new S3Client({
    region: "eu-central-1",
    credentials: Auth.essentialCredentials(info),
  });
  const params = {
    Bucket: "abiot-gateway-updates",
    Key: "latest.json",
  };
  // latest.json is of the form { "version": "0.1.0" }
  s3.send(new GetObjectCommand(params), (err: any, data: any) => {
    if (err) {
      console.log(err);
      return;
    }
    const latest = JSON.parse(data.Body.toString("utf-8"));
    setCurrentVersion(latest.version);
  });
}

export default AdminDashboard;
