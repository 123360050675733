import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Drawer,
  Switch,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { useSelector } from "react-redux";
import type {
  DeepGetProgramQuery,
  DeepGetProgramQueryVariables,
  DeleteProgramMutationVariables,
  Program,
} from "../../../API";
import Page from "../../../components/Page";
import { getProgram } from "../../../graphql/customQueries";
import { deleteProgram } from "../../../graphql/mutations";
import { RootState, store } from "../../../store";
import { gqlOperation, setDefaultProgram } from "../../../store/queries";
import useShowMessage from "../../../utils/hooks";
import { GridColumn } from "../../../utils/types";
import NewProgramDrawer, { NewProgramType } from "./NewProgramDrawer";
import "./Programs.scss";

const columns: GridColumn<Program>[] = [
  { field: "id", headerName: "ID", align: "center", headerAlign: "center" },
  {
    field: "createdAt",
    headerName: "Data creazione",
    align: "center",
    headerAlign: "center",
    width: 300,
  },
  {
    field: "title",
    headerName: "Titolo",
    align: "center",
    headerAlign: "center",
    width: 150,
    // renderCell: ({ row }) => `${row.title} (${row.nodeOpenings?.length} nodi)`,
  },
  {
    field: "isRunning",
    headerName: "Predefinito",
    align: "center",
    headerAlign: "center",
    width: 300,
    hideSortIcons: true,
    renderCell: ({ row }) => (
      <Switch
        disabled
        checked={
          row.id ===
          store.getState().appConfig.currentConsortium
            ?.consortiumDefaultProgramId
        }
        onClick={() => {
          setDefaultProgram(row.id);
        }}
        // disabled
      />
    ),
  },
];

function ProgramsPage() {
  const [showNewProgramDrawer, setShowNewProgramDrawer] = useState(false);
  const [outlinedObject, setOutlinedObject] = useState<
    Partial<NewProgramType> | undefined
  >(undefined);
  const [isLoading, setLoading] = useState(false);

  const { currentConsortium, userGroups } = useSelector(
    (state: RootState) => state.appConfig
  )!;
  const isManagerUser =
    userGroups!.includes("manager") || userGroups?.includes("admin");

  const programs = currentConsortium!.programs!.items as Program[];
  const confirm = useConfirm();
  const showMessage = useShowMessage();

  const modifyProgramHandler = async (row: Program) => {
    setLoading(true);
    const programObj = await gqlOperation(
      getProgram as DeepGetProgramQuery,
      {
        id: row.id,
      } as DeepGetProgramQueryVariables
    );
    console.log(programObj);

    const newOutlinedObj = {
      id: row.id,
      title: row.title,
      isDefault: row.id === currentConsortium!.consortiumDefaultProgramId,
      nodesOpenings: programObj!.getProgram!.nodesOpenings!.items.map(
        (op, idx) => ({
          nodeProgram: op!.nodeProgram,
          node: op!.node!,
          idx,
        })
      ),
    };
    console.log(newOutlinedObj);
    setOutlinedObject(newOutlinedObj);
    setShowNewProgramDrawer(true);
    setLoading(false);
  };
  return (
    <Page title="Programmi | ABIoT">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Programmi irriguo ({programs.length})
          </Typography>
        </Box>
        <Card>
          <CardHeader
            // title="Programmi irriguo"
            subheader="Qui puoi visualizzare i programmi irriguo registrati nel sistema"
            action={
              isManagerUser && (
                <Button
                  variant="outlined"
                  startIcon={<Icon icon={plusFill} />}
                  size="medium"
                  onClick={() => setShowNewProgramDrawer(true)}
                >
                  Nuovo Programma
                </Button>
              )
            }
          />
          <Drawer
            variant="temporary"
            anchor="right"
            ModalProps={{
              keepMounted: false,
            }}
            keepMounted={false}
            SlideProps={{
              unmountOnExit: true,
            }}
            open={showNewProgramDrawer}
            //   onBlur={onClose}
            // PaperProps={{
            //   sx: {
            //     minWidth: 600,
            //   },
            // }}
          >
            {showNewProgramDrawer && (
              <NewProgramDrawer
                onClose={() => {
                  setShowNewProgramDrawer(false);
                  setOutlinedObject(undefined);
                }}
                outlinedObject={outlinedObject}
              />
            )}
          </Drawer>

          <CardContent>
            <DataGrid
              loading={isLoading}
              rows={programs}
              columns={[
                ...columns,
                {
                  field: "",
                  headerName: "",
                  hideSortIcons: true,
                  width: 450,
                  renderCell: ({ row }) => (
                    <ButtonGroup size="medium">
                      {row.isDefault && (
                        <>
                          <Button
                            startIcon={<Icon icon="codicon:debug-start" />}
                            disabled={row.isRunning}
                          >
                            Start
                          </Button>
                          <Button
                            startIcon={<Icon icon="clarity:stop-solid" />}
                          >
                            Stop
                          </Button>
                          <Button startIcon={<Icon icon="bx:bx-reset" />}>
                            Reset
                          </Button>
                        </>
                      )}
                      {isManagerUser && (
                        <>
                          <Button
                            startIcon={<Icon icon="dashicons:edit" />}
                            onClick={() => modifyProgramHandler(row)}
                          >
                            Modifica
                          </Button>
                          <Button
                            sx={{ pl: 0 }}
                            endIcon={<Icon icon="dashicons:trash" />}
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              // check if this the default program, and fail if so
                              if (row.isDefault) {
                                showMessage(
                                  "Non puoi eliminare il programma predefinito",
                                  "error"
                                );
                                return;
                              }

                              confirm({
                                title:
                                  "Sei sicuro di voler eliminare il programma?",
                                description:
                                  "L'eliminazione del programma comporta la perdita di tutti i dati associati.",
                                confirmationText: "Elimina",
                                cancellationText: "Annulla",
                              })
                                .then(async () => {
                                  await gqlOperation(deleteProgram, {
                                    input: {
                                      id: row.id,
                                    },
                                  } as DeleteProgramMutationVariables);
                                  showMessage(
                                    "Programma eliminato con successo",
                                    "success"
                                  );
                                })
                                .catch(() => {});
                            }}
                          />
                        </>
                      )}
                    </ButtonGroup>
                  ),
                },
              ]}
              pageSize={100}
              disableColumnFilter
              disableColumnMenu
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableDensitySelector
              showCellRightBorder
              getRowClassName={(params) =>
                params.row.isEnabled ? "row-default-program" : ""
              }
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default ProgramsPage;
