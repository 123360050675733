import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Consortium } from "../../../API";
import GatewayAppBar from "../../../components/charts/GatewayAppBar";
import { sendMessage } from "../../../graphql/queries";
import { setLoading as appSetLoading } from "../../../store/appConfigSlice";
import {
  deleteConsortium,
  deleteUser,
  gqlOperation,
} from "../../../store/queries";
import useShowMessage from "../../../utils/hooks";
import NewConsortiumDrawer from "./NewConsortium";

interface Props {
  consortiums: Consortium[];
}

export default function CardConsortiums({ consortiums }: Props) {
  const [showNewConsortiumDrawer, setShowNewConsortiumDrawer] = useState(false);
  const [outlinedObject, setOutlinedObject] = useState<
    Consortium | undefined
  >();
  // const [consortiums, setConsortiums] = useState<Consortium[]>([]);
  // const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   gqlUnsubscribeAll();

  //   const fetchData = async () => {
  //     setLoading(true);
  //     console.log("Loading data ConsortiumsCard");
  //     const loadedConsortiums = await listConsortiums();
  //     console.log(loadedConsortiums);
  //     setConsortiums(loadedConsortiums);
  //     setLoading(false);
  //   };
  //   fetchData();

  //   gqlObserve(
  //     "admin-consortium-cardsrefresh",
  //     onChangeConsortiums as OnChangeConsortiumsSubscription,
  //     fetchData
  //   );
  // }, []);
  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Consorzi Gestiti ({consortiums.length})
        </Typography>
      </Box>
      <Card sx={{ pb: 2.5, mb: 2.5 }}>
        <CardHeader
          subheader="Qui puoi visualizzare i consorzi registrati nel sistema ed accedere alle loro dashboard"
          action={
            <Button
              variant="outlined"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setShowNewConsortiumDrawer(true)}
            >
              Nuovo consorzio
            </Button>
          }
        />
        {showNewConsortiumDrawer && (
          <NewConsortiumDrawer
            outlinedObject={outlinedObject}
            open={showNewConsortiumDrawer}
            onClose={() => {
              setShowNewConsortiumDrawer(false);
              setOutlinedObject(undefined);
            }}
          />
        )}
      </Card>
      <Grid container spacing={1}>
        {consortiums.map((consortium) => (
          <Grid
            key={consortium.id}
            item
            xs={12}
            sm={6}
            md={4}
            style={{ minWidth: 335 }}
          >
            <Card>
              <CardContent>
                <CardActionArea
                  onClick={async () => {
                    dispatch(appSetLoading(true));
                    // save consortium id in local storage
                    localStorage.setItem("consortiumId", consortium.id);

                    // navigate to dashboard force reload
                    window.location.href = "/dashboard";
                  }}
                >
                  <Typography variant="h6" component="div">
                    {consortium.name}
                  </Typography>

                  <Typography
                    sx={{ mb: 1.5 }}
                    variant="subtitle1"
                    color="text.secondary"
                  >
                    {consortium.contact}
                  </Typography>
                </CardActionArea>
                <Typography variant="caption" color="text.secondary">
                  {consortium.id}
                </Typography>
                <br />
                <Typography variant="caption" color="text.secondary">
                  Creato: {consortium.createdAt}
                </Typography>
                <br />
                <Typography variant="caption" color="text.secondary">
                  Local Time: {consortium.gatewayLocalTime}
                </Typography>
                <Typography
                  sx={{ mb: 1.5 }}
                  variant="subtitle1"
                  color="text.secondary"
                />
                <GatewayAppBar consortium={consortium} />
              </CardContent>

              <CardActions>
                <AdminActions
                  consortium={consortium}
                  onEditClick={() => {
                    setOutlinedObject(consortium);
                    setShowNewConsortiumDrawer(true);
                  }}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function AdminActions({ consortium, onEditClick }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const showMessage = useShowMessage();

  return (
    <ButtonGroup size="medium" fullWidth>
      <Button
        disabled={loading}
        sx={{ flex: 2 }}
        startIcon={<Icon icon="el:dashboard" />}
        onClick={async () => {
          setLoading(true);
          // save consortium id in local storage
          localStorage.setItem("consortiumId", consortium.id);

          // navigate to dashboard force reload
          window.location.href = "/dashboard";
        }}
      />
      <Button
        disabled={loading}
        sx={{ flex: 2 }}
        onClick={() => {
          confirm({
            title: "Aggiornamento",
            description: `Sei sicuro di voler aggiornare il firmware del gateway consorzio ${consortium.name}? Questa azione potrebbe causare un'interruzione del servizio.`,
            cancellationText: "Annulla",
            confirmationText: "Aggiorna",
          })
            .then(async () => {
              const response = await gqlOperation(sendMessage, {
                action: "updateFirmware",
                consortiumID: consortium.id,
              });
              console.log(response);
              showMessage(
                "Comando aggiornamento inviato con successo",
                "success"
              );
            })
            .catch(() => {});
        }}
        // startIcon={<Icon icon="el:update" />}
      >
        Aggiorna
      </Button>
      <Button
        sx={{ pl: 0, flex: 1 }}
        endIcon={<Icon icon="dashicons:edit" />}
        onClick={onEditClick}
      />
      <Button
        sx={{ pl: 0, flex: 1 }}
        endIcon={<Icon icon="dashicons:trash" />}
        color="error"
        onClick={() => {
          // confirm if user wants to delete with a dialog using popup
          confirm({
            title: "Elimina Consorzio",
            description: `Sei sicuro di voler eliminare il consorzio ${consortium.name}? Questa azione è irreversibile.`,
            cancellationText: "Annulla",
            confirmationText: "Elimina",
          })
            .then(async () => {
              await deleteUser(consortium.contact);
              await deleteConsortium(consortium.id);
              showMessage("Consorzio eliminato con successo", "success");
            })
            .catch(() => {});
        }}
      />
    </ButtonGroup>
  );
}
