import geoDistance from "geodist";
import type { LatLngLiteral } from "leaflet";
import { divIcon, point } from "leaflet";
import { Node } from "../API";

/* eslint-disable import/prefer-default-export */
export function sleep(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function renderIfNotEmpty<T>(property: keyof T) {
  return ({ row }: { row: T }) =>
    row[property] !== undefined ? row[property] : "-";
}

export function renderValue(value: any) {
  return value !== undefined ? value : "-";
}

export const nodeToMapIcon = (
  node: Partial<Node>,
  gatewayLocation: LatLngLiteral,
  currentlyFiringNodes?: string[]
) => {
  let nodeClass = "carbon:iot-platform";
  if (node.status === "ERROR") nodeClass = "iconoir:cpu-warning";

  let nodeStatus: any = node.status;
  if (currentlyFiringNodes?.includes(node.id!)) nodeStatus = "ALARM";
  return divIcon({
    className: `sensor-icon ${nodeStatus}`,
    // <span class="iconify" data-icon="${nodeClass}"></span>
    html: `<div class="node-marker-label">[${node.idxSensor}] ${
      node.title
    }</div>
    <div class="temperature">${node.temperature} °C</div>
    <div class="distance">${geoDistance(
      { lat: gatewayLocation.lat, lon: gatewayLocation.lng },
      { lat: node.lat, lon: node.lng },
      { exact: false, unit: "meters" }
    )} m</div>`,
    iconSize: point(100, 70),
  });
};

export const MAP_GATEWAY_ICON = divIcon({
  className: "gateway-icon",
  // html: `<span class="iconify" data-icon="emojione-monotone:satellite-antenna"></span>GW`,
  iconSize: point(50, 50),
});

export function tensioMeterToColor(value: number | null) {
  if (value === null) return undefined;
  if (value < -100) return "#00FFFF3d";
  if (value < -500) return "#00ff003d";
  if (value < -700) return "#FFFF003d";
  return "#ff000069";
}
export function waterMarkToColor(value: number | null) {
  if (value === null) return undefined;
  if (value < 10) return "#00FFFF3d";
  if (value < 30) return "#00ff003d";
  if (value < 60) return "#FFFF003d";
  if (value < 100) return "#FFA5003d";
  return "#ff000069";
}

/**
 * Given a number, returns a string with the given size and padding
 */
export function p2s(num: number | string, size: number = 2, pad: string = "0") {
  return String(num).padStart(size, pad);
}
