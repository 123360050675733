import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { BounceLoader } from "react-spinners";

import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import ForgotPage from "./pages/Forgot";
import AdminDashboard from "./pages/logged/admin/AdminDashboard";
import AlarmsPage from "./pages/logged/alarms/AlarmsPage";
import DashboardApp from "./pages/logged/DashboardApp";
import NodesPage from "./pages/logged/nodes/NodesPage";
import ProgramsPage from "./pages/logged/programs/ProgramsPage";
import UsersPage from "./pages/logged/users/UsersPage";
import LoginPage from "./pages/Login";
import NotFound from "./pages/Page404";
import { RootState } from "./store";
import {
  setCurrentConsortium,
  setCurrentUserInfo,
  setLoading,
  setLoggedIn,
  setUserGroups,
} from "./store/appConfigSlice";
import { getConsortium, observeCurrentConsortium } from "./store/queries";
import DetailsNode from "./pages/logged/nodes/DetailsNode";

// @ts-ignore
window.Auth = Auth;

export default function Router() {
  const userGroups = useSelector(
    (state: RootState) => state.appConfig.userGroups
  );

  const isLoggedIn = useSelector(
    (state: RootState) => state.appConfig.isLoggedIn
  );
  const isLoading = useSelector(
    (state: RootState) => state.appConfig.isLoading
  );

  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const checkLoggedIn = async () => {
      console.log("[Routes] Loading login info...");
      dispatch(setLoading(true));
      try {
        const { payload } = (await Auth.currentSession()).getIdToken();
        const resolvedUserGroups = payload["cognito:groups"];
        // if (!resolvedUserGroups?.includes("admin")) {
        const consortiumId =
          payload["custom:consortiumID"] ||
          localStorage.getItem("consortiumId");
        if (consortiumId) {
          const refreshConsortium = async () => {
            const consortium = await getConsortium(consortiumId);
            console.log("Current consortium", consortium);
            dispatch(setCurrentConsortium(consortium));
          };
          console.log(consortiumId);
          // observe consortium
          observeCurrentConsortium(consortiumId, refreshConsortium);
          await refreshConsortium();
        }
        dispatch(setCurrentUserInfo(payload));
        dispatch(setUserGroups(resolvedUserGroups));
        dispatch(setLoggedIn(true));
      } catch (err) {
        dispatch(setLoggedIn(false));
        console.error(err);
      }
      dispatch(setLoading(false));
    };
    checkLoggedIn();
  }, []);

  const isAdmin = userGroups?.includes("admin");
  // console.log("isLoggedIn", isLoggedIn, currentConsortium, isAdmin);

  const guardConsortiumSetRoute = <T,>(route: T) => {
    if (!currentConsortium && !isAdmin) {
      console.error("consortium not found for the user!");
      dispatch(setLoading(true));
      // return <Navigate to="/login" replace />;
    }
    return !currentConsortium && isAdmin ? (
      <Navigate to="/admin" replace />
    ) : (
      route
    );
  };

  const guardAdminRoute = <T,>(route: T) =>
    !isAdmin ? <Navigate to="/dashboard" replace /> : route;

  const guardManagerRoute = <T,>(route: T) =>
    !userGroups?.includes("manager") && !isAdmin ? (
      <Navigate to="/dashboard" replace />
    ) : (
      route
    );

  const guardLoading = <T,>(routes: () => T) =>
    isLoading
      ? () => [
          {
            path: "*",
            element: (
              <BounceLoader
                color="#00AB55"
                css={`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
                speedMultiplier={1.5}
              />
            ),
          },
        ]
      : routes;

  const guardLoggedIn = <T,>(routes: () => T) =>
    isLoggedIn
      ? routes
      : () => [
          { path: "login", element: <LoginPage /> },
          { path: "forgot", element: <ForgotPage /> },
          { path: "*", element: <Navigate to="/login" replace /> },
        ];

  const getDefaultRoutes = () => [
    {
      path: "/dashboard",
      element: guardConsortiumSetRoute(<DashboardLayout />),
      children: [
        {
          path: "/dashboard",
          element: <Navigate to="/dashboard/app" replace />,
        },
        { path: "app", element: <DashboardApp /> },
        { path: "programs", element: guardManagerRoute(<ProgramsPage />) },
        { path: "users", element: guardManagerRoute(<UsersPage />) },
        { path: "nodes", element: <NodesPage /> },
        { path: "nodes/:nodeId", element: <DetailsNode /> },
        { path: "alarms", element: <AlarmsPage /> },
      ],
    },
    {
      path: "/admin",
      element: guardAdminRoute(<LogoOnlyLayout />),
      children: [
        // {
        //   path: "/admin",
        //   element: <Navigate to="/admin/consortiums" replace />,
        // },
        { path: "/admin", element: <AdminDashboard /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Navigate to="/admin" /> },
        { path: "forgot", element: <Navigate to="/admin" /> },
        // { path: 'register', element: <Register /> },
        { path: "404", element: <NotFound /> },
        {
          path: "/",
          element: <Navigate to="/admin" />,
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(guardLoading(guardLoggedIn(getDefaultRoutes))());
}
