import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as datefns from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EndUser } from "../../../API";
import Page from "../../../components/Page";
import { deleteEndUser } from "../../../graphql/mutations";
import { RootState } from "../../../store";
import { gqlOperation, shallowUpdateConsortium } from "../../../store/queries";
import { DATETIME_FORMAT_UI } from "../../../utils/constants";
import { GridColumn } from "../../../utils/types";
import NewUserDrawer from "./NewUserDrawer";

const columns: GridColumn<EndUser>[] = [
  { field: "id", headerName: "ID", align: "center", headerAlign: "center" },
  {
    field: "createdAt",
    headerName: "Data",
    align: "center",
    minWidth: 150,
    flex: 1,
    headerAlign: "center",
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
  },

  {
    field: "userType",
    headerName: "Tipo utente",
    align: "center",
    headerAlign: "center",
    minWidth: 150,
    renderCell: ({ row }) => <Chip label={row.userType} />,
  },
  {
    field: "firstName",
    headerName: "Nome",
    align: "center",
    headerAlign: "center",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Cognome",
    align: "center",
    headerAlign: "center",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    align: "center",
    headerAlign: "center",
    minWidth: 200,
    flex: 2,
  },
  {
    field: "",
    width: 200,
    hideSortIcons: true,
    renderCell: ({ row }) => (
      <Button
        sx={{ pl: 0 }}
        endIcon={<Icon icon="dashicons:trash" />}
        color="error"
      />
    ),
  },
];

function UsersPage() {
  const [showNewUserDrawer, setShowNewUserDrawer] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium!
  );

  const users = currentConsortium!.endUsers?.items as EndUser[];
  const confirm = useConfirm();
  const dispatch = useDispatch();

  return (
    <Page title="Utenti | ABIoT">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Programmi irriguo</Typography>
        </Box> */}
        <Card>
          <CardHeader
            title="Utenti"
            subheader="Qui puoi visualizzare gli utenti finali che hanno accesso al sistema"
            action={
              <Button
                variant="outlined"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => setShowNewUserDrawer(true)}
              >
                Nuovo utente
              </Button>
            }
          />
          <NewUserDrawer
            open={showNewUserDrawer}
            onClose={() => setShowNewUserDrawer(false)}
          />
          <CardContent>
            <DataGrid
              rows={users}
              columns={[
                ...columns,
                {
                  field: "",
                  width: 200,
                  hideSortIcons: true,
                  renderCell: ({ row }) => (
                    <Button
                      sx={{ pl: 0 }}
                      endIcon={<Icon icon="dashicons:trash" />}
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirm({
                          title: `Sei sicuro di voler eliminare questo utente (${row.email})?`,
                          description: "Questa azione è irreversibile.",
                          confirmationText: "Elimina",
                          cancellationText: "Annulla",
                        })
                          .then(async () => {
                            setLoading(true);
                            await gqlOperation(deleteEndUser, {
                              input: { id: row.id },
                            });

                            shallowUpdateConsortium("endUsers", (val) => ({
                              items: val.items.filter(
                                (u: any) => u.id !== row.id
                              ),
                            }));
                            setLoading(false);
                          })
                          .catch(() => {});
                      }}
                    />
                  ),
                },
              ]}
              pageSize={20}
              disableColumnFilter
              disableColumnMenu
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableDensitySelector
              showCellRightBorder
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default UsersPage;
