/* eslint-disable no-nested-ternary */
import eyeFill from "@iconify/icons-eva/eye-fill";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Drawer,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DataGrid, itIT } from "@mui/x-data-grid";
import * as datefns from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteNodeMutationVariables,
  ListNodesShallowQuery,
  ListNodesShallowQueryVariables,
  Node,
  NodeStatus,
  OnChangeNodeSubscription,
} from "../../../API";
import Page from "../../../components/Page";
import NewNodeDrawer from "../../../components/_dashboard/map/NewNodeDrawer";
import { deleteNode } from "../../../graphql/mutations";
import type { RootState } from "../../../store";
import { setCurrentConsortium } from "../../../store/appConfigSlice";
import {
  getConsortium,
  gqlObserve,
  gqlOperation,
} from "../../../store/queries";
import { renderIfNotEmpty } from "../../../utils";
import useShowMessage, { usePress } from "../../../utils/hooks";
import { GridColumn } from "../../../utils/types";
import BlurLoader from "../../../components/BlurLoader";
import { onChangeNode } from "../../../graphql/subscriptions";
import { listNodesShallow } from "../../../graphql/customQueries";

function NodesPage() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isCreatingNode, setCreatingNode] = useState(false);
  const [viewMode, setViewMode] = useState<"list" | "cards">("cards");
  const [modifyingNode, setModifyingNode] = useState<Node | undefined>(
    undefined
  );
  const confirm = useConfirm();
  const showMessage = useShowMessage();
  const dispatch = useDispatch();
  const { currentConsortium, userGroups } = useSelector(
    (state: RootState) => state.appConfig
  );

  const [favouriteNodes, setFavouriteNodes] = useState<string[]>(() => {
    const saved = localStorage.getItem(
      `favouriteNodes-${currentConsortium!.id}`
    );
    const initialValue = JSON.parse(saved!) || [];
    return initialValue;
  });

  const [viewFavourites, setViewFavourites] = useState<"all" | "favourites">(
    () => {
      const saved = localStorage.getItem(
        `viewFavourites-${currentConsortium!.id}`
      );
      const initialValue = (saved as "all" | "favourites") || "all";
      return initialValue;
    }
  );
  // const onFavouriteHandler = useLongPress((nodeId: string) => {
  //   let added = false;
  //   setFavouriteNodes((prev) => {
  //     if (prev.includes(nodeId)) {
  //       added = false;
  //       return prev.filter((id) => id !== nodeId);
  //     }
  //     added = true;
  //     return [...prev, nodeId];
  //   });
  //   localStorage.setItem(
  //     `favouriteNodes-${currentConsortium!.id}`,
  //     JSON.stringify(favouriteNodes)
  //   );
  //   alert(
  //     `Nodo ${
  //       added ? "aggiunto ai preferiti" : "rimosso dai preferiti"
  //     } con successo`
  //   );
  // }, 1000);

  const nodePressHandler = usePress({
    onLongPress: useCallback(
      (event: any) => {
        let parentCard = event.target as HTMLElement;
        while (!parentCard.className.includes("MuiPaper-root")) {
          parentCard = parentCard.parentElement!;
        }
        const nodeId = parentCard.id;

        let added = false;
        let newVal = favouriteNodes;
        if (favouriteNodes.includes(nodeId)) {
          added = false;
          newVal = favouriteNodes.filter((id) => id !== nodeId);
        } else {
          added = true;
          newVal = [...favouriteNodes, nodeId];
        }
        setFavouriteNodes(newVal);

        localStorage.setItem(
          `favouriteNodes-${currentConsortium!.id}`,
          JSON.stringify(newVal)
        );
        showMessage(
          `Nodo ${
            added ? "aggiunto ai preferiti" : "rimosso dai preferiti"
          } con successo`,
          "success"
        );
      },
      [favouriteNodes]
    ),
    onPress: useCallback((id: string) => {
      console.log("short press");
      navigate(`/dashboard/nodes/${id}`);
    }, []),
  });

  // effect to refresh nodes list on single node update
  useEffect(() => {
    const fetchList = async () => {
      const newNodesList = await gqlOperation(
        listNodesShallow as ListNodesShallowQuery,
        {
          id: currentConsortium!.id,
        } as ListNodesShallowQueryVariables
      );
      console.log("newNodesList", newNodesList);
      dispatch(
        setCurrentConsortium({
          ...(currentConsortium as any),
          nodes: newNodesList.getConsortium!.nodes,
        })
      );
    };
    return gqlObserve(
      "nodesList-onChangeNode-refresh",
      onChangeNode as OnChangeNodeSubscription,
      fetchList,
      { consortiumID: currentConsortium!.id }
    );
  }, []);

  const nodes = currentConsortium!.nodes!.items as Node[];
  const isManagerUser =
    userGroups!.includes("manager") || userGroups?.includes("admin");

  const columns: GridColumn<Node>[] = [
    {
      field: "",
      width: 100,
      hideSortIcons: true,
      renderCell: ({ row }) => (
        <ButtonGroup size="large">
          <IconButton
            // color="info"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/dashboard/nodes/${row.id}`);
            }}
          >
            <Icon icon={eyeFill} />
          </IconButton>
          {isManagerUser && (
            <IconButton
              // color="info"
              onClick={(e) => {
                e.stopPropagation();
                setModifyingNode(row);
                setCreatingNode(true);
                // /  navigate(`/dashboard/nodes/${row.id}`);
              }}
            >
              <Icon icon="dashicons:edit" />
            </IconButton>
          )}
        </ButtonGroup>
      ),
    },

    {
      field: "nodeType",
      headerName: "",
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <>
          {row.status === NodeStatus.ERROR && (
            <Icon color="red" fontSize={15} icon="eva:alert-triangle-outline" />
          )}
          <Icon
            fontSize={20}
            icon={
              row.nodeType === "SENSOR"
                ? "material-symbols:thermostat"
                : "mdi:switch"
            }
          />
        </>
      ),
      sortable: false,
      width: 40,
    },
    {
      field: "title",
      sortable: true,
      headerName: "Titolo",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: ({ row }) => `${row.idxSensor} - ${row.title}`,
    },
    {
      field: "status",
      headerName: "Stato",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 100,
      renderCell: ({ row }) => (
        <Typography
          variant="body2"
          sx={{
            color:
              row.status === NodeStatus.ERROR
                ? "error.main"
                : row.status === NodeStatus.OK
                ? "success.main"
                : "warning.main",
          }}
        >
          {row.status}
        </Typography>
      ),
    },

    // {
    //   field: "createdAt",
    //   sortable: false,
    //   headerName: "Creato",
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: ({ row }) => (
    //     <span style={{ fontSize: 10 }}>{row.createdAt}</span>
    //   ),
    //   minWidth: 150,
    // },
    {
      field: "updatedAt",
      headerName: "Ultima Com.",
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.updatedAt === row.createdAt
          ? "-"
          : `${datefns.formatDistanceToNow(new Date(row.updatedAt), {
              includeSeconds: true,
            })}`,
      minWidth: 150,
    },

    {
      sortable: false,
      field: "battery",
      headerName: "Batteria",
      align: "center",
      headerAlign: "center",
      // flex: 1,
      renderCell: renderIfNotEmpty("battery"),
      width: 100,
    },
    {
      field: "signal",
      headerName: "Segnale",
      sortable: false,
      align: "center",
      headerAlign: "center",
      // flex: 2,
      renderCell: renderIfNotEmpty("signal"),
      width: 100,
    },
    {
      field: "temperature",
      sortable: false,
      headerName: "Temp.Aria",
      align: "center",
      headerAlign: "center",
      // flex: 2,
      renderCell: renderIfNotEmpty("temperature"),
      width: 100,
    },
    {
      field: "waterMark4",
      headerName: "",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 100,
      renderCell: ({ row }) =>
        isManagerUser && (
          <Button
            sx={{ pl: 0 }}
            endIcon={<Icon icon="dashicons:trash" />}
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              confirm({
                title: "Elimina nodo",
                description: `Sei sicuro di voler eliminare il nodo ${row.title}?`,
                confirmationText: "Elimina",
                cancellationText: "Annulla",
              })
                .then(async () => {
                  setLoading(true);
                  await gqlOperation(deleteNode, {
                    input: { id: row.id },
                  } as DeleteNodeMutationVariables);
                  showMessage("Nodo eliminato con successo", "success");
                  const newConsr = await getConsortium(currentConsortium!.id);
                  dispatch(setCurrentConsortium(newConsr));
                  setLoading(false);
                })
                .catch(() => {});
            }}
          />
        ),
    },
  ];

  return (
    <Page title="Nodi | ABIoT">
      <BlurLoader isLoading={isLoading}>
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Lista Nodi ({nodes.length})</Typography>
          </Box>
          <Card sx={{ pb: 2.5, mb: 2.5 }} raised={false}>
            <CardHeader
              title=""
              subheader="Qui puoi visualizzare i nodi registrati nel sistema. Premi a lungo per aggiungere ai preferiti."
              action={
                isManagerUser && (
                  <Button
                    variant="outlined"
                    component={Link}
                    to="#"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={() => {
                      setCreatingNode(true);
                    }}
                  >
                    Nuovo Nodo
                  </Button>
                )
              }
            />
            <Drawer
              variant="temporary"
              anchor="right"
              ModalProps={{
                keepMounted: false,
              }}
              keepMounted={false}
              SlideProps={{
                unmountOnExit: true,
              }}
              open={isCreatingNode}
            >
              {isCreatingNode && (
                <NewNodeDrawer
                  outlinedObject={modifyingNode}
                  onClose={() => {
                    setCreatingNode(false);
                    setModifyingNode(undefined);
                  }}
                />
              )}
            </Drawer>
          </Card>
          <ToggleButtonGroup
            value={viewMode}
            size="large"
            exclusive
            onChange={(event, newValue) => {
              setViewMode(newValue);
            }}
            sx={{ mb: 2.5 }}
          >
            <ToggleButton value="cards" aria-label="module">
              <Icon icon="eva:grid-fill" />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <Icon icon="eva:menu-2-fill" />
            </ToggleButton>
          </ToggleButtonGroup>
          <div>
            <ToggleButtonGroup
              value={viewFavourites}
              size="medium"
              exclusive
              sx={{ mb: 2.5 }}
              onChange={(event, newValue) => {
                setViewFavourites(newValue);
                localStorage.setItem(
                  `viewFavourites-${currentConsortium!.id}`,
                  newValue!
                );
              }}
            >
              <ToggleButton value="all">Tutti</ToggleButton>
              <ToggleButton value="favourites">
                <Icon
                  icon="eva:heart-fill"
                  style={{ color: "#9f9f9f", fontSize: "1.5em" }}
                />
                Preferiti
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {viewMode === "cards" ? (
            <Grid container spacing={3}>
              {nodes.map((node) =>
                viewFavourites === "favourites" &&
                !favouriteNodes.includes(node.id) ? null : (
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      key={node.id}
                      id={node.id}
                      // sx={{ mb: 2.5 }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...nodePressHandler(node.id)}
                      // onMouseDown={() => onFavouriteHandler[0](node.id)}
                      // onMouseUp={() => onFavouriteHandler[1](node.id)}
                      // onTouchStart={() => onFavouriteHandler[0](node.id)}
                      // onTouchEnd={() => onFavouriteHandler[1](node.id)}
                    >
                      <CardActionArea style={{ padding: 15 }}>
                        <Icon
                          icon={
                            favouriteNodes.includes(node.id)
                              ? "eva:heart-fill"
                              : "eva:heart-outline"
                          }
                          style={{
                            color: "red",
                            fontSize: "1.5em",
                            position: "absolute",
                            right: 10,
                            top: 10,
                          }}
                        />
                        <Typography variant="h6">
                          {node.idxSensor} - {node.title}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Chip
                            size="small"
                            label={node.status}
                            color={
                              node.status === NodeStatus.ERROR
                                ? "error"
                                : "success"
                            }
                          />
                          <span>
                            <Icon icon="mdi:signal" /> {node.signal}dBm
                          </span>
                          <span>
                            <Icon icon="mdi:thermometer" /> {node.temperature}°C
                          </span>
                          <span>
                            <Icon icon="mdi:battery" /> {node.battery}%
                          </span>
                          <span>
                            <Icon icon="eva:clock" />{" "}
                            {datefns.formatDistanceToNow(
                              new Date(node!.updatedAt!),
                              {
                                includeSeconds: true,
                              }
                            )}
                          </span>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <Card>
              <CardContent>
                <DataGrid
                  // sortModel={[{ field: "updatedAt", sort: "desc" }]}
                  rows={nodes}
                  columns={columns}
                  // pageSize={100}
                  hideFooterSelectedRowCount
                  disableColumnFilter
                  disableColumnMenu
                  autoHeight
                  disableColumnSelector
                  disableSelectionOnClick
                  disableDensitySelector
                  showCellRightBorder
                  getRowClassName={({ row }) => {
                    if (row.status === NodeStatus.ERROR) {
                      return "nodes-row-error";
                    }
                    return "";
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  onRowClick={async ({ row }) => {
                    navigate(`/dashboard/nodes/${row.id}`);
                  }}
                />
              </CardContent>
            </Card>
          )}
        </Container>
      </BlurLoader>
    </Page>
  );
}

export default NodesPage;
