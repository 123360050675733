/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2aaec30c-9912-44bf-887f-051575f99f3c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qD0oJrWIk",
    "aws_user_pools_web_client_id": "4o2p9cejirdeccafhfop2khvr9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://xwmgymc2pnc6nkc276d46ukbn4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_dangerously_connect_to_http_endpoint_for_testing": false,
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://860i9i1wgb.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
