import { Icon } from "@iconify/react";
import { Button, Card, CardContent, CardHeader, Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as datefns from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DeleteNotificationMutationVariables,
  Notification,
  NotificationPriority,
} from "../../../API";
import { deleteNotification } from "../../../graphql/mutations";
import { RootState } from "../../../store";
import { setCurrentConsortium } from "../../../store/appConfigSlice";
import { gqlOperation } from "../../../store/queries";
import { DATETIME_FORMAT_UI } from "../../../utils/constants";
import { useRoleChecker } from "../../../utils/hooks";

export const PRIORITY_LABELS = {
  [NotificationPriority.HIGH]: "Alta",
  [NotificationPriority.LOW]: "Bassa",
  [NotificationPriority.MEDIUM]: "Media",
};

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Data creazione",
    align: "center",
    headerAlign: "center",
    width: 150,
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
  },
  {
    field: "priority",
    headerName: "Priorità",
    align: "center",
    headerAlign: "center",
    width: 150,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        color={row.priority === NotificationPriority.HIGH ? "error" : "warning"}
        label={PRIORITY_LABELS[row.priority as NotificationPriority]}
      />
    ),
  },
  {
    field: "message",
    headerName: "Messaggio",
    align: "center",
    headerAlign: "center",
    minWidth: 300,
    flex: 2,
  },
];

type Props = {
  notifications: Notification[];
};

export default function NotificationsList({ notifications }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium!
  );
  const roleCheck = useRoleChecker();
  return (
    <Card>
      <CardHeader
        title="Notifiche"
        subheader="Qui puoi visualizzare le notifiche ricevute"
        // action={
        //   <Button
        //     variant="outlined"
        //     startIcon={<Icon icon={plusFill} />}
        //     onClick={() => {}}
        //   >
        //     Attiva notifiche di sistema
        //   </Button>
        // }
      />

      <CardContent>
        <DataGrid
          loading={isLoading}
          rows={notifications}
          columns={[
            ...columns,
            {
              field: "",
              hideSortIcons: true,
              renderCell: ({ row }) =>
                roleCheck("manager") && (
                  <Button
                    startIcon={<Icon icon="bi:check-all" />}
                    onClick={async () => {
                      setIsLoading(true);
                      await gqlOperation(deleteNotification, {
                        input: { id: row.id },
                      } as DeleteNotificationMutationVariables);
                      dispatch(
                        setCurrentConsortium({
                          ...currentConsortium,
                          notifications: {
                            __typename: "ModelNotificationConnection",
                            ...currentConsortium.notifications,
                            items:
                              currentConsortium.notifications!.items.filter(
                                (n: any) => n.id !== row.id
                              ),
                          },
                        })
                      );
                      setIsLoading(false);
                    }}
                  >
                    Elimina
                  </Button>
                ),
            },
          ]}
          pageSize={30}
          disableColumnFilter
          disableColumnMenu
          autoHeight
          disableColumnSelector
          disableSelectionOnClick
          disableDensitySelector
          showCellRightBorder
        />
      </CardContent>
    </Card>
  );
}
