/* eslint-disable no-param-reassign */
// This optional code is used to register a service worker.
// register() is not called by default.

import { Auth } from "aws-amplify";
import {
  createNotificationSubscriber,
  updateNotificationSubscriber,
} from "./graphql/mutations";
import { gqlOperation } from "./store/queries";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  immediateRegister?: boolean;
};

export async function register(config?: Config) {
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      alert("Public url origin is not equal to window location origin");
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const logic = async () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const swUrlLocal = `${process.env.PUBLIC_URL}/sw.js`;

      if (isLocalhost) {
        await registerValidSW(swUrlLocal, config);
      } else {
        // Is not localhost. Just register service worker
        await registerValidSW(swUrl, config);
      }
    };

    if (config?.immediateRegister) {
      await logic();
    } else {
      window.addEventListener("load", logic);
    }
  } else {
    alert(
      "Installa l'applicazione sul tuo dispositivo per ricevere notifiche."
    );
  }
}

const VAPID_KEYS = {
  publicKey:
    "BF4AoZsORS3D_g8lODDP5breXQMp8eKpGNFWjFbHSSC4q2Yt05NkwB_2BGM8C3XIzSbsiuZecKMvCN5UKFJnTPc",
};
function registerValidSW(swUrl: string, config?: Config) {
  console.log("registring service worker");
  return navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://cra.link/PWA."
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };

      Notification.requestPermission().then((permissionResult) => {
        if (permissionResult === "granted") {
          console.log("Can show notifications");
        }
      });

      registration.pushManager.getSubscription().then(async (psub) => {
        let subscription = psub;
        if (subscription) {
          console.log("notifications subscription", psub);
          // alert(`notifications subscription exists${JSON.stringify(psub)}`);
          // subscription.unsubscribe();
        } else {
          // alert("notifications subscription does not exist");
          try {
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: VAPID_KEYS.publicKey,
            });
          } catch (error) {
            console.error(JSON.stringify(error));
          }
          console.log("notifications subscription");
          console.log(`${JSON.stringify(subscription)}`);
          // alert("notifications subscription created");
          // get consortiumId from local storage
        }
        if (!subscription) return;
        try {
          const { payload } = (await Auth.currentSession()).getIdToken();
          const resolvedUserGroups = payload["cognito:groups"];
          const consortiumId = payload["custom:consortiumID"];
          try {
            await gqlOperation(updateNotificationSubscriber, {
              input: {
                subscription: JSON.stringify(subscription),
                consortiumID: consortiumId || resolvedUserGroups[0],
                userGroup: resolvedUserGroups[0],
              },
            });
          } catch (error) {
            await gqlOperation(createNotificationSubscriber, {
              input: {
                subscription: JSON.stringify(subscription),
                consortiumID: consortiumId || resolvedUserGroups[0],
                userGroup: resolvedUserGroups[0],
              },
            });
          }
        } catch (error) {
          console.log(error);
          alert(error);
        }
      });
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
      // alert(JSON.stringify(error));
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
