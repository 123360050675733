import { Icon } from "@iconify/react";
import Leaflet, { LatLngLiteral } from "leaflet";
import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

type Props = {
  position: LatLngLiteral;
  onMove: (ev: Leaflet.Map) => void;
  children?: React.ReactNode;
};
let mapInstance: Leaflet.Map;

export default class MapLocationSelector extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const { position } = this.props;
    return (
      position.lat !== nextProps.position.lat ||
      position.lng !== nextProps.position.lng
    );
  }

  render() {
    const { onMove, position, children } = this.props;
    if (
      mapInstance?.getCenter()?.lat !== position.lat &&
      mapInstance?.getCenter()?.lng !== position.lng
    ) {
      mapInstance?.flyTo(position, undefined, { duration: 1.5 });
    }
    return (
      <MapContainer
        center={position}
        zoom={15}
        scrollWheelZoom={false}
        style={{
          height: 300,
          cursor: "pointer",
          // boxShadow: "0 0 10px grey",
          borderRadius: 7,
          border: "1px solid grey",
        }}
        attributionControl={false}
        whenCreated={(map) => {
          mapInstance = map;
          map.on("moveend", () => onMove(map));
        }}
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={["mt1", "mt2", "mt3"]}
        />

        <Icon icon="fluent:location-12-filled" className="center-marker" />
        {children}
      </MapContainer>
    );
  }
}
