/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onChangeConsortiums = /* GraphQL */ `subscription OnChangeConsortiums($consortiumID: ID, $id: ID) {
  onChangeConsortiums(consortiumID: $consortiumID, id: $id) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeConsortiumsSubscriptionVariables,
  APITypes.OnChangeConsortiumsSubscription
>;
export const onChangeNode = /* GraphQL */ `subscription OnChangeNode($consortiumID: ID!, $id: ID) {
  onChangeNode(consortiumID: $consortiumID, id: $id) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeNodeSubscriptionVariables,
  APITypes.OnChangeNodeSubscription
>;
export const onChangeProgram = /* GraphQL */ `subscription OnChangeProgram($consortiumID: ID!, $id: ID) {
  onChangeProgram(consortiumID: $consortiumID, id: $id) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeProgramSubscriptionVariables,
  APITypes.OnChangeProgramSubscription
>;
export const onChangeAlarm = /* GraphQL */ `subscription OnChangeAlarm($consortiumID: ID!, $id: ID) {
  onChangeAlarm(consortiumID: $consortiumID, id: $id) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeAlarmSubscriptionVariables,
  APITypes.OnChangeAlarmSubscription
>;
export const onChangeNotification = /* GraphQL */ `subscription OnChangeNotification($consortiumID: ID!, $id: ID) {
  onChangeNotification(consortiumID: $consortiumID, id: $id) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeNotificationSubscriptionVariables,
  APITypes.OnChangeNotificationSubscription
>;
export const onCreateConsortium = /* GraphQL */ `subscription OnCreateConsortium(
  $filter: ModelSubscriptionConsortiumFilterInput
  $id: String
) {
  onCreateConsortium(filter: $filter, id: $id) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConsortiumSubscriptionVariables,
  APITypes.OnCreateConsortiumSubscription
>;
export const onUpdateConsortium = /* GraphQL */ `subscription OnUpdateConsortium(
  $filter: ModelSubscriptionConsortiumFilterInput
  $id: String
) {
  onUpdateConsortium(filter: $filter, id: $id) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConsortiumSubscriptionVariables,
  APITypes.OnUpdateConsortiumSubscription
>;
export const onDeleteConsortium = /* GraphQL */ `subscription OnDeleteConsortium(
  $filter: ModelSubscriptionConsortiumFilterInput
  $id: String
) {
  onDeleteConsortium(filter: $filter, id: $id) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConsortiumSubscriptionVariables,
  APITypes.OnDeleteConsortiumSubscription
>;
export const onCreateNode = /* GraphQL */ `subscription OnCreateNode($filter: ModelSubscriptionNodeFilterInput) {
  onCreateNode(filter: $filter) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeSubscriptionVariables,
  APITypes.OnCreateNodeSubscription
>;
export const onUpdateNode = /* GraphQL */ `subscription OnUpdateNode($filter: ModelSubscriptionNodeFilterInput) {
  onUpdateNode(filter: $filter) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeSubscriptionVariables,
  APITypes.OnUpdateNodeSubscription
>;
export const onDeleteNode = /* GraphQL */ `subscription OnDeleteNode($filter: ModelSubscriptionNodeFilterInput) {
  onDeleteNode(filter: $filter) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeSubscriptionVariables,
  APITypes.OnDeleteNodeSubscription
>;
export const onCreateNodeDataRecord = /* GraphQL */ `subscription OnCreateNodeDataRecord(
  $filter: ModelSubscriptionNodeDataRecordFilterInput
) {
  onCreateNodeDataRecord(filter: $filter) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeDataRecordSubscriptionVariables,
  APITypes.OnCreateNodeDataRecordSubscription
>;
export const onUpdateNodeDataRecord = /* GraphQL */ `subscription OnUpdateNodeDataRecord(
  $filter: ModelSubscriptionNodeDataRecordFilterInput
) {
  onUpdateNodeDataRecord(filter: $filter) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeDataRecordSubscriptionVariables,
  APITypes.OnUpdateNodeDataRecordSubscription
>;
export const onDeleteNodeDataRecord = /* GraphQL */ `subscription OnDeleteNodeDataRecord(
  $filter: ModelSubscriptionNodeDataRecordFilterInput
) {
  onDeleteNodeDataRecord(filter: $filter) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeDataRecordSubscriptionVariables,
  APITypes.OnDeleteNodeDataRecordSubscription
>;
export const onCreateNodeStatisticsDaily = /* GraphQL */ `subscription OnCreateNodeStatisticsDaily(
  $filter: ModelSubscriptionNodeStatisticsDailyFilterInput
) {
  onCreateNodeStatisticsDaily(filter: $filter) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeStatisticsDailySubscriptionVariables,
  APITypes.OnCreateNodeStatisticsDailySubscription
>;
export const onUpdateNodeStatisticsDaily = /* GraphQL */ `subscription OnUpdateNodeStatisticsDaily(
  $filter: ModelSubscriptionNodeStatisticsDailyFilterInput
) {
  onUpdateNodeStatisticsDaily(filter: $filter) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeStatisticsDailySubscriptionVariables,
  APITypes.OnUpdateNodeStatisticsDailySubscription
>;
export const onDeleteNodeStatisticsDaily = /* GraphQL */ `subscription OnDeleteNodeStatisticsDaily(
  $filter: ModelSubscriptionNodeStatisticsDailyFilterInput
) {
  onDeleteNodeStatisticsDaily(filter: $filter) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeStatisticsDailySubscriptionVariables,
  APITypes.OnDeleteNodeStatisticsDailySubscription
>;
export const onCreateNodeStatisticsMonthly = /* GraphQL */ `subscription OnCreateNodeStatisticsMonthly(
  $filter: ModelSubscriptionNodeStatisticsMonthlyFilterInput
) {
  onCreateNodeStatisticsMonthly(filter: $filter) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeStatisticsMonthlySubscriptionVariables,
  APITypes.OnCreateNodeStatisticsMonthlySubscription
>;
export const onUpdateNodeStatisticsMonthly = /* GraphQL */ `subscription OnUpdateNodeStatisticsMonthly(
  $filter: ModelSubscriptionNodeStatisticsMonthlyFilterInput
) {
  onUpdateNodeStatisticsMonthly(filter: $filter) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeStatisticsMonthlySubscriptionVariables,
  APITypes.OnUpdateNodeStatisticsMonthlySubscription
>;
export const onDeleteNodeStatisticsMonthly = /* GraphQL */ `subscription OnDeleteNodeStatisticsMonthly(
  $filter: ModelSubscriptionNodeStatisticsMonthlyFilterInput
) {
  onDeleteNodeStatisticsMonthly(filter: $filter) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeStatisticsMonthlySubscriptionVariables,
  APITypes.OnDeleteNodeStatisticsMonthlySubscription
>;
export const onCreateNodeOpenings = /* GraphQL */ `subscription OnCreateNodeOpenings(
  $filter: ModelSubscriptionNodeOpeningsFilterInput
) {
  onCreateNodeOpenings(filter: $filter) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeOpeningsSubscriptionVariables,
  APITypes.OnCreateNodeOpeningsSubscription
>;
export const onUpdateNodeOpenings = /* GraphQL */ `subscription OnUpdateNodeOpenings(
  $filter: ModelSubscriptionNodeOpeningsFilterInput
) {
  onUpdateNodeOpenings(filter: $filter) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeOpeningsSubscriptionVariables,
  APITypes.OnUpdateNodeOpeningsSubscription
>;
export const onDeleteNodeOpenings = /* GraphQL */ `subscription OnDeleteNodeOpenings(
  $filter: ModelSubscriptionNodeOpeningsFilterInput
) {
  onDeleteNodeOpenings(filter: $filter) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeOpeningsSubscriptionVariables,
  APITypes.OnDeleteNodeOpeningsSubscription
>;
export const onCreateProgram = /* GraphQL */ `subscription OnCreateProgram($filter: ModelSubscriptionProgramFilterInput) {
  onCreateProgram(filter: $filter) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProgramSubscriptionVariables,
  APITypes.OnCreateProgramSubscription
>;
export const onUpdateProgram = /* GraphQL */ `subscription OnUpdateProgram($filter: ModelSubscriptionProgramFilterInput) {
  onUpdateProgram(filter: $filter) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProgramSubscriptionVariables,
  APITypes.OnUpdateProgramSubscription
>;
export const onDeleteProgram = /* GraphQL */ `subscription OnDeleteProgram($filter: ModelSubscriptionProgramFilterInput) {
  onDeleteProgram(filter: $filter) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProgramSubscriptionVariables,
  APITypes.OnDeleteProgramSubscription
>;
export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onCreateNotification(filter: $filter) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onUpdateNotification(filter: $filter) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onDeleteNotification(filter: $filter) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
export const onCreateNodeLog = /* GraphQL */ `subscription OnCreateNodeLog($filter: ModelSubscriptionNodeLogFilterInput) {
  onCreateNodeLog(filter: $filter) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNodeLogSubscriptionVariables,
  APITypes.OnCreateNodeLogSubscription
>;
export const onUpdateNodeLog = /* GraphQL */ `subscription OnUpdateNodeLog($filter: ModelSubscriptionNodeLogFilterInput) {
  onUpdateNodeLog(filter: $filter) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNodeLogSubscriptionVariables,
  APITypes.OnUpdateNodeLogSubscription
>;
export const onDeleteNodeLog = /* GraphQL */ `subscription OnDeleteNodeLog($filter: ModelSubscriptionNodeLogFilterInput) {
  onDeleteNodeLog(filter: $filter) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNodeLogSubscriptionVariables,
  APITypes.OnDeleteNodeLogSubscription
>;
export const onCreateAlarm = /* GraphQL */ `subscription OnCreateAlarm($filter: ModelSubscriptionAlarmFilterInput) {
  onCreateAlarm(filter: $filter) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAlarmSubscriptionVariables,
  APITypes.OnCreateAlarmSubscription
>;
export const onUpdateAlarm = /* GraphQL */ `subscription OnUpdateAlarm($filter: ModelSubscriptionAlarmFilterInput) {
  onUpdateAlarm(filter: $filter) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAlarmSubscriptionVariables,
  APITypes.OnUpdateAlarmSubscription
>;
export const onDeleteAlarm = /* GraphQL */ `subscription OnDeleteAlarm($filter: ModelSubscriptionAlarmFilterInput) {
  onDeleteAlarm(filter: $filter) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAlarmSubscriptionVariables,
  APITypes.OnDeleteAlarmSubscription
>;
export const onCreateEndUser = /* GraphQL */ `subscription OnCreateEndUser($filter: ModelSubscriptionEndUserFilterInput) {
  onCreateEndUser(filter: $filter) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEndUserSubscriptionVariables,
  APITypes.OnCreateEndUserSubscription
>;
export const onUpdateEndUser = /* GraphQL */ `subscription OnUpdateEndUser($filter: ModelSubscriptionEndUserFilterInput) {
  onUpdateEndUser(filter: $filter) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEndUserSubscriptionVariables,
  APITypes.OnUpdateEndUserSubscription
>;
export const onDeleteEndUser = /* GraphQL */ `subscription OnDeleteEndUser($filter: ModelSubscriptionEndUserFilterInput) {
  onDeleteEndUser(filter: $filter) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEndUserSubscriptionVariables,
  APITypes.OnDeleteEndUserSubscription
>;
