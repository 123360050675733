import clockFill from "@iconify/icons-eva/clock-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import nodeFill from "@iconify/icons-eva/radio-button-on-fill";
import alarmFill from "@iconify/icons-eva/sun-fill";
import { Icon, IconifyIcon } from "@iconify/react";

// ----------------------------------------------------------------------

const getIcon = (name: IconifyIcon) => (
  <Icon icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Nodi",
    path: "/dashboard/nodes",
    icon: getIcon(nodeFill),
  },
  {
    title: "Programmi",
    path: "/dashboard/programs",
    icon: getIcon(clockFill),
    role: "manager",
  },
  {
    title: "Allarmi",
    path: "/dashboard/alarms",
    icon: getIcon(alarmFill),
  },
  {
    title: "Utenti",
    path: "/dashboard/users",
    icon: getIcon(peopleFill),
    role: "manager",
  },

  // {
  //   title: "login",
  //   path: "/login",
  //   icon: getIcon(lockFill),
  // },
  // {
  //   title: "register",
  //   path: "/register",
  //   icon: getIcon(personAddFill),
  // },
  // {
  //   title: "Not found",
  //   path: "/404",
  //   icon: getIcon(alertTriangleFill),
  // },
];

export default sidebarConfig;
