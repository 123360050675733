import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Drawer,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as datefns from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BounceLoader } from "react-spinners";
import { Link } from "react-router-dom";
import {
  Alarm,
  AlarmOperator,
  DeleteAlarmMutationVariables,
} from "../../../API";
import Page from "../../../components/Page";
import { deleteAlarm } from "../../../graphql/mutations";
import { RootState } from "../../../store";
import { setCurrentConsortium } from "../../../store/appConfigSlice";
import { getConsortium, gqlOperation } from "../../../store/queries";
import { DATETIME_FORMAT_UI, SENSOR_LABELS } from "../../../utils/constants";
import { GridColumn } from "../../../utils/types";
import NewAlarmDrawer from "./NewAlarmDrawer";

const operatorToSymbol = (operator: AlarmOperator) => {
  switch (operator) {
    case AlarmOperator.EQ:
      return "=";
    case AlarmOperator.GT:
      return ">";
    case AlarmOperator.LT:
      return "<";
  }
  return "-";
};

function AlarmsPage() {
  const [showNewAlarmDrawer, setShowNewAlarmDrawer] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [outlinedAlarm, setOutlinedAlarm] = useState<Alarm | undefined>(
    undefined
  );

  const { currentConsortium, userGroups } = useSelector(
    (state: RootState) => state.appConfig
  )!;

  const isManagerUser =
    userGroups!.includes("manager") || userGroups?.includes("admin");

  const alarms = currentConsortium!.alarms!.items as Alarm[];
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const columns: GridColumn<Alarm>[] = [
    {
      field: "name",
      headerName: "Titolo",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "property",
      headerName: "Soglia",
      align: "center",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) =>
        `${
          SENSOR_LABELS[row.property as keyof typeof SENSOR_LABELS]
        } ${operatorToSymbol(row.operator)} ${row.value}`,
    },
    {
      field: "currentlyFiringNodes",
      headerName: "Stato",
      align: "center",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={50000}
          title={
            <ul>
              {row.currentlyFiringNodes?.map((nodeId: string) => {
                const node = currentConsortium?.nodes?.items?.find(
                  (n) => n?.id === nodeId
                );
                return (
                  <li key={nodeId} style={{ lineHeight: 1.5, padding: 5 }}>
                    <Link
                      style={{
                        color: "white",
                        fontSize: 15,
                      }}
                      to={`/dashboard/nodes/${nodeId}`}
                    >
                      {node?.idxSensor} {node?.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          }
        >
          <Chip
            icon={
              <Icon
                icon={
                  row.currentlyFiringNodes?.length
                    ? "eva:alert-circle-outline"
                    : "eva:checkmark-circle-2-outline"
                }
              />
            }
            color={row.currentlyFiringNodes?.length ? "error" : "primary"}
            label={`${row.currentlyFiringNodes?.length || 0} nodi in allarme`}
            variant="outlined"
          />
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Data creazione",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) =>
        datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
    },
    {
      field: "",
      width: 200,
      hideSortIcons: true,
      renderCell: ({ row }) =>
        isManagerUser && (
          <ButtonGroup>
            <Button
              startIcon={<Icon icon="dashicons:edit" />}
              onClick={() => {
                setOutlinedAlarm(row);
                setShowNewAlarmDrawer(true);
              }}
            >
              Modifica
            </Button>
            <Button
              sx={{ pl: 0 }}
              endIcon={<Icon icon="dashicons:trash" />}
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: "Sei sicuro di voler eliminare questa allarme?",
                  description: "Questa azione è irreversibile.",
                  confirmationText: "Elimina",
                  cancellationText: "Annulla",
                })
                  .then(async () => {
                    setLoading(true);
                    await gqlOperation(deleteAlarm, {
                      input: { id: row.id },
                    } as DeleteAlarmMutationVariables);

                    const newConsr = await getConsortium(currentConsortium!.id);
                    dispatch(setCurrentConsortium(newConsr));
                    setLoading(false);
                  })
                  .catch(() => {});
              }}
            />
          </ButtonGroup>
        ),
    },
  ];
  return (
    <Page title="Allarmi | ABIoT">
      {isLoading ? (
        <Box style={{ position: "relative", height: "100vh" }}>
          <BounceLoader
            color="#00AB55"
            css={`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
            `}
            speedMultiplier={1.5}
          />
        </Box>
      ) : (
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">
              Allarmi Sistema ({alarms.length})
            </Typography>
          </Box>
          <Card>
            <CardHeader
              title="Allarmi"
              subheader="Qui puoi visualizzare le allarmi registrate per le quali desideri essere notificato."
              action={
                isManagerUser && (
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={() => setShowNewAlarmDrawer(true)}
                  >
                    Nuova allarme
                  </Button>
                )
              }
            />
            <Drawer
              variant="temporary"
              anchor="right"
              ModalProps={{
                keepMounted: false,
              }}
              keepMounted={false}
              SlideProps={{
                unmountOnExit: true,
              }}
              open={showNewAlarmDrawer}
              PaperProps={{
                sx: {
                  maxWidth: 500,
                },
              }}
            >
              {showNewAlarmDrawer && (
                <NewAlarmDrawer
                  outlinedObject={outlinedAlarm}
                  onClose={() => {
                    setShowNewAlarmDrawer(false);
                    setOutlinedAlarm(undefined);
                  }}
                />
              )}
            </Drawer>
            <CardContent>
              <DataGrid
                rows={alarms}
                columns={columns}
                pageSize={8}
                disableColumnFilter
                disableColumnMenu
                autoHeight
                disableColumnSelector
                disableSelectionOnClick
                disableDensitySelector
                showCellRightBorder
              />
            </CardContent>
          </Card>
        </Container>
      )}
    </Page>
  );
}

export default AlarmsPage;
