/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as datefns from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  GetNodeSortedBaseQuery,
  GetNodeSortedBaseQueryVariables,
  ListNodeStatisticsDailiesQuery,
  ListNodeStatisticsDailiesQueryVariables,
  ListNodeStatisticsMonthliesQuery,
  ListNodeStatisticsMonthliesQueryVariables,
  Node,
  NodeDataRecord,
  NodeLog,
  NodeStatisticsDaily,
  NodeStatisticsMonthly,
  Notification,
  OnChangeNodeSubscription,
  UpdateNodeMutation,
  UpdateNodeMutationVariables,
} from "../../../API";
import AbiotChart from "../../../components/AbiotChart";
import Page from "../../../components/Page";
import SingleNodeView from "../../../components/_dashboard/map/SingleNodeView";
import {
  getNodeSortedBase,
  getNodeSortedManager,
} from "../../../graphql/customQueries";
import { updateNode } from "../../../graphql/mutations";
import {
  listNodeStatisticsDailies,
  listNodeStatisticsMonthlies,
} from "../../../graphql/queries";
import { gqlObserve, gqlOperation } from "../../../store/queries";
import { p2s, renderIfNotEmpty } from "../../../utils";
import { DATETIME_FORMAT_UI, SENSOR_LABELS } from "../../../utils/constants";
import useShowMessage, { useRoleChecker } from "../../../utils/hooks";
import { GridColumn } from "../../../utils/types";
import { onChangeNode } from "../../../graphql/subscriptions";
import { RootState } from "../../../store";
import BlurLoader from "../../../components/BlurLoader";

export type SeriesType = { [k in keyof typeof SENSOR_LABELS]?: any };

const logsColumns: GridColumn<NodeLog>[] = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "createdAt",
    headerName: "Data",
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
    minWidth: 200,
  },
  {
    field: "rawMessage",
    headerName: "Messaggio",
    minWidth: 400,
  },
];

const notificationsColumns: GridColumn<Partial<Notification>>[] = [
  {
    field: "createdAt",
    headerName: "Data",
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
    minWidth: 200,
  },
  {
    field: "message",
    headerName: "Messaggio",
    minWidth: 400,
  },
];

const columns: GridColumn<NodeDataRecord>[] = [
  // { field: "id", headerName: "ID", align: "center", headerAlign: "center" },
  {
    field: "createdAt",
    headerName: "Data",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
    minWidth: 150,
  },
  {
    field: "battery",
    headerName: "Batteria",
    align: "center",
    headerAlign: "center",
    flex: 1,
    renderCell: renderIfNotEmpty("battery"),
  },
  {
    field: "signal",
    headerName: "Segnale",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("signal"),
  },
  {
    field: "temperature",
    headerName: "Temp.Aria",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("temperature"),
  },
  {
    field: "humidity",
    headerName: "Umidità",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("humidity"),
  },
  {
    field: "temperatureGround",
    headerName: "Temp.Terreno",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("temperatureGround"),
  },
  {
    field: "tensiometer1",
    headerName: "T1",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("tensiometer1"),
  },
  {
    field: "tensiometer2",
    headerName: "T2",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("tensiometer2"),
  },
  {
    field: "tensiometer3",
    headerName: "T3",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("tensiometer3"),
  },
  {
    field: "pluvioMeter",
    headerName: "PV",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("pluvioMeter"),
  },
  {
    field: "waterMark1",
    headerName: "WM1",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("waterMark1"),
  },
  {
    field: "waterMark2",
    headerName: "WM2",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("waterMark2"),
  },
  {
    field: "waterMark3",
    headerName: "WM3",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("waterMark3"),
  },
  {
    field: "waterMark4",
    headerName: "WM4",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("waterMark4"),
  },
  {
    field: "flowState",
    headerName: "Pressostato",
    align: "center",
    headerAlign: "center",
    flex: 2,
    renderCell: renderIfNotEmpty("flowState"),
  },
];

const CHART_GROUPINGS =
  // : {
  //   [key: string]: (keyof SENSOR_LABELS & string)[];
  // }
  {
    Temperatura: ["temperature", "temperatureGround"],
    Umidità: ["humidity"],
    Tensiometri: ["tensiometer1", "tensiometer2", "tensiometer3"],
    Pluviometro: ["pluvioMeter"],
    Pressostato: ["flowState"],
    Watermark: ["waterMark1", "waterMark2", "waterMark3", "waterMark4"],
    Sistema: ["battery", "signal"],
  };

function historyToNodeChart<
  T extends NodeDataRecord[] | NodeStatisticsDaily[] | NodeStatisticsMonthly[]
>(rawData: T, viewMode: "realtime" | "daily" | "monthly" = "realtime") {
  const plottedSensors = Object.keys(
    SENSOR_LABELS
  ) as (keyof typeof SENSOR_LABELS)[];

  if (rawData.length === 0) return {};

  const series = plottedSensors.reduce((res, k) => {
    res[k] = {
      data: [SENSOR_LABELS[k]] as any[],
      min: [`Min`] as any[],
      avg: [`Media`] as any[],
      max: [`Max`] as any[],
    };
    return res;
  }, {} as SeriesType);

  const xAxis: any[] = ["xAxis"];
  const hourlyXAxis: any[] = ["xAxis"]; // for hourly charts
  const pluvioMeterHourly: any = {}; // for each hour have the sum of the values. Used for pluviometer hourly chart
  plottedSensors.forEach((k, ydx) =>
    rawData.forEach((curr, xdx) => {
      if (viewMode === "realtime") {
        const currRecord = curr as NodeDataRecord;
        let hour: any = new Date(currRecord.createdAt!);
        hour.setMinutes(0);
        hour.setSeconds(0);
        hour.setMilliseconds(0);
        hour = hour.toISOString();

        if (ydx === 0) {
          // only fill x labels once
          xAxis.push(new Date(currRecord.createdAt!));

          if (!pluvioMeterHourly[hour]) {
            // console.log(hour);
            pluvioMeterHourly[hour] = 0;
          }
        }

        if (k === "pluvioMeter") {
          pluvioMeterHourly[hour] += currRecord[k] as number;
        }

        if (currRecord[k] !== undefined && currRecord[k] !== null)
          series[k].data.push(currRecord[k] as number);
        else series[k].data.push(null);
      } else if (viewMode === "daily") {
        const currRecord = curr as NodeStatisticsDaily;
        if (ydx === 0) {
          // only fill x labels once
          xAxis.push(
            new Date(
              `${currRecord.year!}-${p2s(currRecord.month!)}-${p2s(
                currRecord.day!
              )}T00:00:00.000Z`
            )
          );
        }
        if (currRecord[k] !== undefined && currRecord[k] !== null) {
          series[k].min.push(currRecord[k]!.min as number);
          series[k].avg.push(currRecord[k]!.avg as number);
          series[k].max.push(currRecord[k]!.max as number);
        } else {
          series[k].min.push(null);
          series[k].avg.push(null);
          series[k].max.push(null);
        }
      } else if (viewMode === "monthly") {
        const currRecord = curr as NodeStatisticsMonthly;
        if (ydx === 0) {
          // only fill x labels once
          xAxis.push(
            new Date(
              `${currRecord.year!}-${p2s(currRecord.month!)}-01T00:00:00.000Z`
            )
          );
        }
        if (currRecord[k] !== undefined && currRecord[k] !== null) {
          series[k].min.push(currRecord[k]!.min as number);
          series[k].avg.push(currRecord[k]!.avg as number);
          series[k].max.push(currRecord[k]!.max as number);
        } else {
          series[k].min.push(null);
          series[k].avg.push(null);
          series[k].max.push(null);
        }
      }
    })
  );

  // fix pluvioMeter series to get the hourly data (sum of the values)
  hourlyXAxis.push(...Object.keys(pluvioMeterHourly).map((k) => new Date(k)));
  series.pluvioMeter.data = [
    SENSOR_LABELS.pluvioMeter,
    ...Object.values(pluvioMeterHourly),
  ];
  // console.log(pluvioMeterHourly);
  // Object.keys(series).forEach((k) => {
  //   if ((series as any)[k].coords.length === 0) delete (series as any)[k];
  // });
  return { series, xAxis, hourlyXAxis };
}

export default function DetailsNode() {
  const [node, setNode] = useState<Node>();
  const nodeHistory = node?.sensorsHistory?.items! as
    | NodeDataRecord[]
    | undefined;
  const [series, setSeries] = useState<any>();
  const [isLoading, setLoading] = useState(true);
  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date()
  );
  const showMessage = useShowMessage();
  const isForUser = useRoleChecker();
  const currentConsortiumId = useSelector(
    (state: RootState) => state.appConfig.currentConsortium?.id
  );

  const { nodeId } = useParams();
  useEffect(() => {
    if (nodeId) {
      const fetchNode = async () => {
        // console.log("Loading node data..", nodeId);
        setLoading(true);

        selectedPickerDate.setHours(0, 0, 0, 0);
        const fromISO = selectedPickerDate.toISOString();
        selectedPickerDate.setHours(23, 59, 59, 999);
        const toISO = selectedPickerDate.toISOString();
        await loadNodeData(fromISO, toISO);
        setLoading(false);
      };
      fetchNode();
      return gqlObserve(
        "detailsnodepage-onChangeNode-refresh",
        onChangeNode as OnChangeNodeSubscription,
        fetchNode,
        { consortiumID: currentConsortiumId, id: nodeId }
      );
    }
    setNode(undefined);
    return () => {};
  }, []);

  const [viewMode, setViewMode] = useState<"realtime" | "daily" | "monthly">(
    "realtime"
  );

  const [selectedSeries, setSelectedSeries] = useState<
    keyof typeof CHART_GROUPINGS | keyof typeof SENSOR_LABELS
  >("Temperatura");

  const handleViewModeChange = useCallback(
    async (e, v) => {
      // setLoading(true);

      if (v === "daily") {
        const nodeDailyStatistics = await gqlOperation(
          listNodeStatisticsDailies as ListNodeStatisticsDailiesQuery,
          {
            id: nodeId,
            sortDirection: "DESC",
          } as ListNodeStatisticsDailiesQueryVariables
        );
        if (nodeDailyStatistics.listNodeStatisticsDailies) {
          setSeries(
            historyToNodeChart(
              nodeDailyStatistics.listNodeStatisticsDailies
                .items as NodeStatisticsDaily[],
              "daily"
            )
          );
        }
      } else if (v === "monthly") {
        const nodeMonthlyStatistics = await gqlOperation(
          listNodeStatisticsMonthlies as ListNodeStatisticsMonthliesQuery,
          {
            id: nodeId,
            sortDirection: "DESC",
          } as ListNodeStatisticsMonthliesQueryVariables
        );
        if (nodeMonthlyStatistics.listNodeStatisticsMonthlies) {
          setSeries(
            historyToNodeChart(
              nodeMonthlyStatistics.listNodeStatisticsMonthlies
                .items as NodeStatisticsMonthly[],
              "monthly"
            )
          );
        }
      } else {
        setSeries(historyToNodeChart(nodeHistory!));
      }
      setViewMode(v);
      setSelectedSeries(
        // @ts-ignore
        v === "realtime"
          ? Object.keys(CHART_GROUPINGS).filter((grouping: string) =>
              CHART_GROUPINGS[grouping as keyof typeof CHART_GROUPINGS].some(
                (sensor) =>
                  (
                    node?.enabledSensors || Object.keys(SENSOR_LABELS)
                  )?.includes(sensor)
              )
            )[0]
          : (node?.enabledSensors || Object.keys(SENSOR_LABELS))[0]!
      );

      setLoading(false);
    },
    [nodeHistory]
  );

  const handleDatePicked = useCallback(
    async (date: Date) => {
      if (!date) return;
      // console.log(date);
      setSelectedPickerDate(date as Date);

      date.setHours(0, 0, 0, 0);

      const fromISO = date.toISOString();
      date.setHours(23, 59, 59, 999);
      const toISO = date.toISOString();

      await loadNodeData(fromISO, toISO);
    },
    [nodeId]
  );

  const navigate = useNavigate();
  // console.log("SelectedSeries", selectedSeries);

  return (
    <Page title="Nodo | ABIoT">
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Breadcrumbs>
          <IconButton
            size="large"
            color="primary"
            onClick={() => navigate("/dashboard/nodes")}
          >
            <Icon icon="eva:arrow-left-fill" fontSize="inherit" />
          </IconButton>
          <Typography variant="h5" color="text.secondary">
            Nodi
          </Typography>
          <Typography variant="h5" color="text.primary">
            Gestione Nodo
            {/* {outlinedNode.idxSensor} - {outlinedNode.title}
                <div style={{ fontSize: 12 }}>id: {outlinedNode.id}</div> */}
          </Typography>
        </Breadcrumbs>
        <BlurLoader isLoading={isLoading}>
          {node && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <SingleNodeView node={node!} hideView />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Tabs
                    style={{ borderBottom: "1px solid #ccc" }}
                    variant="scrollable"
                    value={viewMode}
                    onChange={handleViewModeChange}
                    allowScrollButtonsMobile
                  >
                    <Tab
                      value="realtime"
                      style={{ borderRight: "1px solid #ccc" }}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      label={
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          Letture per Data{" "}
                          {viewMode === "realtime" && (
                            <DatePicker
                              value={selectedPickerDate}
                              disabled={viewMode !== "realtime"}
                              onChange={handleDatePicked as any}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  label="-"
                                  onClick={(e) => e.preventDefault()}
                                  // label=""
                                />
                              )}
                              label=""
                            />
                          )}
                        </LocalizationProvider>
                      }
                    />
                    <Tab value="daily" label="Statistiche giornaliere" />
                    <Tab value="monthly" label="Statistiche mensili" />
                  </Tabs>
                  {/* </Card>
              </Grid>
              <Grid item xs={12}>
                <Card> */}
                  <Tabs
                    variant="scrollable"
                    value={selectedSeries}
                    onChange={(e, v) => setSelectedSeries(v)}
                    allowScrollButtonsMobile
                  >
                    {viewMode === "realtime"
                      ? Object.keys(CHART_GROUPINGS).map(
                          (grouping: string) =>
                            CHART_GROUPINGS[
                              grouping as keyof typeof CHART_GROUPINGS
                            ].some((sensor) =>
                              (
                                node.enabledSensors ||
                                Object.keys(SENSOR_LABELS)
                              )?.includes(sensor)
                            ) && (
                              <Tab
                                key={grouping}
                                value={grouping}
                                label={grouping}
                              />
                            )
                        )
                      : Object.keys(SENSOR_LABELS).map(
                          (sensor: any) =>
                            (
                              node.enabledSensors || Object.keys(SENSOR_LABELS)
                            ).includes(sensor) && (
                              <Tab
                                key={sensor}
                                value={sensor}
                                label={(SENSOR_LABELS as any)[sensor]}
                              />
                            )
                        )}
                  </Tabs>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {/* <Card>
                  <CardHeader
                    title="Batteria"
                    style={{ marginBottom: 20 }}
                    // subheader="Andamento valori batteria"
                  /> */}
                <AbiotChart
                  data={{
                    series: series.series,
                    xAxis:
                      selectedSeries === SENSOR_LABELS.pluvioMeter
                        ? series.hourlyXAxis
                        : series.xAxis,
                  }}
                  selections={
                    viewMode === "realtime"
                      ? CHART_GROUPINGS[
                          selectedSeries as keyof typeof CHART_GROUPINGS
                        ].filter(
                          (s) =>
                            !node.enabledSensors ||
                            node.enabledSensors?.includes(s)
                        )
                      : ([selectedSeries] as any)
                  }
                  chartType={
                    [SENSOR_LABELS.pluvioMeter].includes(selectedSeries)
                      ? "bar"
                      : undefined
                  }
                  viewMode={viewMode}
                />
                {/* </Card> */}
              </Grid>
              {viewMode === "realtime" && (
                <>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Registro Letture" />
                      <CardContent>
                        <DataGrid
                          rows={nodeHistory!}
                          columns={columns.filter((c) => {
                            if (["createdAt", "id"].includes(c.field))
                              return true;
                            return (
                              node.enabledSensors || Object.keys(SENSOR_LABELS)
                            )?.includes(c.field as any);
                          })}
                          pageSize={8}
                          disableColumnFilter
                          disableColumnMenu
                          autoHeight
                          disableColumnSelector
                          disableSelectionOnClick
                          disableDensitySelector
                          showCellRightBorder
                          localeText={{
                            noRowsLabel: "Nessun dato disponibile",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Registro Notifiche di Sistema" />
                      <CardContent>
                        <DataGrid
                          rows={
                            (node!.notifications?.items as Notification[]) || []
                          }
                          columns={notificationsColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          disableColumnFilter
                          disableColumnMenu
                          autoHeight
                          disableColumnSelector
                          disableSelectionOnClick
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title="Logging"
                        action={
                          <>
                            Registro eventi{" "}
                            {node!.isLogEnabled ? "attivo" : "disattivo"}
                            <Switch
                              disabled={isLoading}
                              checked={node!.isLogEnabled}
                              onChange={async (ev) => {
                                await gqlOperation(
                                  updateNode as UpdateNodeMutation,
                                  {
                                    input: {
                                      id: node!.id,
                                      isLogEnabled: ev.target.checked,
                                    },
                                  } as UpdateNodeMutationVariables
                                );
                                showMessage(
                                  "Stato logging cambiato con successo."
                                );
                              }}
                            />
                          </>
                        }
                      />
                      <CardContent>
                        <DataGrid
                          rows={(node!.lastLogs?.items as NodeLog[]) || []}
                          columns={logsColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          disableColumnFilter
                          disableColumnMenu
                          autoHeight
                          disableColumnSelector
                          disableSelectionOnClick
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </BlurLoader>
      </Container>
    </Page>
  );

  async function loadNodeData(fromISO: string, toISO: string) {
    const getNodeResult = await gqlOperation(
      (isForUser("manager")
        ? getNodeSortedManager
        : getNodeSortedBase) as GetNodeSortedBaseQuery,
      {
        id: nodeId,
        sortDirection: "DESC",
        from: fromISO,
        to: toISO,
      } as GetNodeSortedBaseQueryVariables
    );
    setNode(getNodeResult.getNode!);
    // console.log(getNodeResult.getNode!);
    setSeries(
      historyToNodeChart(
        getNodeResult.getNode!.sensorsHistory?.items.reverse()! as NodeDataRecord[]
      )
    );
    setSelectedSeries(
      // @ts-ignore
      Object.keys(CHART_GROUPINGS).filter((grouping: string) =>
        CHART_GROUPINGS[grouping as keyof typeof CHART_GROUPINGS].some(
          (sensor) =>
            (
              getNodeResult.getNode!?.enabledSensors ||
              Object.keys(SENSOR_LABELS)
            )?.includes(sensor)
        )
      )[0]
    );
  }
}
