/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Alarm,
  AlarmOperator,
  CreateAlarmMutationVariables,
  UpdateAlarmMutationVariables,
} from "../../../API";
import { createAlarm, updateAlarm } from "../../../graphql/mutations";
import { RootState } from "../../../store";
import { setCurrentConsortium } from "../../../store/appConfigSlice";
import { getConsortium, gqlOperation } from "../../../store/queries";
import { SENSOR_LABELS } from "../../../utils/constants";
import useShowMessage from "../../../utils/hooks";

interface Props {
  outlinedObject?: Partial<Alarm>;
  onClose: any;
}

export default function NewUserDrawer({ outlinedObject, onClose }: Props) {
  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  )!;
  const consortiumId = currentConsortium.id;
  const showMessage = useShowMessage();
  const AlarmSchema = Yup.object().shape({
    name: Yup.string().required("Titolo è richiesto"),
    property: Yup.string().required("Proprietà è richiesta"),
    operator: Yup.string().required("Operatore è richiesto"),
    value: Yup.number().required("Valore è richiesto"),
  });
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      property: "temperature",
      operator: "",
      value: 0,
      ...outlinedObject,
    },
    validationSchema: AlarmSchema,
    onSubmit: async (values, fk) => {
      try {
        // if values.id is provided, it means that we are editing an existing node, use updateNode mutation
        if (values.id) {
          await gqlOperation(updateAlarm, {
            input: {
              id: values.id,
              name: values.name,
              property: values.property,
              operator: values.operator,
              value: values.value,
            },
          } as UpdateAlarmMutationVariables);
        } else {
          await gqlOperation(createAlarm, {
            input: {
              name: values.name,
              property: values.property,
              operator: values.operator,
              value: values.value,
              consortiumID: consortiumId,
              currentlyFiringNodes: [],
            },
          } as CreateAlarmMutationVariables);
        }

        showMessage("Allarme memorizzata con successo", "success");
        const newConsr = await getConsortium(consortiumId);
        dispatch(setCurrentConsortium(newConsr));

        fk.resetForm();
      } finally {
        fk.setSubmitting(false);
        onClose();
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <>
      <CardHeader
        title="Nuova allarme"
        subheader="Registra una nuova allarme nel sistema ABIoT. Riceverai una notifica in base ai criteri definiti nell'allarme."
        action={
          <IconButton onClick={onClose} color="primary" size="medium">
            <Icon icon="bi:x-lg" />
          </IconButton>
        }
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Titolo"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              {/* Property must be selected among known properties of type Node */}
              <Autocomplete
                fullWidth
                options={Object.keys(SENSOR_LABELS)}
                getOptionLabel={(option) =>
                  SENSOR_LABELS[option as keyof typeof SENSOR_LABELS]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proprietà"
                    error={Boolean(touched.property && errors.property)}
                    helperText={touched.property && errors.property}
                  />
                )}
                {...getFieldProps("property")}
                onChange={(_, value) => {
                  formik.setFieldValue("property", value);
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Operatore</InputLabel>
                <Select
                  fullWidth
                  label="Operatore"
                  {...getFieldProps("operator")}
                  error={Boolean(touched.operator && errors.operator)}
                >
                  <MenuItem value={AlarmOperator.GT}>Maggiore di</MenuItem>
                  <MenuItem value={AlarmOperator.LT}>Minore di</MenuItem>
                  <MenuItem value={AlarmOperator.EQ}>Uguale a</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="number"
                label="Valore"
                {...getFieldProps("value")}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salva
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </>
  );
}
