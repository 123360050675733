import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { Avatar, Box, Drawer, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import { useSelector } from "react-redux";
import { MHidden } from "../../components/@material-extend";
import NavSection from "../../components/NavSection";
import Scrollbar from "../../components/Scrollbar";
//
import { RootState } from "../../store";
import account from "../../_mocks_/account";
import sidebarConfig from "./SidebarConfig";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: any;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();

  const userInfo = useSelector(
    (state: RootState) => state.appConfig.currentUserInfo
  );

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* <Box
        sx={{
          px: 2.5,
          py: 3,
          textAlign: "center",
          imageRendering: "pixelated",
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box> */}

      <Box sx={{ mb: 5, mx: 2.5, mt: 1 }}>
        <Link underline="none" component={RouterLink} to="/admin">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {userInfo.name} {userInfo.family_name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {userInfo["cognito:groups"]}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
