/* eslint-disable react/jsx-props-no-spreading */
import * as c3 from "c3";
import * as datefns from "date-fns";
import { useEffect, useRef } from "react";
import type { SeriesType } from "../pages/logged/nodes/DetailsNode";
import "./AbiotChart.scss";

interface Props {
  data: { series: SeriesType; xAxis: any[] };
  selections: (keyof SeriesType)[];
  viewMode?: string;
  chartType?: c3.ChartType;
}

// export default function AbiotChart({ series, selections, viewMode }: Props) {
//   const [visibleChart, setVisibleChart] = useState<keyof SeriesType>(
//     selections[0]
//   );
//   const [isLoading, setLoading] = useState(false);

//   const chartProps = {
//     vizType: VizType.LineChart as any,
//     data: series[visibleChart] ? [series[visibleChart]] : [],
//     axisLabels: { left: "Valore", bottom: "Ora" },
//     formatAxis: {
//       x: ((n: number, a: any) => {
//         if (!series[visibleChart].coords[n - 1]) return "";
//         try {
//           return datefns.format(
//             new Date(series[visibleChart].coords[n - 1]?.datetime),
//             viewMode === "realtime" ? "HH:mm" : "dd MMM"
//           );
//         } catch (e) {
//           console.error(e, series[visibleChart].coords[n - 1]);

//           return "";
//         }
//       }) as any,
//     },
//     showGridLines: {
//       xAxis: true,
//       yAxis: true,
//     },
//     formatTooltipX: (coord: any) =>
//       datefns.format(
//         new Date(coord.datetime),
//         viewMode === "realtime" ? "HH:mm:ss" : "dd MMM yyyy"
//       ),
//     formatTooltipY: (coord: any) =>
//       viewMode === "realtime"
//         ? `<b>${coord.y}</b>`
//         : `min: <b>${coord.min}</b>, max: <b>${coord.max}</b>, media: <b>${coord.avg}</b>, letture: <b>${coord.count}</b>`,
//   };

//   const emptyChartProps = {
//     vizType: VizType.Error as any,
//     message: "Nessun dato disponibile",
//   };

//   return (
//     <>
//       <FormControl>
//         <RadioGroup
//           row
//           defaultValue={selections[0]}
//           onChange={(ev, value) => {
//             setLoading(true);
//             setVisibleChart(value as keyof SeriesType);
//             setLoading(false);
//           }}
//         >
//           {selections.map((selection) => (
//             <FormControlLabel
//               key={selection}
//               value={selection}
//               control={<Radio />}
//               label={SENSOR_LABELS[selection]}
//             />
//           ))}
//         </RadioGroup>
//       </FormControl>
//       {isLoading ? (
//         <Grid item xs={12} style={{ textAlign: "center" }}>
//           <BounceLoader color="#00AB55" speedMultiplier={1.5} />
//         </Grid>
//       ) : (
//         <DataViz
//           id={`tensiocharts${visibleChart}`}
//           {...(series[visibleChart] ? chartProps : emptyChartProps)}
//         />
//       )}
//     </>
//   );
// }

export default function AbiotChart({
  data,
  selections,
  viewMode,
  chartType,
}: Props) {
  const chartRef = useRef(null);
  useEffect(() => {
    // use c3 to load sample chart
    // https://c3js.org/gettingstarted.html
    const { xAxis, series } = data;
    if (!xAxis || xAxis.length === 0) return;
    // console.log("abiotchart", data, selections, viewMode, chartType);

    let finalChartType: c3.ChartType = "spline";
    if (viewMode === "realtime") finalChartType = "area-spline";
    if (chartType) finalChartType = chartType;

    // console.log(xAxis);
    const chart = c3.generate({
      bindto: chartRef.current,
      size: {
        height: 450,
      },
      color: {
        pattern: [
          "#00AB55",
          "#FF8B00",
          "#6554C0",
          "#36B37E",
          "#00B8D9",
          "#253858",
          "#FF5630",
          "#FFAB00",
          "#FFC400",
          "#00875A",
          "#172B4D",
          "#6554C0",
          "#FF5630",
          "#00B8D9",
          "#36B37E",
          "#FFAB00",
          "#FF8B00",
          "#253858",
          "#00875A",
          "#172B4D",
        ],
      },
      data: {
        type: finalChartType,
        x: "xAxis",
        colors: {
          Pluviometro: "rgb(115 209 195)",
        },
        // xFormat: "%Y-%m-%d",
        // labels: true,

        columns:
          viewMode === "realtime"
            ? [xAxis, ...selections.map((selection) => series[selection].data)]
            : [
                xAxis,
                ...selections.map((selection) => series[selection].min),
                ...selections.map((selection) => series[selection].avg),
                ...selections.map((selection) => series[selection].max),
              ],
        // [
        //   // ["data1", 30, 200, 100, 400, 150, 250],
        //   // ["data2", 50, 20, 10, 40, 15, 25],
        // ],
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            fit: false,
            centered: true,
            rotate: 90,
            // outer: true,
            // count: 50,
            multiline: false,
            format: (x: any) =>
              datefns.format(
                x, // +1 because of the xAxis column
                viewMode === "realtime" ? "eee HH:mm" : "dd MMM yyyy"
              ) as any,
          },
        },
      },
      tooltip: {
        show: true,
        format: {
          title: (x: any) =>
            datefns.format(
              x,
              viewMode === "realtime" ? "dd MMM yyyy HH:mm" : "dd MMM yyyy"
            ),
        },
      },
      zoom: {
        enabled: true,
        rescale: true,
        // extent: [1, 2],
        // initialRange:
        //   viewMode === "realtime" &&
        //   ([
        //     xAxis.length > 20 ? xAxis[xAxis.length - 15] : xAxis[1],
        //     xAxis[xAxis.length - 1],
        //   ] as any),
      },
      spline: {
        interpolation: {
          type: "monotone",
        },
      },
      grid: {
        x: {
          show: true,
          type: "timeseries",
        },
        y: {
          show: true,
        },
      },
      subchart: {
        show: true,
        axis: {
          x: {
            show: false,
          },
        },
      },
    });
  }, [data, selections, viewMode]);
  return data.xAxis && data.xAxis.length > 0 ? (
    <div ref={chartRef} style={{ marginLeft: -10 }} />
  ) : null;
}
