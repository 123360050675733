import bugFilled from "@iconify/icons-ant-design/bug-filled";
import { Icon } from "@iconify/react";
// material
import { Card, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }: any) => ({
  boxShadow: "none",
  textAlign: "center",
  cursor: "pointer",
  padding: theme.spacing(2, 0),
  minWidth: 125,
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(1),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.error.dark,
    0
  )} 0%, ${alpha(theme.palette.error.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 20;

type Props = {
  quantity: number;
};

export default function AppBugReports({ quantity }: Props) {
  const navigate = useNavigate();
  return (
    <RootStyle onClick={() => navigate("/dashboard/nodes")}>
      <IconWrapperStyle>
        <Icon icon={bugFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h4">{fShortenNumber(quantity)}</Typography>
      <Typography variant="body2" sx={{ opacity: 0.72 }}>
        Nodi in Errore
      </Typography>
    </RootStyle>
  );
}
