/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Marker, Polyline } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { BounceLoader } from "react-spinners";
import {
  CreateNodeMutationVariables,
  Node,
  NodeStatus,
  NodeType,
  UpdateNodeMutationVariables,
} from "../../../API";
import { createNode, updateNode } from "../../../graphql/mutations";
import type { RootState } from "../../../store";
import { setCurrentConsortium } from "../../../store/appConfigSlice";
import queries, { getConsortium, gqlOperation } from "../../../store/queries";
import { MAP_GATEWAY_ICON, nodeToMapIcon } from "../../../utils";
import useShowMessage from "../../../utils/hooks";
import MapLocationSelector from "./MapLocationSelector";
import { SENSOR_LABELS } from "../../../utils/constants";

interface Props {
  outlinedObject?: Partial<Node>;
  onClose: any;
}

const FIXED_SENSORS = ["battery", "signal"];

export default function NewNodeDrawer({ outlinedObject, onClose }: Props) {
  const currentConsortium = useSelector(
    (state: RootState) => state.appConfig.currentConsortium
  )!;
  const consortiumId = currentConsortium.id;
  const showMessage = useShowMessage();
  const NodeSchema = Yup.object().shape({
    idxSensor: Yup.number().required(
      "Inserire l'index del nodo, come specificato nel gateway"
    ),
    title: Yup.string().required("Inserire titolo"),
    lat: Yup.number().required("Inserire la latitudine del nodo"),
    lng: Yup.number().required("Inserire la longitudine del nodo"),
    isSensor: Yup.boolean(),
    isLogEnabled: Yup.boolean(),
  });
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      idxSensor: 1,
      title: "Nuovo Nodo",
      lat: 46.4263191,
      lng: 10.8710145,
      isSensor: outlinedObject?.nodeType === NodeType.SENSOR,
      isLogEnabled: false,
      enabledSensors: Object.keys(SENSOR_LABELS),
      ...outlinedObject,
    },
    validationSchema: NodeSchema,
    onSubmit: async (values, fk) => {
      try {
        if (typeof values.idxSensor !== "number") {
          showMessage("Indice Nodo invalido", "error");
          return;
        }
        const exists = await queries.nodeIdExists(values.idxSensor, values.id);
        if (exists) {
          showMessage(
            `Nodo con indice ${values.idxSensor} esiste già nel consorzio.`,
            "error"
          );
          return;
        }
        console.log(values);
        // if values.id is provided, it means that we are editing an existing node, use updateNode mutation
        if (values.id) {
          await gqlOperation(updateNode, {
            input: {
              id: values.id,
              idxSensor: values.idxSensor,
              title: values.title,
              lat: values.lat,
              lng: values.lng,
              enabledSensors: values.enabledSensors,
              nodeType: values.isSensor ? NodeType.SENSOR : NodeType.VALVE,
              isLogEnabled: values.isLogEnabled,
            },
          } as UpdateNodeMutationVariables);
        } else {
          await gqlOperation(createNode, {
            input: {
              idxSensor: values.idxSensor,
              title: values.title,
              lat: values.lat,
              lng: values.lng,
              enabledSensors: values.enabledSensors,
              battery: 0,
              signal: 0,
              nodeType: values.isSensor ? NodeType.SENSOR : NodeType.VALVE,
              isDataOngoing: false,
              isLogEnabled: values.isLogEnabled,
              isOpeningOngoing: false,
              isClosingOngoing: false,
              status: NodeStatus.OK,
              consortiumID: consortiumId,
            },
          } as CreateNodeMutationVariables);
        }

        showMessage("Nodo memorizzato con successo", "success");
        const newConsr = await getConsortium(consortiumId);
        dispatch(setCurrentConsortium(newConsr));

        fk.resetForm();
      } finally {
        fk.setSubmitting(false);
        onClose();
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [isGeoWorking, setIsGeoWorking] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!outlinedObject) {
      queries.getNextNodeId().then((nextNodeIdx) => {
        setFieldValue("idxSensor", nextNodeIdx);

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <CardHeader
        title="Nuovo Nodo"
        subheader="Registra un nuovo nodo nel sistema ABIoT"
        action={
          <IconButton onClick={onClose} color="primary" size="medium">
            <Icon icon="bi:x-lg" />
          </IconButton>
        }
      />
      <CardContent>
        {loading ? (
          <BounceLoader
            color="#00AB55"
            css={`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
            `}
            speedMultiplier={1.5}
          />
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="ID"
                  type="number"
                  {...getFieldProps("idxSensor")}
                  error={Boolean(touched.idxSensor && errors.idxSensor)}
                  helperText={touched.idxSensor && errors.idxSensor}
                />
                <TextField
                  fullWidth
                  label="Titolo"
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
                <Stack direction="row" spacing={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Latitudine"
                    {...getFieldProps("lat")}
                    error={Boolean(touched.lat && errors.lat)}
                    helperText={touched.lat && errors.lat}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    label="Longitudine"
                    {...getFieldProps("lng")}
                    error={Boolean(touched.lng && errors.lng)}
                    helperText={touched.lng && errors.lng}
                  />
                </Stack>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="outlined"
                  onClick={() => {
                    setIsGeoWorking(true);

                    navigator.geolocation.getCurrentPosition(
                      (position: GeolocationPosition) => {
                        formik.setFieldValue("lat", position.coords.latitude);
                        formik.setFieldValue("lng", position.coords.longitude);
                        setIsGeoWorking(false);
                        showMessage("Posizione caricata correttamente.");
                      },
                      (error: GeolocationPositionError) => {
                        showMessage(
                          `Impossibile caricare posizione GPS: ${error.message}`,
                          "error"
                        );
                        setIsGeoWorking(false);
                      },
                      { enableHighAccuracy: true, maximumAge: 0 }
                    );
                  }}
                  loading={isGeoWorking}
                  startIcon={<Icon icon="bx:bx-current-location" />}
                >
                  Carica posizione
                </LoadingButton>

                <MapLocationSelector
                  position={{ lat: values.lat, lng: values.lng }}
                  onMove={(map) => {
                    formik.setFieldValue("lat", map.getCenter().lat);
                    formik.setFieldValue("lng", map.getCenter().lng);
                  }}
                >
                  <Marker
                    position={currentConsortium}
                    icon={MAP_GATEWAY_ICON}
                  />

                  {currentConsortium.nodes?.items?.map((node) => (
                    <React.Fragment key={node!.id}>
                      <Polyline
                        color="#00ab55"
                        positions={[
                          currentConsortium,
                          node!.idxSensor !== values.idxSensor
                            ? [node!.lat, node!.lng]
                            : values,
                        ]}
                      />
                      <Marker
                        icon={nodeToMapIcon(node!, currentConsortium)}
                        position={
                          node!.idxSensor !== values.idxSensor
                            ? [node!.lat, node!.lng]
                            : values
                        }
                      />
                    </React.Fragment>
                  ))}
                </MapLocationSelector>

                <Stack direction="row" alignItems="center" spacing={3}>
                  <h4>Tipo di nodo</h4>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps("isSensor")}
                        checked={values.isSensor}
                        size="medium"
                      />
                    }
                    label={
                      values.isSensor ? (
                        <>
                          Sensore{" "}
                          <Icon
                            icon="material-symbols:thermostat"
                            style={{ fontSize: "22px", color: "#33994f" }}
                          />
                        </>
                      ) : (
                        <>
                          Valvola{" "}
                          <Icon
                            icon="mdi:switch"
                            style={{ fontSize: "22px", color: "#33994f" }}
                          />
                        </>
                      )
                    }
                  />
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" spacing={3}>
                  <h4>Registro log attivo</h4>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps("isLogEnabled")}
                        checked={values.isLogEnabled}
                        size="medium"
                      />
                    }
                    label={values.isLogEnabled ? "Attivo" : "Disattivo"}
                  />
                </Stack>
                <Divider />
                <h4>Sensori Installati</h4>
                <Autocomplete
                  multiple
                  options={Object.keys(SENSOR_LABELS)}
                  disableCloseOnSelect
                  // @ts-ignore
                  getOptionLabel={(option) => SENSOR_LABELS[option]}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        // @ts-ignore
                        label={SENSOR_LABELS[option]}
                        {...getTagProps({ index })}
                        disabled={FIXED_SENSORS.includes(option!)}
                      />
                    ))
                  }
                  // style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} />}
                  value={values.enabledSensors || Object.keys(SENSOR_LABELS)}
                  onChange={(_, newValue) => {
                    // check if sensor
                    setFieldValue("enabledSensors", newValue);
                  }}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Salva
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        )}
      </CardContent>
    </>
  );
}
