/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Program = {
  __typename: "Program",
  id: string,
  title: string,
  isRunning?: boolean | null,
  nodesOpenings?: ModelNodeOpeningsConnection | null,
  consortiumID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelNodeOpeningsConnection = {
  __typename: "ModelNodeOpeningsConnection",
  items:  Array<NodeOpenings | null >,
  nextToken?: string | null,
};

export type NodeOpenings = {
  __typename: "NodeOpenings",
  id: string,
  node?: Node | null,
  nodeProgram: Array< string >,
  programID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  nodeOpeningsNodeId?: string | null,
};

export type Node = {
  __typename: "Node",
  id: string,
  idxSensor: number,
  title: string,
  lat: number,
  lng: number,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  humidity?: number | null,
  signal?: number | null,
  flowState?: number | null,
  enabledSensors?: Array< string | null > | null,
  status: NodeStatus,
  valveStatus?: ValveStatus | null,
  lastValveCommand?: ValveStatus | null,
  nodeType?: NodeType | null,
  isLogEnabled: boolean,
  isOpeningOngoing: boolean,
  openingStartTime?: string | null,
  isClosingOngoing: boolean,
  closingStartTime?: string | null,
  isDataOngoing: boolean,
  dataStartTime?: string | null,
  consortiumID: string,
  sensorsHistory?: ModelNodeDataRecordConnection | null,
  lastLogs?: ModelNodeLogConnection | null,
  notifications?: ModelNotificationConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum NodeStatus {
  OK = "OK",
  ERROR = "ERROR",
}


export enum ValveStatus {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}


export enum NodeType {
  SENSOR = "SENSOR",
  VALVE = "VALVE",
}


export type ModelNodeDataRecordConnection = {
  __typename: "ModelNodeDataRecordConnection",
  items:  Array<NodeDataRecord | null >,
  nextToken?: string | null,
};

export type NodeDataRecord = {
  __typename: "NodeDataRecord",
  id: string,
  nodeID?: string | null,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  flowState?: number | null,
  humidity?: number | null,
  signal?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ttl?: number | null,
};

export type ModelNodeLogConnection = {
  __typename: "ModelNodeLogConnection",
  items:  Array<NodeLog | null >,
  nextToken?: string | null,
};

export type NodeLog = {
  __typename: "NodeLog",
  id: string,
  nodeID?: string | null,
  rawMessage?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  priority?: NotificationPriority | null,
  message?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumID: string,
  nodeID: string,
  status: NotificationStatus,
  ttl?: number | null,
};

export enum NotificationPriority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}


export enum NotificationStatus {
  UNREAD = "UNREAD",
  READ = "READ",
}


export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type Consortium = {
  __typename: "Consortium",
  id: string,
  name: string,
  lat: number,
  lng: number,
  contact: string,
  nodes?: ModelNodeConnection | null,
  programs?: ModelProgramConnection | null,
  notifications?: ModelNotificationConnection | null,
  alarms?: ModelAlarmConnection | null,
  endUsers?: ModelEndUserConnection | null,
  defaultProgram?: Program | null,
  gatewayLatestComm?: string | null,
  gatewayStatus?: string | null,
  gatewayBattery?: number | null,
  gatewaySignal?: number | null,
  gatewayConnectionType?: string | null,
  gatewayLocalTime?: string | null,
  gatewayLocalFirmware?: string | null,
  gatewayAutotest?: string | null,
  gatewaySolarTension?: number | null,
  gatewaySolarCurrent?: number | null,
  gatewayBatteryTension?: number | null,
  gatewayBatteryCurrent?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumDefaultProgramId?: string | null,
};

export type ModelNodeConnection = {
  __typename: "ModelNodeConnection",
  items:  Array<Node | null >,
  nextToken?: string | null,
};

export type ModelProgramConnection = {
  __typename: "ModelProgramConnection",
  items:  Array<Program | null >,
  nextToken?: string | null,
};

export type ModelAlarmConnection = {
  __typename: "ModelAlarmConnection",
  items:  Array<Alarm | null >,
  nextToken?: string | null,
};

export type Alarm = {
  __typename: "Alarm",
  id: string,
  consortiumID: string,
  name?: string | null,
  property?: string | null,
  operator?: AlarmOperator | null,
  value?: number | null,
  currentlyFiringNodes?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum AlarmOperator {
  LT = "LT",
  GT = "GT",
  EQ = "EQ",
}


export type ModelEndUserConnection = {
  __typename: "ModelEndUserConnection",
  items:  Array<EndUser | null >,
  nextToken?: string | null,
};

export type EndUser = {
  __typename: "EndUser",
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  userType: UserType,
  consortiumID: string,
  createdAt?: string | null,
};

export enum UserType {
  BASE = "BASE",
  TECHNICIAN = "TECHNICIAN",
}


export type CreateConsortiumInput = {
  id?: string | null,
  name: string,
  lat: number,
  lng: number,
  contact: string,
  gatewayLatestComm?: string | null,
  gatewayStatus?: string | null,
  gatewayBattery?: number | null,
  gatewaySignal?: number | null,
  gatewayConnectionType?: string | null,
  gatewayLocalTime?: string | null,
  gatewayLocalFirmware?: string | null,
  gatewayAutotest?: string | null,
  gatewaySolarTension?: number | null,
  gatewaySolarCurrent?: number | null,
  gatewayBatteryTension?: number | null,
  gatewayBatteryCurrent?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumDefaultProgramId?: string | null,
};

export type ModelConsortiumConditionInput = {
  name?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  contact?: ModelStringInput | null,
  gatewayLatestComm?: ModelStringInput | null,
  gatewayStatus?: ModelStringInput | null,
  gatewayBattery?: ModelFloatInput | null,
  gatewaySignal?: ModelFloatInput | null,
  gatewayConnectionType?: ModelStringInput | null,
  gatewayLocalTime?: ModelStringInput | null,
  gatewayLocalFirmware?: ModelStringInput | null,
  gatewayAutotest?: ModelStringInput | null,
  gatewaySolarTension?: ModelFloatInput | null,
  gatewaySolarCurrent?: ModelFloatInput | null,
  gatewayBatteryTension?: ModelFloatInput | null,
  gatewayBatteryCurrent?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConsortiumConditionInput | null > | null,
  or?: Array< ModelConsortiumConditionInput | null > | null,
  not?: ModelConsortiumConditionInput | null,
  consortiumDefaultProgramId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateConsortiumInput = {
  id: string,
  name?: string | null,
  lat?: number | null,
  lng?: number | null,
  contact?: string | null,
  gatewayLatestComm?: string | null,
  gatewayStatus?: string | null,
  gatewayBattery?: number | null,
  gatewaySignal?: number | null,
  gatewayConnectionType?: string | null,
  gatewayLocalTime?: string | null,
  gatewayLocalFirmware?: string | null,
  gatewayAutotest?: string | null,
  gatewaySolarTension?: number | null,
  gatewaySolarCurrent?: number | null,
  gatewayBatteryTension?: number | null,
  gatewayBatteryCurrent?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumDefaultProgramId?: string | null,
};

export type DeleteConsortiumInput = {
  id: string,
};

export type CreateNodeInput = {
  id?: string | null,
  idxSensor: number,
  title: string,
  lat: number,
  lng: number,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  humidity?: number | null,
  signal?: number | null,
  flowState?: number | null,
  enabledSensors?: Array< string | null > | null,
  status: NodeStatus,
  valveStatus?: ValveStatus | null,
  lastValveCommand?: ValveStatus | null,
  nodeType?: NodeType | null,
  isLogEnabled: boolean,
  isOpeningOngoing: boolean,
  openingStartTime?: string | null,
  isClosingOngoing: boolean,
  closingStartTime?: string | null,
  isDataOngoing: boolean,
  dataStartTime?: string | null,
  consortiumID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelNodeConditionInput = {
  idxSensor?: ModelIntInput | null,
  title?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  temperatureGround?: ModelFloatInput | null,
  pluvioMeter?: ModelFloatInput | null,
  tensiometer1?: ModelFloatInput | null,
  tensiometer2?: ModelFloatInput | null,
  tensiometer3?: ModelFloatInput | null,
  waterMark1?: ModelFloatInput | null,
  waterMark2?: ModelFloatInput | null,
  waterMark3?: ModelFloatInput | null,
  waterMark4?: ModelFloatInput | null,
  humidity?: ModelFloatInput | null,
  signal?: ModelFloatInput | null,
  flowState?: ModelIntInput | null,
  enabledSensors?: ModelStringInput | null,
  status?: ModelNodeStatusInput | null,
  valveStatus?: ModelValveStatusInput | null,
  lastValveCommand?: ModelValveStatusInput | null,
  nodeType?: ModelNodeTypeInput | null,
  isLogEnabled?: ModelBooleanInput | null,
  isOpeningOngoing?: ModelBooleanInput | null,
  openingStartTime?: ModelStringInput | null,
  isClosingOngoing?: ModelBooleanInput | null,
  closingStartTime?: ModelStringInput | null,
  isDataOngoing?: ModelBooleanInput | null,
  dataStartTime?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeConditionInput | null > | null,
  or?: Array< ModelNodeConditionInput | null > | null,
  not?: ModelNodeConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelNodeStatusInput = {
  eq?: NodeStatus | null,
  ne?: NodeStatus | null,
};

export type ModelValveStatusInput = {
  eq?: ValveStatus | null,
  ne?: ValveStatus | null,
};

export type ModelNodeTypeInput = {
  eq?: NodeType | null,
  ne?: NodeType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateNodeInput = {
  id: string,
  idxSensor?: number | null,
  title?: string | null,
  lat?: number | null,
  lng?: number | null,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  humidity?: number | null,
  signal?: number | null,
  flowState?: number | null,
  enabledSensors?: Array< string | null > | null,
  status?: NodeStatus | null,
  valveStatus?: ValveStatus | null,
  lastValveCommand?: ValveStatus | null,
  nodeType?: NodeType | null,
  isLogEnabled?: boolean | null,
  isOpeningOngoing?: boolean | null,
  openingStartTime?: string | null,
  isClosingOngoing?: boolean | null,
  closingStartTime?: string | null,
  isDataOngoing?: boolean | null,
  dataStartTime?: string | null,
  consortiumID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteNodeInput = {
  id: string,
};

export type CreateNodeDataRecordInput = {
  id?: string | null,
  nodeID?: string | null,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  flowState?: number | null,
  humidity?: number | null,
  signal?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ttl?: number | null,
};

export type ModelNodeDataRecordConditionInput = {
  nodeID?: ModelIDInput | null,
  battery?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  temperatureGround?: ModelFloatInput | null,
  pluvioMeter?: ModelFloatInput | null,
  tensiometer1?: ModelFloatInput | null,
  tensiometer2?: ModelFloatInput | null,
  tensiometer3?: ModelFloatInput | null,
  waterMark1?: ModelFloatInput | null,
  waterMark2?: ModelFloatInput | null,
  waterMark3?: ModelFloatInput | null,
  waterMark4?: ModelFloatInput | null,
  flowState?: ModelIntInput | null,
  humidity?: ModelFloatInput | null,
  signal?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelNodeDataRecordConditionInput | null > | null,
  or?: Array< ModelNodeDataRecordConditionInput | null > | null,
  not?: ModelNodeDataRecordConditionInput | null,
};

export type UpdateNodeDataRecordInput = {
  id: string,
  nodeID?: string | null,
  battery?: number | null,
  temperature?: number | null,
  temperatureGround?: number | null,
  pluvioMeter?: number | null,
  tensiometer1?: number | null,
  tensiometer2?: number | null,
  tensiometer3?: number | null,
  waterMark1?: number | null,
  waterMark2?: number | null,
  waterMark3?: number | null,
  waterMark4?: number | null,
  flowState?: number | null,
  humidity?: number | null,
  signal?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ttl?: number | null,
};

export type DeleteNodeDataRecordInput = {
  id: string,
};

export type CreateNodeStatisticsDailyInput = {
  id?: string | null,
  year: string,
  month: string,
  day: string,
  battery?: NodeStatisticsValueInput | null,
  temperature?: NodeStatisticsValueInput | null,
  temperatureGround?: NodeStatisticsValueInput | null,
  pluvioMeter?: NodeStatisticsValueInput | null,
  tensiometer1?: NodeStatisticsValueInput | null,
  tensiometer2?: NodeStatisticsValueInput | null,
  tensiometer3?: NodeStatisticsValueInput | null,
  waterMark1?: NodeStatisticsValueInput | null,
  waterMark2?: NodeStatisticsValueInput | null,
  waterMark3?: NodeStatisticsValueInput | null,
  waterMark4?: NodeStatisticsValueInput | null,
  humidity?: NodeStatisticsValueInput | null,
  signal?: NodeStatisticsValueInput | null,
  flowState?: NodeStatisticsValueInput | null,
};

export type NodeStatisticsValueInput = {
  min?: number | null,
  max?: number | null,
  avg?: number | null,
  count?: number | null,
};

export type ModelNodeStatisticsDailyConditionInput = {
  and?: Array< ModelNodeStatisticsDailyConditionInput | null > | null,
  or?: Array< ModelNodeStatisticsDailyConditionInput | null > | null,
  not?: ModelNodeStatisticsDailyConditionInput | null,
};

export type NodeStatisticsDaily = {
  __typename: "NodeStatisticsDaily",
  id: string,
  year: string,
  month: string,
  day: string,
  battery?: NodeStatisticsValue | null,
  temperature?: NodeStatisticsValue | null,
  temperatureGround?: NodeStatisticsValue | null,
  pluvioMeter?: NodeStatisticsValue | null,
  tensiometer1?: NodeStatisticsValue | null,
  tensiometer2?: NodeStatisticsValue | null,
  tensiometer3?: NodeStatisticsValue | null,
  waterMark1?: NodeStatisticsValue | null,
  waterMark2?: NodeStatisticsValue | null,
  waterMark3?: NodeStatisticsValue | null,
  waterMark4?: NodeStatisticsValue | null,
  humidity?: NodeStatisticsValue | null,
  signal?: NodeStatisticsValue | null,
  flowState?: NodeStatisticsValue | null,
};

export type NodeStatisticsValue = {
  __typename: "NodeStatisticsValue",
  min?: number | null,
  max?: number | null,
  avg?: number | null,
  count?: number | null,
};

export type UpdateNodeStatisticsDailyInput = {
  id: string,
  year: string,
  month: string,
  day: string,
  battery?: NodeStatisticsValueInput | null,
  temperature?: NodeStatisticsValueInput | null,
  temperatureGround?: NodeStatisticsValueInput | null,
  pluvioMeter?: NodeStatisticsValueInput | null,
  tensiometer1?: NodeStatisticsValueInput | null,
  tensiometer2?: NodeStatisticsValueInput | null,
  tensiometer3?: NodeStatisticsValueInput | null,
  waterMark1?: NodeStatisticsValueInput | null,
  waterMark2?: NodeStatisticsValueInput | null,
  waterMark3?: NodeStatisticsValueInput | null,
  waterMark4?: NodeStatisticsValueInput | null,
  humidity?: NodeStatisticsValueInput | null,
  signal?: NodeStatisticsValueInput | null,
  flowState?: NodeStatisticsValueInput | null,
};

export type DeleteNodeStatisticsDailyInput = {
  id: string,
  year: string,
  month: string,
  day: string,
};

export type CreateNodeStatisticsMonthlyInput = {
  id?: string | null,
  year: string,
  month: string,
  battery?: NodeStatisticsValueInput | null,
  temperature?: NodeStatisticsValueInput | null,
  temperatureGround?: NodeStatisticsValueInput | null,
  pluvioMeter?: NodeStatisticsValueInput | null,
  tensiometer1?: NodeStatisticsValueInput | null,
  tensiometer2?: NodeStatisticsValueInput | null,
  tensiometer3?: NodeStatisticsValueInput | null,
  waterMark1?: NodeStatisticsValueInput | null,
  waterMark2?: NodeStatisticsValueInput | null,
  waterMark3?: NodeStatisticsValueInput | null,
  waterMark4?: NodeStatisticsValueInput | null,
  humidity?: NodeStatisticsValueInput | null,
  signal?: NodeStatisticsValueInput | null,
  flowState?: NodeStatisticsValueInput | null,
};

export type ModelNodeStatisticsMonthlyConditionInput = {
  and?: Array< ModelNodeStatisticsMonthlyConditionInput | null > | null,
  or?: Array< ModelNodeStatisticsMonthlyConditionInput | null > | null,
  not?: ModelNodeStatisticsMonthlyConditionInput | null,
};

export type NodeStatisticsMonthly = {
  __typename: "NodeStatisticsMonthly",
  id: string,
  year: string,
  month: string,
  battery?: NodeStatisticsValue | null,
  temperature?: NodeStatisticsValue | null,
  temperatureGround?: NodeStatisticsValue | null,
  pluvioMeter?: NodeStatisticsValue | null,
  tensiometer1?: NodeStatisticsValue | null,
  tensiometer2?: NodeStatisticsValue | null,
  tensiometer3?: NodeStatisticsValue | null,
  waterMark1?: NodeStatisticsValue | null,
  waterMark2?: NodeStatisticsValue | null,
  waterMark3?: NodeStatisticsValue | null,
  waterMark4?: NodeStatisticsValue | null,
  humidity?: NodeStatisticsValue | null,
  signal?: NodeStatisticsValue | null,
  flowState?: NodeStatisticsValue | null,
};

export type UpdateNodeStatisticsMonthlyInput = {
  id: string,
  year: string,
  month: string,
  battery?: NodeStatisticsValueInput | null,
  temperature?: NodeStatisticsValueInput | null,
  temperatureGround?: NodeStatisticsValueInput | null,
  pluvioMeter?: NodeStatisticsValueInput | null,
  tensiometer1?: NodeStatisticsValueInput | null,
  tensiometer2?: NodeStatisticsValueInput | null,
  tensiometer3?: NodeStatisticsValueInput | null,
  waterMark1?: NodeStatisticsValueInput | null,
  waterMark2?: NodeStatisticsValueInput | null,
  waterMark3?: NodeStatisticsValueInput | null,
  waterMark4?: NodeStatisticsValueInput | null,
  humidity?: NodeStatisticsValueInput | null,
  signal?: NodeStatisticsValueInput | null,
  flowState?: NodeStatisticsValueInput | null,
};

export type DeleteNodeStatisticsMonthlyInput = {
  id: string,
  year: string,
  month: string,
};

export type CreateNodeOpeningsInput = {
  id?: string | null,
  nodeProgram: Array< string >,
  programID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  nodeOpeningsNodeId?: string | null,
};

export type ModelNodeOpeningsConditionInput = {
  nodeProgram?: ModelStringInput | null,
  programID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeOpeningsConditionInput | null > | null,
  or?: Array< ModelNodeOpeningsConditionInput | null > | null,
  not?: ModelNodeOpeningsConditionInput | null,
  nodeOpeningsNodeId?: ModelIDInput | null,
};

export type UpdateNodeOpeningsInput = {
  id: string,
  nodeProgram?: Array< string > | null,
  programID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  nodeOpeningsNodeId?: string | null,
};

export type DeleteNodeOpeningsInput = {
  id: string,
};

export type CreateProgramInput = {
  id?: string | null,
  title: string,
  isRunning?: boolean | null,
  consortiumID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelProgramConditionInput = {
  title?: ModelStringInput | null,
  isRunning?: ModelBooleanInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProgramConditionInput | null > | null,
  or?: Array< ModelProgramConditionInput | null > | null,
  not?: ModelProgramConditionInput | null,
};

export type UpdateProgramInput = {
  id: string,
  title?: string | null,
  isRunning?: boolean | null,
  consortiumID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteProgramInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  priority?: NotificationPriority | null,
  message?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumID: string,
  nodeID: string,
  status: NotificationStatus,
  ttl?: number | null,
};

export type ModelNotificationConditionInput = {
  priority?: ModelNotificationPriorityInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  nodeID?: ModelIDInput | null,
  status?: ModelNotificationStatusInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationPriorityInput = {
  eq?: NotificationPriority | null,
  ne?: NotificationPriority | null,
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null,
  ne?: NotificationStatus | null,
};

export type UpdateNotificationInput = {
  id: string,
  priority?: NotificationPriority | null,
  message?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  consortiumID?: string | null,
  nodeID?: string | null,
  status?: NotificationStatus | null,
  ttl?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateNodeLogInput = {
  id?: string | null,
  nodeID?: string | null,
  rawMessage?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelNodeLogConditionInput = {
  nodeID?: ModelIDInput | null,
  rawMessage?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeLogConditionInput | null > | null,
  or?: Array< ModelNodeLogConditionInput | null > | null,
  not?: ModelNodeLogConditionInput | null,
};

export type UpdateNodeLogInput = {
  id: string,
  nodeID?: string | null,
  rawMessage?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteNodeLogInput = {
  id: string,
};

export type CreateAlarmInput = {
  id?: string | null,
  consortiumID: string,
  name?: string | null,
  property?: string | null,
  operator?: AlarmOperator | null,
  value?: number | null,
  currentlyFiringNodes?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelAlarmConditionInput = {
  consortiumID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  property?: ModelStringInput | null,
  operator?: ModelAlarmOperatorInput | null,
  value?: ModelFloatInput | null,
  currentlyFiringNodes?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAlarmConditionInput | null > | null,
  or?: Array< ModelAlarmConditionInput | null > | null,
  not?: ModelAlarmConditionInput | null,
};

export type ModelAlarmOperatorInput = {
  eq?: AlarmOperator | null,
  ne?: AlarmOperator | null,
};

export type UpdateAlarmInput = {
  id: string,
  consortiumID?: string | null,
  name?: string | null,
  property?: string | null,
  operator?: AlarmOperator | null,
  value?: number | null,
  currentlyFiringNodes?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteAlarmInput = {
  id: string,
};

export type CreateNotificationSubscriberInput = {
  subscription: string,
  userGroup?: string | null,
  consortiumID: string,
  createdAt?: string | null,
};

export type ModelNotificationSubscriberConditionInput = {
  userGroup?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationSubscriberConditionInput | null > | null,
  or?: Array< ModelNotificationSubscriberConditionInput | null > | null,
  not?: ModelNotificationSubscriberConditionInput | null,
};

export type NotificationSubscriber = {
  __typename: "NotificationSubscriber",
  subscription: string,
  userGroup?: string | null,
  consortiumID: string,
  createdAt?: string | null,
};

export type UpdateNotificationSubscriberInput = {
  subscription: string,
  userGroup?: string | null,
  consortiumID?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificationSubscriberInput = {
  subscription: string,
};

export type CreateEndUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  userType: UserType,
  consortiumID: string,
  createdAt?: string | null,
};

export type ModelEndUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEndUserConditionInput | null > | null,
  or?: Array< ModelEndUserConditionInput | null > | null,
  not?: ModelEndUserConditionInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type UpdateEndUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  userType?: UserType | null,
  consortiumID?: string | null,
  createdAt?: string | null,
};

export type DeleteEndUserInput = {
  id: string,
};

export type ModelConsortiumFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  contact?: ModelStringInput | null,
  gatewayLatestComm?: ModelStringInput | null,
  gatewayStatus?: ModelStringInput | null,
  gatewayBattery?: ModelFloatInput | null,
  gatewaySignal?: ModelFloatInput | null,
  gatewayConnectionType?: ModelStringInput | null,
  gatewayLocalTime?: ModelStringInput | null,
  gatewayLocalFirmware?: ModelStringInput | null,
  gatewayAutotest?: ModelStringInput | null,
  gatewaySolarTension?: ModelFloatInput | null,
  gatewaySolarCurrent?: ModelFloatInput | null,
  gatewayBatteryTension?: ModelFloatInput | null,
  gatewayBatteryCurrent?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConsortiumFilterInput | null > | null,
  or?: Array< ModelConsortiumFilterInput | null > | null,
  not?: ModelConsortiumFilterInput | null,
  consortiumDefaultProgramId?: ModelIDInput | null,
};

export type ModelConsortiumConnection = {
  __typename: "ModelConsortiumConnection",
  items:  Array<Consortium | null >,
  nextToken?: string | null,
};

export type ModelNodeFilterInput = {
  id?: ModelIDInput | null,
  idxSensor?: ModelIntInput | null,
  title?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  temperatureGround?: ModelFloatInput | null,
  pluvioMeter?: ModelFloatInput | null,
  tensiometer1?: ModelFloatInput | null,
  tensiometer2?: ModelFloatInput | null,
  tensiometer3?: ModelFloatInput | null,
  waterMark1?: ModelFloatInput | null,
  waterMark2?: ModelFloatInput | null,
  waterMark3?: ModelFloatInput | null,
  waterMark4?: ModelFloatInput | null,
  humidity?: ModelFloatInput | null,
  signal?: ModelFloatInput | null,
  flowState?: ModelIntInput | null,
  enabledSensors?: ModelStringInput | null,
  status?: ModelNodeStatusInput | null,
  valveStatus?: ModelValveStatusInput | null,
  lastValveCommand?: ModelValveStatusInput | null,
  nodeType?: ModelNodeTypeInput | null,
  isLogEnabled?: ModelBooleanInput | null,
  isOpeningOngoing?: ModelBooleanInput | null,
  openingStartTime?: ModelStringInput | null,
  isClosingOngoing?: ModelBooleanInput | null,
  closingStartTime?: ModelStringInput | null,
  isDataOngoing?: ModelBooleanInput | null,
  dataStartTime?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeFilterInput | null > | null,
  or?: Array< ModelNodeFilterInput | null > | null,
  not?: ModelNodeFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelNodeDataRecordFilterInput = {
  id?: ModelIDInput | null,
  nodeID?: ModelIDInput | null,
  battery?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  temperatureGround?: ModelFloatInput | null,
  pluvioMeter?: ModelFloatInput | null,
  tensiometer1?: ModelFloatInput | null,
  tensiometer2?: ModelFloatInput | null,
  tensiometer3?: ModelFloatInput | null,
  waterMark1?: ModelFloatInput | null,
  waterMark2?: ModelFloatInput | null,
  waterMark3?: ModelFloatInput | null,
  waterMark4?: ModelFloatInput | null,
  flowState?: ModelIntInput | null,
  humidity?: ModelFloatInput | null,
  signal?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelNodeDataRecordFilterInput | null > | null,
  or?: Array< ModelNodeDataRecordFilterInput | null > | null,
  not?: ModelNodeDataRecordFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelNodeStatisticsDailyPrimaryCompositeKeyConditionInput = {
  eq?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
  le?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
  lt?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
  ge?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
  gt?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
  between?: Array< ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelNodeStatisticsDailyPrimaryCompositeKeyInput | null,
};

export type ModelNodeStatisticsDailyPrimaryCompositeKeyInput = {
  year?: string | null,
  month?: string | null,
  day?: string | null,
};

export type ModelNodeStatisticsDailyFilterInput = {
  id?: ModelIDInput | null,
  year?: ModelStringInput | null,
  month?: ModelStringInput | null,
  day?: ModelStringInput | null,
  and?: Array< ModelNodeStatisticsDailyFilterInput | null > | null,
  or?: Array< ModelNodeStatisticsDailyFilterInput | null > | null,
  not?: ModelNodeStatisticsDailyFilterInput | null,
};

export type ModelNodeStatisticsDailyConnection = {
  __typename: "ModelNodeStatisticsDailyConnection",
  items:  Array<NodeStatisticsDaily | null >,
  nextToken?: string | null,
};

export type ModelNodeStatisticsMonthlyPrimaryCompositeKeyConditionInput = {
  eq?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
  le?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
  lt?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
  ge?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
  gt?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
  between?: Array< ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput | null,
};

export type ModelNodeStatisticsMonthlyPrimaryCompositeKeyInput = {
  year?: string | null,
  month?: string | null,
};

export type ModelNodeStatisticsMonthlyFilterInput = {
  id?: ModelIDInput | null,
  year?: ModelStringInput | null,
  month?: ModelStringInput | null,
  and?: Array< ModelNodeStatisticsMonthlyFilterInput | null > | null,
  or?: Array< ModelNodeStatisticsMonthlyFilterInput | null > | null,
  not?: ModelNodeStatisticsMonthlyFilterInput | null,
};

export type ModelNodeStatisticsMonthlyConnection = {
  __typename: "ModelNodeStatisticsMonthlyConnection",
  items:  Array<NodeStatisticsMonthly | null >,
  nextToken?: string | null,
};

export type ModelNodeOpeningsFilterInput = {
  id?: ModelIDInput | null,
  nodeProgram?: ModelStringInput | null,
  programID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeOpeningsFilterInput | null > | null,
  or?: Array< ModelNodeOpeningsFilterInput | null > | null,
  not?: ModelNodeOpeningsFilterInput | null,
  nodeOpeningsNodeId?: ModelIDInput | null,
};

export type ModelProgramFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  isRunning?: ModelBooleanInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProgramFilterInput | null > | null,
  or?: Array< ModelProgramFilterInput | null > | null,
  not?: ModelProgramFilterInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  priority?: ModelNotificationPriorityInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  nodeID?: ModelIDInput | null,
  status?: ModelNotificationStatusInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNodeLogFilterInput = {
  id?: ModelIDInput | null,
  nodeID?: ModelIDInput | null,
  rawMessage?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNodeLogFilterInput | null > | null,
  or?: Array< ModelNodeLogFilterInput | null > | null,
  not?: ModelNodeLogFilterInput | null,
};

export type ModelAlarmFilterInput = {
  id?: ModelIDInput | null,
  consortiumID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  property?: ModelStringInput | null,
  operator?: ModelAlarmOperatorInput | null,
  value?: ModelFloatInput | null,
  currentlyFiringNodes?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAlarmFilterInput | null > | null,
  or?: Array< ModelAlarmFilterInput | null > | null,
  not?: ModelAlarmFilterInput | null,
};

export type ModelNotificationSubscriberFilterInput = {
  subscription?: ModelStringInput | null,
  userGroup?: ModelStringInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationSubscriberFilterInput | null > | null,
  or?: Array< ModelNotificationSubscriberFilterInput | null > | null,
  not?: ModelNotificationSubscriberFilterInput | null,
};

export type ModelNotificationSubscriberConnection = {
  __typename: "ModelNotificationSubscriberConnection",
  items:  Array<NotificationSubscriber | null >,
  nextToken?: string | null,
};

export type ModelEndUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  consortiumID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEndUserFilterInput | null > | null,
  or?: Array< ModelEndUserFilterInput | null > | null,
  not?: ModelEndUserFilterInput | null,
};

export type ModelSubscriptionConsortiumFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  contact?: ModelSubscriptionStringInput | null,
  gatewayLatestComm?: ModelSubscriptionStringInput | null,
  gatewayStatus?: ModelSubscriptionStringInput | null,
  gatewayBattery?: ModelSubscriptionFloatInput | null,
  gatewaySignal?: ModelSubscriptionFloatInput | null,
  gatewayConnectionType?: ModelSubscriptionStringInput | null,
  gatewayLocalTime?: ModelSubscriptionStringInput | null,
  gatewayLocalFirmware?: ModelSubscriptionStringInput | null,
  gatewayAutotest?: ModelSubscriptionStringInput | null,
  gatewaySolarTension?: ModelSubscriptionFloatInput | null,
  gatewaySolarCurrent?: ModelSubscriptionFloatInput | null,
  gatewayBatteryTension?: ModelSubscriptionFloatInput | null,
  gatewayBatteryCurrent?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConsortiumFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsortiumFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionNodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  idxSensor?: ModelSubscriptionIntInput | null,
  title?: ModelSubscriptionStringInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  battery?: ModelSubscriptionFloatInput | null,
  temperature?: ModelSubscriptionFloatInput | null,
  temperatureGround?: ModelSubscriptionFloatInput | null,
  pluvioMeter?: ModelSubscriptionFloatInput | null,
  tensiometer1?: ModelSubscriptionFloatInput | null,
  tensiometer2?: ModelSubscriptionFloatInput | null,
  tensiometer3?: ModelSubscriptionFloatInput | null,
  waterMark1?: ModelSubscriptionFloatInput | null,
  waterMark2?: ModelSubscriptionFloatInput | null,
  waterMark3?: ModelSubscriptionFloatInput | null,
  waterMark4?: ModelSubscriptionFloatInput | null,
  humidity?: ModelSubscriptionFloatInput | null,
  signal?: ModelSubscriptionFloatInput | null,
  flowState?: ModelSubscriptionIntInput | null,
  enabledSensors?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  valveStatus?: ModelSubscriptionStringInput | null,
  lastValveCommand?: ModelSubscriptionStringInput | null,
  nodeType?: ModelSubscriptionStringInput | null,
  isLogEnabled?: ModelSubscriptionBooleanInput | null,
  isOpeningOngoing?: ModelSubscriptionBooleanInput | null,
  openingStartTime?: ModelSubscriptionStringInput | null,
  isClosingOngoing?: ModelSubscriptionBooleanInput | null,
  closingStartTime?: ModelSubscriptionStringInput | null,
  isDataOngoing?: ModelSubscriptionBooleanInput | null,
  dataStartTime?: ModelSubscriptionStringInput | null,
  consortiumID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionNodeDataRecordFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nodeID?: ModelSubscriptionIDInput | null,
  battery?: ModelSubscriptionFloatInput | null,
  temperature?: ModelSubscriptionFloatInput | null,
  temperatureGround?: ModelSubscriptionFloatInput | null,
  pluvioMeter?: ModelSubscriptionFloatInput | null,
  tensiometer1?: ModelSubscriptionFloatInput | null,
  tensiometer2?: ModelSubscriptionFloatInput | null,
  tensiometer3?: ModelSubscriptionFloatInput | null,
  waterMark1?: ModelSubscriptionFloatInput | null,
  waterMark2?: ModelSubscriptionFloatInput | null,
  waterMark3?: ModelSubscriptionFloatInput | null,
  waterMark4?: ModelSubscriptionFloatInput | null,
  flowState?: ModelSubscriptionIntInput | null,
  humidity?: ModelSubscriptionFloatInput | null,
  signal?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionNodeDataRecordFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeDataRecordFilterInput | null > | null,
};

export type ModelSubscriptionNodeStatisticsDailyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionStringInput | null,
  month?: ModelSubscriptionStringInput | null,
  day?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNodeStatisticsDailyFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeStatisticsDailyFilterInput | null > | null,
};

export type ModelSubscriptionNodeStatisticsMonthlyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionStringInput | null,
  month?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNodeStatisticsMonthlyFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeStatisticsMonthlyFilterInput | null > | null,
};

export type ModelSubscriptionNodeOpeningsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nodeProgram?: ModelSubscriptionStringInput | null,
  programID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNodeOpeningsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeOpeningsFilterInput | null > | null,
};

export type ModelSubscriptionProgramFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  isRunning?: ModelSubscriptionBooleanInput | null,
  consortiumID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProgramFilterInput | null > | null,
  or?: Array< ModelSubscriptionProgramFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  priority?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  consortiumID?: ModelSubscriptionIDInput | null,
  nodeID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionNodeLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nodeID?: ModelSubscriptionIDInput | null,
  rawMessage?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNodeLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionNodeLogFilterInput | null > | null,
};

export type ModelSubscriptionAlarmFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  consortiumID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  property?: ModelSubscriptionStringInput | null,
  operator?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionFloatInput | null,
  currentlyFiringNodes?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAlarmFilterInput | null > | null,
  or?: Array< ModelSubscriptionAlarmFilterInput | null > | null,
};

export type ModelSubscriptionEndUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  userType?: ModelSubscriptionStringInput | null,
  consortiumID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEndUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionEndUserFilterInput | null > | null,
};

export type DeepGetProgramQueryVariables = {
  id: string,
};

export type DeepGetProgramQuery = {
  getProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        node?:  {
          __typename: "Node",
          id: string,
          idxSensor: number,
          title: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetNodeSortedManagerQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  from?: string | null,
  to?: string | null,
};

export type GetNodeSortedManagerQuery = {
  getNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        pluvioMeter?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
      } | null >,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
      } | null >,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        status: NotificationStatus,
        consortiumID: string,
        nodeID: string,
        createdAt?: string | null,
      } | null >,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetNodeSortedBaseQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  from?: string | null,
  to?: string | null,
};

export type GetNodeSortedBaseQuery = {
  getNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    enabledSensors?: Array< string | null > | null,
    signal?: number | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        pluvioMeter?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
      } | null >,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
      } | null >,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        status: NotificationStatus,
        consortiumID: string,
        nodeID: string,
        createdAt?: string | null,
      } | null >,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListNodesShallowQueryVariables = {
  id: string,
};

export type ListNodesShallowQuery = {
  getConsortium?:  {
    __typename: "Consortium",
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
  } | null,
};

export type GetConsortiumSortedManagerQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
};

export type GetConsortiumSortedManagerQuery = {
  getConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        enabledSensors?: Array< string | null > | null,
        signal?: number | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        status: NotificationStatus,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
      } | null >,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        userType: UserType,
        email: string,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type GetConsortiumSortedBaseQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
};

export type GetConsortiumSortedBaseQuery = {
  getConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        status: NodeStatus,
        enabledSensors?: Array< string | null > | null,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        status: NotificationStatus,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
      } | null >,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type CreateCognitoEndUserMutationVariables = {
  consortiumID: string,
  user: string,
};

export type CreateCognitoEndUserMutation = {
  createCognitoEndUser?: string | null,
};

export type CreateConsortiumMutationVariables = {
  input: CreateConsortiumInput,
  condition?: ModelConsortiumConditionInput | null,
};

export type CreateConsortiumMutation = {
  createConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type UpdateConsortiumMutationVariables = {
  input: UpdateConsortiumInput,
  condition?: ModelConsortiumConditionInput | null,
};

export type UpdateConsortiumMutation = {
  updateConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type DeleteConsortiumMutationVariables = {
  input: DeleteConsortiumInput,
  condition?: ModelConsortiumConditionInput | null,
};

export type DeleteConsortiumMutation = {
  deleteConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type CreateNodeMutationVariables = {
  input: CreateNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type CreateNodeMutation = {
  createNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateNodeMutationVariables = {
  input: UpdateNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type UpdateNodeMutation = {
  updateNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteNodeMutationVariables = {
  input: DeleteNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type DeleteNodeMutation = {
  deleteNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateNodeDataRecordMutationVariables = {
  input: CreateNodeDataRecordInput,
  condition?: ModelNodeDataRecordConditionInput | null,
};

export type CreateNodeDataRecordMutation = {
  createNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type UpdateNodeDataRecordMutationVariables = {
  input: UpdateNodeDataRecordInput,
  condition?: ModelNodeDataRecordConditionInput | null,
};

export type UpdateNodeDataRecordMutation = {
  updateNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type DeleteNodeDataRecordMutationVariables = {
  input: DeleteNodeDataRecordInput,
  condition?: ModelNodeDataRecordConditionInput | null,
};

export type DeleteNodeDataRecordMutation = {
  deleteNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type CreateNodeStatisticsDailyMutationVariables = {
  input: CreateNodeStatisticsDailyInput,
  condition?: ModelNodeStatisticsDailyConditionInput | null,
};

export type CreateNodeStatisticsDailyMutation = {
  createNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type UpdateNodeStatisticsDailyMutationVariables = {
  input: UpdateNodeStatisticsDailyInput,
  condition?: ModelNodeStatisticsDailyConditionInput | null,
};

export type UpdateNodeStatisticsDailyMutation = {
  updateNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type DeleteNodeStatisticsDailyMutationVariables = {
  input: DeleteNodeStatisticsDailyInput,
  condition?: ModelNodeStatisticsDailyConditionInput | null,
};

export type DeleteNodeStatisticsDailyMutation = {
  deleteNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type CreateNodeStatisticsMonthlyMutationVariables = {
  input: CreateNodeStatisticsMonthlyInput,
  condition?: ModelNodeStatisticsMonthlyConditionInput | null,
};

export type CreateNodeStatisticsMonthlyMutation = {
  createNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type UpdateNodeStatisticsMonthlyMutationVariables = {
  input: UpdateNodeStatisticsMonthlyInput,
  condition?: ModelNodeStatisticsMonthlyConditionInput | null,
};

export type UpdateNodeStatisticsMonthlyMutation = {
  updateNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type DeleteNodeStatisticsMonthlyMutationVariables = {
  input: DeleteNodeStatisticsMonthlyInput,
  condition?: ModelNodeStatisticsMonthlyConditionInput | null,
};

export type DeleteNodeStatisticsMonthlyMutation = {
  deleteNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type CreateNodeOpeningsMutationVariables = {
  input: CreateNodeOpeningsInput,
  condition?: ModelNodeOpeningsConditionInput | null,
};

export type CreateNodeOpeningsMutation = {
  createNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type UpdateNodeOpeningsMutationVariables = {
  input: UpdateNodeOpeningsInput,
  condition?: ModelNodeOpeningsConditionInput | null,
};

export type UpdateNodeOpeningsMutation = {
  updateNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type DeleteNodeOpeningsMutationVariables = {
  input: DeleteNodeOpeningsInput,
  condition?: ModelNodeOpeningsConditionInput | null,
};

export type DeleteNodeOpeningsMutation = {
  deleteNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type CreateProgramMutationVariables = {
  input: CreateProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type CreateProgramMutation = {
  createProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateProgramMutationVariables = {
  input: UpdateProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type UpdateProgramMutation = {
  updateProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteProgramMutationVariables = {
  input: DeleteProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type DeleteProgramMutation = {
  deleteProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type CreateNodeLogMutationVariables = {
  input: CreateNodeLogInput,
  condition?: ModelNodeLogConditionInput | null,
};

export type CreateNodeLogMutation = {
  createNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateNodeLogMutationVariables = {
  input: UpdateNodeLogInput,
  condition?: ModelNodeLogConditionInput | null,
};

export type UpdateNodeLogMutation = {
  updateNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteNodeLogMutationVariables = {
  input: DeleteNodeLogInput,
  condition?: ModelNodeLogConditionInput | null,
};

export type DeleteNodeLogMutation = {
  deleteNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateAlarmMutationVariables = {
  input: CreateAlarmInput,
  condition?: ModelAlarmConditionInput | null,
};

export type CreateAlarmMutation = {
  createAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateAlarmMutationVariables = {
  input: UpdateAlarmInput,
  condition?: ModelAlarmConditionInput | null,
};

export type UpdateAlarmMutation = {
  updateAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteAlarmMutationVariables = {
  input: DeleteAlarmInput,
  condition?: ModelAlarmConditionInput | null,
};

export type DeleteAlarmMutation = {
  deleteAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateNotificationSubscriberMutationVariables = {
  input: CreateNotificationSubscriberInput,
  condition?: ModelNotificationSubscriberConditionInput | null,
};

export type CreateNotificationSubscriberMutation = {
  createNotificationSubscriber?:  {
    __typename: "NotificationSubscriber",
    subscription: string,
    userGroup?: string | null,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type UpdateNotificationSubscriberMutationVariables = {
  input: UpdateNotificationSubscriberInput,
  condition?: ModelNotificationSubscriberConditionInput | null,
};

export type UpdateNotificationSubscriberMutation = {
  updateNotificationSubscriber?:  {
    __typename: "NotificationSubscriber",
    subscription: string,
    userGroup?: string | null,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type DeleteNotificationSubscriberMutationVariables = {
  input: DeleteNotificationSubscriberInput,
  condition?: ModelNotificationSubscriberConditionInput | null,
};

export type DeleteNotificationSubscriberMutation = {
  deleteNotificationSubscriber?:  {
    __typename: "NotificationSubscriber",
    subscription: string,
    userGroup?: string | null,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type CreateEndUserMutationVariables = {
  input: CreateEndUserInput,
  condition?: ModelEndUserConditionInput | null,
};

export type CreateEndUserMutation = {
  createEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type UpdateEndUserMutationVariables = {
  input: UpdateEndUserInput,
  condition?: ModelEndUserConditionInput | null,
};

export type UpdateEndUserMutation = {
  updateEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type DeleteEndUserMutationVariables = {
  input: DeleteEndUserInput,
  condition?: ModelEndUserConditionInput | null,
};

export type DeleteEndUserMutation = {
  deleteEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type SendMessageQueryVariables = {
  consortiumID?: string | null,
  nodeID?: string | null,
  action: string,
  payload?: string | null,
};

export type SendMessageQuery = {
  sendMessage?: string | null,
};

export type GetConsortiumQueryVariables = {
  id: string,
};

export type GetConsortiumQuery = {
  getConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type ListConsortiumsQueryVariables = {
  filter?: ModelConsortiumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsortiumsQuery = {
  listConsortiums?:  {
    __typename: "ModelConsortiumConnection",
    items:  Array< {
      __typename: "Consortium",
      id: string,
      name: string,
      lat: number,
      lng: number,
      contact: string,
      nodes?:  {
        __typename: "ModelNodeConnection",
        nextToken?: string | null,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      alarms?:  {
        __typename: "ModelAlarmConnection",
        nextToken?: string | null,
      } | null,
      endUsers?:  {
        __typename: "ModelEndUserConnection",
        nextToken?: string | null,
      } | null,
      defaultProgram?:  {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      gatewayLatestComm?: string | null,
      gatewayStatus?: string | null,
      gatewayBattery?: number | null,
      gatewaySignal?: number | null,
      gatewayConnectionType?: string | null,
      gatewayLocalTime?: string | null,
      gatewayLocalFirmware?: string | null,
      gatewayAutotest?: string | null,
      gatewaySolarTension?: number | null,
      gatewaySolarCurrent?: number | null,
      gatewayBatteryTension?: number | null,
      gatewayBatteryCurrent?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumDefaultProgramId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeQueryVariables = {
  id: string,
};

export type GetNodeQuery = {
  getNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListNodesQueryVariables = {
  filter?: ModelNodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodesQuery = {
  listNodes?:  {
    __typename: "ModelNodeConnection",
    items:  Array< {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NodesByConsortiumQueryVariables = {
  consortiumID: string,
  idxSensor?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NodesByConsortiumQuery = {
  nodesByConsortium?:  {
    __typename: "ModelNodeConnection",
    items:  Array< {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeDataRecordQueryVariables = {
  id: string,
};

export type GetNodeDataRecordQuery = {
  getNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type ListNodeDataRecordsQueryVariables = {
  filter?: ModelNodeDataRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodeDataRecordsQuery = {
  listNodeDataRecords?:  {
    __typename: "ModelNodeDataRecordConnection",
    items:  Array< {
      __typename: "NodeDataRecord",
      id: string,
      nodeID?: string | null,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      flowState?: number | null,
      humidity?: number | null,
      signal?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HistoryByNodeQueryVariables = {
  nodeID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeDataRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HistoryByNodeQuery = {
  historyByNode?:  {
    __typename: "ModelNodeDataRecordConnection",
    items:  Array< {
      __typename: "NodeDataRecord",
      id: string,
      nodeID?: string | null,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      flowState?: number | null,
      humidity?: number | null,
      signal?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeStatisticsDailyQueryVariables = {
  id: string,
  year: string,
  month: string,
  day: string,
};

export type GetNodeStatisticsDailyQuery = {
  getNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type ListNodeStatisticsDailiesQueryVariables = {
  id?: string | null,
  yearMonthDay?: ModelNodeStatisticsDailyPrimaryCompositeKeyConditionInput | null,
  filter?: ModelNodeStatisticsDailyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNodeStatisticsDailiesQuery = {
  listNodeStatisticsDailies?:  {
    __typename: "ModelNodeStatisticsDailyConnection",
    items:  Array< {
      __typename: "NodeStatisticsDaily",
      id: string,
      year: string,
      month: string,
      day: string,
      battery?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      temperature?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      temperatureGround?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      pluvioMeter?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer1?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer2?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer3?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark1?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark2?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark3?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark4?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      humidity?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      signal?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      flowState?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeStatisticsMonthlyQueryVariables = {
  id: string,
  year: string,
  month: string,
};

export type GetNodeStatisticsMonthlyQuery = {
  getNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type ListNodeStatisticsMonthliesQueryVariables = {
  id?: string | null,
  yearMonth?: ModelNodeStatisticsMonthlyPrimaryCompositeKeyConditionInput | null,
  filter?: ModelNodeStatisticsMonthlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNodeStatisticsMonthliesQuery = {
  listNodeStatisticsMonthlies?:  {
    __typename: "ModelNodeStatisticsMonthlyConnection",
    items:  Array< {
      __typename: "NodeStatisticsMonthly",
      id: string,
      year: string,
      month: string,
      battery?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      temperature?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      temperatureGround?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      pluvioMeter?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer1?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer2?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      tensiometer3?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark1?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark2?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark3?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      waterMark4?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      humidity?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      signal?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
      flowState?:  {
        __typename: "NodeStatisticsValue",
        min?: number | null,
        max?: number | null,
        avg?: number | null,
        count?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeOpeningsQueryVariables = {
  id: string,
};

export type GetNodeOpeningsQuery = {
  getNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type ListNodeOpeningsQueryVariables = {
  filter?: ModelNodeOpeningsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodeOpeningsQuery = {
  listNodeOpenings?:  {
    __typename: "ModelNodeOpeningsConnection",
    items:  Array< {
      __typename: "NodeOpenings",
      id: string,
      node?:  {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      nodeProgram: Array< string >,
      programID?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      nodeOpeningsNodeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NodeOpeningsByProgramQueryVariables = {
  programID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeOpeningsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NodeOpeningsByProgramQuery = {
  nodeOpeningsByProgram?:  {
    __typename: "ModelNodeOpeningsConnection",
    items:  Array< {
      __typename: "NodeOpenings",
      id: string,
      node?:  {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      nodeProgram: Array< string >,
      programID?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      nodeOpeningsNodeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProgramQueryVariables = {
  id: string,
};

export type GetProgramQuery = {
  getProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListProgramsQueryVariables = {
  filter?: ModelProgramFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgramsQuery = {
  listPrograms?:  {
    __typename: "ModelProgramConnection",
    items:  Array< {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProgramsByConsortiumQueryVariables = {
  consortiumID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProgramFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProgramsByConsortiumQuery = {
  programsByConsortium?:  {
    __typename: "ModelProgramConnection",
    items:  Array< {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      priority?: NotificationPriority | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumID: string,
      nodeID: string,
      status: NotificationStatus,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByPriorityQueryVariables = {
  priority: NotificationPriority,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByPriorityQuery = {
  notificationsByPriority?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      priority?: NotificationPriority | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumID: string,
      nodeID: string,
      status: NotificationStatus,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByConsortiumQueryVariables = {
  consortiumID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByConsortiumQuery = {
  notificationsByConsortium?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      priority?: NotificationPriority | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumID: string,
      nodeID: string,
      status: NotificationStatus,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByNodeQueryVariables = {
  nodeID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByNodeQuery = {
  notificationsByNode?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      priority?: NotificationPriority | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumID: string,
      nodeID: string,
      status: NotificationStatus,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByStatusQueryVariables = {
  status: NotificationStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByStatusQuery = {
  notificationsByStatus?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      priority?: NotificationPriority | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      consortiumID: string,
      nodeID: string,
      status: NotificationStatus,
      ttl?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeLogQueryVariables = {
  id: string,
};

export type GetNodeLogQuery = {
  getNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListNodeLogsQueryVariables = {
  filter?: ModelNodeLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodeLogsQuery = {
  listNodeLogs?:  {
    __typename: "ModelNodeLogConnection",
    items:  Array< {
      __typename: "NodeLog",
      id: string,
      nodeID?: string | null,
      rawMessage?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LogsByNodeQueryVariables = {
  nodeID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogsByNodeQuery = {
  logsByNode?:  {
    __typename: "ModelNodeLogConnection",
    items:  Array< {
      __typename: "NodeLog",
      id: string,
      nodeID?: string | null,
      rawMessage?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAlarmQueryVariables = {
  id: string,
};

export type GetAlarmQuery = {
  getAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListAlarmsQueryVariables = {
  filter?: ModelAlarmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlarmsQuery = {
  listAlarms?:  {
    __typename: "ModelAlarmConnection",
    items:  Array< {
      __typename: "Alarm",
      id: string,
      consortiumID: string,
      name?: string | null,
      property?: string | null,
      operator?: AlarmOperator | null,
      value?: number | null,
      currentlyFiringNodes?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AlarmsByConsortiumQueryVariables = {
  consortiumID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlarmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlarmsByConsortiumQuery = {
  alarmsByConsortium?:  {
    __typename: "ModelAlarmConnection",
    items:  Array< {
      __typename: "Alarm",
      id: string,
      consortiumID: string,
      name?: string | null,
      property?: string | null,
      operator?: AlarmOperator | null,
      value?: number | null,
      currentlyFiringNodes?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationSubscribersByConsortiumQueryVariables = {
  consortiumID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationSubscriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationSubscribersByConsortiumQuery = {
  notificationSubscribersByConsortium?:  {
    __typename: "ModelNotificationSubscriberConnection",
    items:  Array< {
      __typename: "NotificationSubscriber",
      subscription: string,
      userGroup?: string | null,
      consortiumID: string,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEndUserQueryVariables = {
  id: string,
};

export type GetEndUserQuery = {
  getEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type ListEndUsersQueryVariables = {
  filter?: ModelEndUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEndUsersQuery = {
  listEndUsers?:  {
    __typename: "ModelEndUserConnection",
    items:  Array< {
      __typename: "EndUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      userType: UserType,
      consortiumID: string,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EndUsersByConsortiumQueryVariables = {
  consortiumID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEndUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EndUsersByConsortiumQuery = {
  endUsersByConsortium?:  {
    __typename: "ModelEndUserConnection",
    items:  Array< {
      __typename: "EndUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      userType: UserType,
      consortiumID: string,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnChangeConsortiumsSubscriptionVariables = {
  consortiumID?: string | null,
  id?: string | null,
};

export type OnChangeConsortiumsSubscription = {
  onChangeConsortiums?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type OnChangeNodeSubscriptionVariables = {
  consortiumID: string,
  id?: string | null,
};

export type OnChangeNodeSubscription = {
  onChangeNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnChangeProgramSubscriptionVariables = {
  consortiumID: string,
  id?: string | null,
};

export type OnChangeProgramSubscription = {
  onChangeProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnChangeAlarmSubscriptionVariables = {
  consortiumID: string,
  id?: string | null,
};

export type OnChangeAlarmSubscription = {
  onChangeAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnChangeNotificationSubscriptionVariables = {
  consortiumID: string,
  id?: string | null,
};

export type OnChangeNotificationSubscription = {
  onChangeNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type OnCreateConsortiumSubscriptionVariables = {
  filter?: ModelSubscriptionConsortiumFilterInput | null,
  id?: string | null,
};

export type OnCreateConsortiumSubscription = {
  onCreateConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type OnUpdateConsortiumSubscriptionVariables = {
  filter?: ModelSubscriptionConsortiumFilterInput | null,
  id?: string | null,
};

export type OnUpdateConsortiumSubscription = {
  onUpdateConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type OnDeleteConsortiumSubscriptionVariables = {
  filter?: ModelSubscriptionConsortiumFilterInput | null,
  id?: string | null,
};

export type OnDeleteConsortiumSubscription = {
  onDeleteConsortium?:  {
    __typename: "Consortium",
    id: string,
    name: string,
    lat: number,
    lng: number,
    contact: string,
    nodes?:  {
      __typename: "ModelNodeConnection",
      items:  Array< {
        __typename: "Node",
        id: string,
        idxSensor: number,
        title: string,
        lat: number,
        lng: number,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        humidity?: number | null,
        signal?: number | null,
        flowState?: number | null,
        enabledSensors?: Array< string | null > | null,
        status: NodeStatus,
        valveStatus?: ValveStatus | null,
        lastValveCommand?: ValveStatus | null,
        nodeType?: NodeType | null,
        isLogEnabled: boolean,
        isOpeningOngoing: boolean,
        openingStartTime?: string | null,
        isClosingOngoing: boolean,
        closingStartTime?: string | null,
        isDataOngoing: boolean,
        dataStartTime?: string | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        title: string,
        isRunning?: boolean | null,
        consortiumID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    alarms?:  {
      __typename: "ModelAlarmConnection",
      items:  Array< {
        __typename: "Alarm",
        id: string,
        consortiumID: string,
        name?: string | null,
        property?: string | null,
        operator?: AlarmOperator | null,
        value?: number | null,
        currentlyFiringNodes?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    endUsers?:  {
      __typename: "ModelEndUserConnection",
      items:  Array< {
        __typename: "EndUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        userType: UserType,
        consortiumID: string,
        createdAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    defaultProgram?:  {
      __typename: "Program",
      id: string,
      title: string,
      isRunning?: boolean | null,
      nodesOpenings?:  {
        __typename: "ModelNodeOpeningsConnection",
        nextToken?: string | null,
      } | null,
      consortiumID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    gatewayLatestComm?: string | null,
    gatewayStatus?: string | null,
    gatewayBattery?: number | null,
    gatewaySignal?: number | null,
    gatewayConnectionType?: string | null,
    gatewayLocalTime?: string | null,
    gatewayLocalFirmware?: string | null,
    gatewayAutotest?: string | null,
    gatewaySolarTension?: number | null,
    gatewaySolarCurrent?: number | null,
    gatewayBatteryTension?: number | null,
    gatewayBatteryCurrent?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumDefaultProgramId?: string | null,
  } | null,
};

export type OnCreateNodeSubscriptionVariables = {
  filter?: ModelSubscriptionNodeFilterInput | null,
};

export type OnCreateNodeSubscription = {
  onCreateNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateNodeSubscriptionVariables = {
  filter?: ModelSubscriptionNodeFilterInput | null,
};

export type OnUpdateNodeSubscription = {
  onUpdateNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteNodeSubscriptionVariables = {
  filter?: ModelSubscriptionNodeFilterInput | null,
};

export type OnDeleteNodeSubscription = {
  onDeleteNode?:  {
    __typename: "Node",
    id: string,
    idxSensor: number,
    title: string,
    lat: number,
    lng: number,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    humidity?: number | null,
    signal?: number | null,
    flowState?: number | null,
    enabledSensors?: Array< string | null > | null,
    status: NodeStatus,
    valveStatus?: ValveStatus | null,
    lastValveCommand?: ValveStatus | null,
    nodeType?: NodeType | null,
    isLogEnabled: boolean,
    isOpeningOngoing: boolean,
    openingStartTime?: string | null,
    isClosingOngoing: boolean,
    closingStartTime?: string | null,
    isDataOngoing: boolean,
    dataStartTime?: string | null,
    consortiumID: string,
    sensorsHistory?:  {
      __typename: "ModelNodeDataRecordConnection",
      items:  Array< {
        __typename: "NodeDataRecord",
        id: string,
        nodeID?: string | null,
        battery?: number | null,
        temperature?: number | null,
        temperatureGround?: number | null,
        pluvioMeter?: number | null,
        tensiometer1?: number | null,
        tensiometer2?: number | null,
        tensiometer3?: number | null,
        waterMark1?: number | null,
        waterMark2?: number | null,
        waterMark3?: number | null,
        waterMark4?: number | null,
        flowState?: number | null,
        humidity?: number | null,
        signal?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLogs?:  {
      __typename: "ModelNodeLogConnection",
      items:  Array< {
        __typename: "NodeLog",
        id: string,
        nodeID?: string | null,
        rawMessage?: string | null,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        priority?: NotificationPriority | null,
        message?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        consortiumID: string,
        nodeID: string,
        status: NotificationStatus,
        ttl?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateNodeDataRecordSubscriptionVariables = {
  filter?: ModelSubscriptionNodeDataRecordFilterInput | null,
};

export type OnCreateNodeDataRecordSubscription = {
  onCreateNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type OnUpdateNodeDataRecordSubscriptionVariables = {
  filter?: ModelSubscriptionNodeDataRecordFilterInput | null,
};

export type OnUpdateNodeDataRecordSubscription = {
  onUpdateNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type OnDeleteNodeDataRecordSubscriptionVariables = {
  filter?: ModelSubscriptionNodeDataRecordFilterInput | null,
};

export type OnDeleteNodeDataRecordSubscription = {
  onDeleteNodeDataRecord?:  {
    __typename: "NodeDataRecord",
    id: string,
    nodeID?: string | null,
    battery?: number | null,
    temperature?: number | null,
    temperatureGround?: number | null,
    pluvioMeter?: number | null,
    tensiometer1?: number | null,
    tensiometer2?: number | null,
    tensiometer3?: number | null,
    waterMark1?: number | null,
    waterMark2?: number | null,
    waterMark3?: number | null,
    waterMark4?: number | null,
    flowState?: number | null,
    humidity?: number | null,
    signal?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    ttl?: number | null,
  } | null,
};

export type OnCreateNodeStatisticsDailySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsDailyFilterInput | null,
};

export type OnCreateNodeStatisticsDailySubscription = {
  onCreateNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnUpdateNodeStatisticsDailySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsDailyFilterInput | null,
};

export type OnUpdateNodeStatisticsDailySubscription = {
  onUpdateNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnDeleteNodeStatisticsDailySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsDailyFilterInput | null,
};

export type OnDeleteNodeStatisticsDailySubscription = {
  onDeleteNodeStatisticsDaily?:  {
    __typename: "NodeStatisticsDaily",
    id: string,
    year: string,
    month: string,
    day: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnCreateNodeStatisticsMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsMonthlyFilterInput | null,
};

export type OnCreateNodeStatisticsMonthlySubscription = {
  onCreateNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnUpdateNodeStatisticsMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsMonthlyFilterInput | null,
};

export type OnUpdateNodeStatisticsMonthlySubscription = {
  onUpdateNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnDeleteNodeStatisticsMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionNodeStatisticsMonthlyFilterInput | null,
};

export type OnDeleteNodeStatisticsMonthlySubscription = {
  onDeleteNodeStatisticsMonthly?:  {
    __typename: "NodeStatisticsMonthly",
    id: string,
    year: string,
    month: string,
    battery?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperature?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    temperatureGround?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    pluvioMeter?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    tensiometer3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark1?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark2?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark3?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    waterMark4?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    humidity?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    signal?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
    flowState?:  {
      __typename: "NodeStatisticsValue",
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      count?: number | null,
    } | null,
  } | null,
};

export type OnCreateNodeOpeningsSubscriptionVariables = {
  filter?: ModelSubscriptionNodeOpeningsFilterInput | null,
};

export type OnCreateNodeOpeningsSubscription = {
  onCreateNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type OnUpdateNodeOpeningsSubscriptionVariables = {
  filter?: ModelSubscriptionNodeOpeningsFilterInput | null,
};

export type OnUpdateNodeOpeningsSubscription = {
  onUpdateNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type OnDeleteNodeOpeningsSubscriptionVariables = {
  filter?: ModelSubscriptionNodeOpeningsFilterInput | null,
};

export type OnDeleteNodeOpeningsSubscription = {
  onDeleteNodeOpenings?:  {
    __typename: "NodeOpenings",
    id: string,
    node?:  {
      __typename: "Node",
      id: string,
      idxSensor: number,
      title: string,
      lat: number,
      lng: number,
      battery?: number | null,
      temperature?: number | null,
      temperatureGround?: number | null,
      pluvioMeter?: number | null,
      tensiometer1?: number | null,
      tensiometer2?: number | null,
      tensiometer3?: number | null,
      waterMark1?: number | null,
      waterMark2?: number | null,
      waterMark3?: number | null,
      waterMark4?: number | null,
      humidity?: number | null,
      signal?: number | null,
      flowState?: number | null,
      enabledSensors?: Array< string | null > | null,
      status: NodeStatus,
      valveStatus?: ValveStatus | null,
      lastValveCommand?: ValveStatus | null,
      nodeType?: NodeType | null,
      isLogEnabled: boolean,
      isOpeningOngoing: boolean,
      openingStartTime?: string | null,
      isClosingOngoing: boolean,
      closingStartTime?: string | null,
      isDataOngoing: boolean,
      dataStartTime?: string | null,
      consortiumID: string,
      sensorsHistory?:  {
        __typename: "ModelNodeDataRecordConnection",
        nextToken?: string | null,
      } | null,
      lastLogs?:  {
        __typename: "ModelNodeLogConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    nodeProgram: Array< string >,
    programID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nodeOpeningsNodeId?: string | null,
  } | null,
};

export type OnCreateProgramSubscriptionVariables = {
  filter?: ModelSubscriptionProgramFilterInput | null,
};

export type OnCreateProgramSubscription = {
  onCreateProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateProgramSubscriptionVariables = {
  filter?: ModelSubscriptionProgramFilterInput | null,
};

export type OnUpdateProgramSubscription = {
  onUpdateProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteProgramSubscriptionVariables = {
  filter?: ModelSubscriptionProgramFilterInput | null,
};

export type OnDeleteProgramSubscription = {
  onDeleteProgram?:  {
    __typename: "Program",
    id: string,
    title: string,
    isRunning?: boolean | null,
    nodesOpenings?:  {
      __typename: "ModelNodeOpeningsConnection",
      items:  Array< {
        __typename: "NodeOpenings",
        id: string,
        nodeProgram: Array< string >,
        programID?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nodeOpeningsNodeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    priority?: NotificationPriority | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    consortiumID: string,
    nodeID: string,
    status: NotificationStatus,
    ttl?: number | null,
  } | null,
};

export type OnCreateNodeLogSubscriptionVariables = {
  filter?: ModelSubscriptionNodeLogFilterInput | null,
};

export type OnCreateNodeLogSubscription = {
  onCreateNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateNodeLogSubscriptionVariables = {
  filter?: ModelSubscriptionNodeLogFilterInput | null,
};

export type OnUpdateNodeLogSubscription = {
  onUpdateNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteNodeLogSubscriptionVariables = {
  filter?: ModelSubscriptionNodeLogFilterInput | null,
};

export type OnDeleteNodeLogSubscription = {
  onDeleteNodeLog?:  {
    __typename: "NodeLog",
    id: string,
    nodeID?: string | null,
    rawMessage?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateAlarmSubscriptionVariables = {
  filter?: ModelSubscriptionAlarmFilterInput | null,
};

export type OnCreateAlarmSubscription = {
  onCreateAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateAlarmSubscriptionVariables = {
  filter?: ModelSubscriptionAlarmFilterInput | null,
};

export type OnUpdateAlarmSubscription = {
  onUpdateAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteAlarmSubscriptionVariables = {
  filter?: ModelSubscriptionAlarmFilterInput | null,
};

export type OnDeleteAlarmSubscription = {
  onDeleteAlarm?:  {
    __typename: "Alarm",
    id: string,
    consortiumID: string,
    name?: string | null,
    property?: string | null,
    operator?: AlarmOperator | null,
    value?: number | null,
    currentlyFiringNodes?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateEndUserSubscriptionVariables = {
  filter?: ModelSubscriptionEndUserFilterInput | null,
};

export type OnCreateEndUserSubscription = {
  onCreateEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type OnUpdateEndUserSubscriptionVariables = {
  filter?: ModelSubscriptionEndUserFilterInput | null,
};

export type OnUpdateEndUserSubscription = {
  onUpdateEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};

export type OnDeleteEndUserSubscriptionVariables = {
  filter?: ModelSubscriptionEndUserFilterInput | null,
};

export type OnDeleteEndUserSubscription = {
  onDeleteEndUser?:  {
    __typename: "EndUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType,
    consortiumID: string,
    createdAt?: string | null,
  } | null,
};
