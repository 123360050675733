/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Consortium } from "../API";

type AppConfig = {
  userGroups?: string[];
  isLoggedIn: boolean;
  isLoading: boolean;
  currentConsortium?: Consortium | null;
  currentUserInfo?: any;
};

const initialState: AppConfig = {
  userGroups: [],
  isLoggedIn: false,
  isLoading: true,
};

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setLoading: (appConfig, isLoading: PayloadAction<boolean>) => {
      appConfig.isLoading = isLoading.payload;
    },
    setLoggedIn: (appConfig, loggedIn: PayloadAction<boolean>) => {
      if (!loggedIn.payload) {
        appConfig.userGroups = [];
        appConfig.currentConsortium = undefined;
      }
      appConfig.isLoggedIn = loggedIn.payload;
    },
    setCurrentConsortium: (
      appConfig,
      action: PayloadAction<Consortium | undefined | null>
    ) => {
      appConfig.currentConsortium = action.payload;
    },
    setUserGroups: (appConfig, action: PayloadAction<string[]>) => {
      appConfig.userGroups = action.payload;
    },
    setCurrentUserInfo: (appConfig, action: PayloadAction<any>) => {
      appConfig.currentUserInfo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoggedIn,
  setLoading,
  setCurrentConsortium,
  setUserGroups,
  setCurrentUserInfo,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
