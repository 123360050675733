import type { GraphQLResult } from "@aws-amplify/api";
import { API, Auth, graphqlOperation } from "aws-amplify";
import type { Observable } from "zen-observable-ts";
import { store } from ".";
import {
  Consortium,
  ListConsortiumsQuery,
  Node,
  OnChangeAlarmSubscription,
  OnChangeConsortiumsSubscription,
  OnChangeNotificationSubscription,
  OnChangeProgramSubscription,
  UpdateConsortiumMutationVariables,
} from "../API";
import {
  getConsortiumSortedBase,
  getConsortiumSortedManager,
} from "../graphql/customQueries";
import * as gqlMutations from "../graphql/mutations";
import { updateConsortium } from "../graphql/mutations";
import * as gqlQueries from "../graphql/queries";
import {
  onChangeAlarm,
  onChangeConsortiums,
  onChangeNotification,
  onChangeProgram,
} from "../graphql/subscriptions";
import { setCurrentConsortium } from "./appConfigSlice";

export async function gqlOperation<T>(operation: T, variables: any) {
  const gqlResult = (await API.graphql(
    graphqlOperation(operation, variables)
  )) as GraphQLResult<T>;
  if (gqlResult.errors) {
    console.error(gqlResult);
    throw new Error("GQL Operation failed");
  }
  return gqlResult.data!;
}

const observationMap: any = {};
export function gqlUnsubscribeAll() {
  Object.values(observationMap).forEach((f: any) => f());
}

export function gqlObserve<T extends object>(
  callerId: string,
  operation: T,
  callback: Function,
  variables?: any
): () => void {
  if (observationMap[callerId]) observationMap[callerId]();

  const subscription = (
    API.graphql(graphqlOperation(operation, variables)) as Observable<T>
  ).subscribe({
    next: (params) => {
      callback();
      console.log(callerId, params);
    },
    error: (error) => console.error(error),
    start: (st) => console.log("Started subscription ", callerId, st),
    complete: () => console.log("Completed subscription"),
  });
  console.log("Started subscription ", callerId);

  const unsubscribeHandler = () => {
    console.log("unsubscribed..", callerId);
    subscription.unsubscribe();
  };

  observationMap[callerId] = unsubscribeHandler;
  return unsubscribeHandler;
}

export async function getConsortium(id: string) {
  // get current user and check if they are a manager or base user

  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession?.accessToken.payload["cognito:groups"];
  // const groups = store.getState().appConfig.userGroups;
  // console.log(groups);
  const isManager = ["manager", "admin"].includes(groups[0]);
  const consortiumQuery = isManager
    ? getConsortiumSortedManager
    : getConsortiumSortedBase;
  const gqlResult = (await API.graphql({
    query: consortiumQuery,
    variables: { id, sortDirection: "DESC" },
  })) as GraphQLResult<any>;

  return gqlResult.data?.getConsortium as Consortium;
}

export async function listConsortiums(): Promise<Consortium[]> {
  const gqlResult = (await API.graphql({
    query: gqlQueries.listConsortiums,
  })) as GraphQLResult<ListConsortiumsQuery>;
  return gqlResult.data?.listConsortiums?.items as Consortium[];
}

export async function deleteConsortium(consortiumId: string) {
  console.log("Deleting consortium", consortiumId);
  await API.graphql(
    graphqlOperation(gqlMutations.deleteConsortium, {
      input: { id: consortiumId },
    })
  );
}

async function listNodes() {
  const consortiumId = store.getState().appConfig.currentConsortium?.id;
  if (!consortiumId) {
    throw new Error("Consortium is not set in page state");
  }
  console.error("TODO list nodes in GQL");
  return []; // DataStore.query(Node, (c) => c.consortiumID("eq", consortiumId));
}

async function nodeIdExists(id: number, excludeNodeId?: string) {
  const consortium = store.getState().appConfig.currentConsortium!;

  // Using backend
  // const consortiumId = store.getState().appConfig.currentConsortium!.id;
  // const result = await gqlOperation(
  //   gqlQueries.nodesByConsortium as NodesByConsortiumQuery,
  //   {
  //     consortiumID: consortiumId,
  //     idxSensor: { eq: id },
  //   } as NodesByConsortiumQueryVariables
  // );
  // return result.nodesByConsortium!.items.length > 0;
  return !!consortium.nodes?.items.find(
    (node) => node?.idxSensor === id && node?.id !== excludeNodeId
  );
}

async function getNextNodeId() {
  const consortium = store.getState().appConfig.currentConsortium!;
  console.log(consortium.nodes);
  const sortedNodes = [...consortium.nodes!.items].sort(
    (a, b) => b!.idxSensor! - a!.idxSensor!
  ) as Node[];

  // Using backend
  // const consortiumId = store.getState().appConfig.currentConsortium!.id;
  // const result = await gqlOperation(
  //   gqlQueries.nodesByConsortium as NodesByConsortiumQuery,
  //   {
  //     consortiumID: consortiumId,
  //     sortDirection: ModelSortDirection.DESC,
  //     limit: 1,
  //   } as NodesByConsortiumQueryVariables
  // );
  // if (result.nodesByConsortium!.items.length === 0) return 1;
  // return result.nodesByConsortium!.items[0]!.idxSensor + 1;
  if (sortedNodes.length === 0) return 1;
  return sortedNodes[0].idxSensor + 1;
}

//  ==== Admin queries
export async function createIoTConsortium(consortiumId: string) {
  const apiName = "AdminQueries";
  const path = "/createIoTConsortium";
  const myInit = {
    body: {
      consortiumId,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(apiName, path, myInit);
}

export async function addUserToGroup(email: string, group: string) {
  const apiName = "AdminQueries";
  const path = "/addUserToGroup";
  const myInit = {
    body: {
      username: email,
      groupname: group,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(apiName, path, myInit);
}

export async function forceConfirmUser(email: string) {
  const apiName = "AdminQueries";
  const path = "/confirmUserSignUp";
  const myInit = {
    body: {
      username: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(apiName, path, myInit);
}

export async function deleteUser(email: string) {
  console.log("Deleting user", email);
  const apiName = "AdminQueries";
  const path = "/deleteUser";
  const myInit = {
    body: {
      username: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(apiName, path, myInit);
}

export async function getUser(email: string) {
  const apiName = "AdminQueries";
  const path = "/getUser";
  const myInit = {
    queryStringParameters: {
      username: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  try {
    await API.get(apiName, path, myInit);
    return true;
  } catch (e) {
    return false;
  }
}

export async function setDefaultProgram(programId: string | null) {
  console.log("Changing Default Program");

  const currentConsortium = store.getState().appConfig.currentConsortium!;

  await gqlOperation(updateConsortium, {
    input: {
      id: currentConsortium.id,
      consortiumDefaultProgramId: programId,
    },
  } as UpdateConsortiumMutationVariables);
}

export function observeCurrentConsortium(
  consortiumId: string,
  callback: Function
) {
  // gqlObserve(
  //   "routing-consortiumRefresh-node",
  //   onChangeNode as OnChangeNodeSubscription,
  //   callback,
  //   { consortiumID: consortiumId }
  // );
  gqlObserve(
    "routing-consortiumRefresh-program",
    onChangeProgram as OnChangeProgramSubscription,
    callback,
    { consortiumID: consortiumId }
  );
  gqlObserve(
    "routing-consortiumRefresh-alarms",
    onChangeAlarm as OnChangeAlarmSubscription,
    callback,
    { consortiumID: consortiumId }
  );
  gqlObserve(
    "routing-consortiumRefresh-notifications",
    onChangeNotification as OnChangeNotificationSubscription,
    callback,
    { consortiumID: consortiumId }
  );
  gqlObserve(
    "routing-consortiumRefresh-consortium",
    onChangeConsortiums as OnChangeConsortiumsSubscription,
    callback,
    { id: consortiumId }
  );
}

export function shallowUpdateConsortium(
  property: keyof Consortium,
  reducer: (v: any) => any
) {
  const currentConsortium = store.getState().appConfig.currentConsortium!;
  if (!currentConsortium) {
    console.error("No current consortium");
    return;
  }

  store.dispatch(
    setCurrentConsortium({
      ...currentConsortium,
      [property]: reducer(currentConsortium[property]),
    })
  );
}

const queries = {
  listNodes,
  nodeIdExists,
  getNextNodeId,
  addUserToGroup,
  forceConfirmUser,
  getConsortiumGQL: getConsortium,
  setDefaultProgram,
  createIoTConsortium,
  shallowUpdateConsortium,
};

export default queries;
