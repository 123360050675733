export const getProgram = /* GraphQL */ `
  query DeepGetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      title
      isRunning
      nodesOpenings {
        items {
          id
          nodeProgram
          node {
            id
            idxSensor
            title
          }
          createdAt
          updatedAt
          nodeOpeningsNodeId
        }
      }
      consortiumID
      createdAt
      updatedAt
    }
  }
`;

export const getNodeSortedManager = /* GraphQL */ `
  query GetNodeSortedManager(
    $id: ID!
    $sortDirection: ModelSortDirection
    $from: String
    $to: String
  ) {
    getNode(id: $id) {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      enabledSensors
      status
      valveStatus
      flowState
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory(
        sortDirection: $sortDirection
        limit: 300
        createdAt: { between: [$from, $to] }
      ) {
        items {
          id
          nodeID
          battery
          temperature
          temperatureGround
          tensiometer1
          tensiometer2
          tensiometer3
          waterMark1
          waterMark2
          waterMark3
          waterMark4
          pluvioMeter
          flowState
          humidity
          signal
          createdAt
        }
      }
      lastLogs(
        sortDirection: $sortDirection
        limit: 100
        createdAt: { between: [$from, $to] }
      ) {
        items {
          id
          nodeID
          rawMessage
          ttl
          createdAt
        }
      }
      notifications(sortDirection: $sortDirection) {
        items {
          id
          priority
          message
          status
          consortiumID
          nodeID
          createdAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;

// needed to get only medium/base notifications
export const getNodeSortedBase = /* GraphQL */ `
  query GetNodeSortedBase(
    $id: ID!
    $sortDirection: ModelSortDirection
    $from: String
    $to: String
  ) {
    getNode(id: $id) {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      enabledSensors
      signal
      status
      valveStatus
      flowState
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory(
        sortDirection: $sortDirection
        limit: 300
        createdAt: { between: [$from, $to] }
      ) {
        items {
          id
          nodeID
          battery
          temperature
          temperatureGround
          tensiometer1
          tensiometer2
          tensiometer3
          waterMark1
          waterMark2
          waterMark3
          waterMark4
          pluvioMeter
          flowState
          humidity
          signal
          createdAt
        }
      }
      lastLogs(
        sortDirection: $sortDirection
        limit: 100
        createdAt: { between: [$from, $to] }
      ) {
        items {
          id
          nodeID
          rawMessage
          ttl
          createdAt
        }
      }
      notifications(
        sortDirection: $sortDirection
        filter: {
          or: [{ priority: { eq: MEDIUM } }, { priority: { eq: LOW } }]
        }
      ) {
        items {
          id
          priority
          message
          status
          consortiumID
          nodeID
          createdAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listNodesShallow = /* GraphQL */ `
  query ListNodesShallow($id: ID!) {
    getConsortium(id: $id) {
      nodes {
        items {
          id
          idxSensor
          title
          lat
          lng
          battery
          temperature
          temperatureGround
          pluvioMeter
          tensiometer1
          tensiometer2
          tensiometer3
          waterMark1
          waterMark2
          waterMark3
          waterMark4
          humidity
          signal
          status
          valveStatus
          lastValveCommand
          nodeType
          isLogEnabled
          isOpeningOngoing
          openingStartTime
          isClosingOngoing
          closingStartTime
          isDataOngoing
          dataStartTime
          consortiumID
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getConsortiumSortedManager = /* GraphQL */ `
  query GetConsortiumSortedManager(
    $id: ID!
    $sortDirection: ModelSortDirection
  ) {
    getConsortium(id: $id) {
      id
      name
      lat
      lng
      contact
      nodes {
        items {
          id
          idxSensor
          title
          lat
          lng
          battery
          temperature
          temperatureGround
          pluvioMeter
          tensiometer1
          tensiometer2
          tensiometer3
          waterMark1
          waterMark2
          waterMark3
          waterMark4
          humidity
          enabledSensors
          signal
          status
          valveStatus
          lastValveCommand
          nodeType
          isLogEnabled
          isOpeningOngoing
          openingStartTime
          isClosingOngoing
          closingStartTime
          isDataOngoing
          dataStartTime
          consortiumID
          createdAt
          updatedAt
        }
      }
      programs {
        items {
          id
          title
          isRunning
          consortiumID
          createdAt
          updatedAt
        }
      }
      notifications(sortDirection: $sortDirection) {
        items {
          id
          priority
          message
          status
          createdAt
          updatedAt
          consortiumID
          nodeID
        }
      }
      alarms {
        items {
          id
          consortiumID
          name
          property
          operator
          value
          currentlyFiringNodes
          createdAt
          updatedAt
        }
      }
      endUsers {
        items {
          id
          firstName
          lastName
          userType
          email
          consortiumID
          createdAt
        }
      }
      defaultProgram {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      consortiumDefaultProgramId
    }
  }
`;

export const getConsortiumSortedBase = /* GraphQL */ `
  query GetConsortiumSortedBase($id: ID!, $sortDirection: ModelSortDirection) {
    getConsortium(id: $id) {
      id
      name
      lat
      lng
      contact
      nodes {
        items {
          id
          idxSensor
          title
          lat
          lng
          battery
          temperature
          temperatureGround
          pluvioMeter
          tensiometer1
          tensiometer2
          tensiometer3
          waterMark1
          waterMark2
          waterMark3
          waterMark4
          humidity
          signal
          status
          enabledSensors
          valveStatus
          lastValveCommand
          nodeType
          isLogEnabled
          isOpeningOngoing
          openingStartTime
          isClosingOngoing
          closingStartTime
          isDataOngoing
          dataStartTime
          consortiumID
          createdAt
          updatedAt
        }
      }
      programs {
        items {
          id
          title
          isRunning
          consortiumID
          createdAt
          updatedAt
        }
      }
      notifications(
        sortDirection: $sortDirection
        filter: {
          or: [{ priority: { eq: MEDIUM } }, { priority: { eq: LOW } }]
        }
      ) {
        items {
          id
          priority
          message
          status
          createdAt
          updatedAt
          consortiumID
          nodeID
        }
      }
      alarms {
        items {
          id
          consortiumID
          name
          property
          operator
          value
          currentlyFiringNodes
          createdAt
          updatedAt
        }
      }
      defaultProgram {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      consortiumDefaultProgramId
    }
  }
`;

// export const historyByNode = /* GraphQL */ `
//   query HistoryByNode(
//     $nodeID: ID!
//     $createdAt: ModelStringKeyConditionInput
//     $sortDirection: ModelSortDirection
//     $filter: ModelNodeDataRecordFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     historyByNode(
//       nodeID: $nodeID
//       createdAt: $createdAt
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         battery
//         temperature
//         temperatureGround
//         pluvioMeter
//         tensiometer1
//         tensiometer2
//         tensiometer3
//         waterMark1
//         waterMark2
//         waterMark3
//         waterMark4
//         humidity
//         signal
//         createdAt
//       }
//     }
//   }
// `;
