import { Alert, AlertColor } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import {
  LongPressCallbackMeta,
  LongPressReactEvents,
  useLongPress,
} from "use-long-press";
import { RootState } from "../store";

export default function useShowMessage() {
  const { enqueueSnackbar } = useSnackbar();

  return (message: string, type: AlertColor = "success") => {
    enqueueSnackbar("", {
      content: (
        <Alert elevation={10} severity={type}>
          {message}
        </Alert>
      ),
      autoHideDuration: 3000,
      anchorOrigin: { horizontal: "center", vertical: "top" },
    });
  };
}

export function useRoleChecker() {
  const { userGroups } = useSelector((state: RootState) => state.appConfig)!;
  return (role?: string) =>
    !role || userGroups!.includes(role) || userGroups?.includes("admin");
}

export function usePress(
  options: Omit<Parameters<typeof useLongPress>[1], "onCancel" | "onStart"> & {
    onLongPress: NonNullable<Parameters<typeof useLongPress>[0]>;
    onPress: (payload: any) => void;
  }
) {
  const { onLongPress, onPress, ...actualOptions } = options;
  const [hasLongPress, setHasLongPress] = useState(false);

  const onCancel = useCallback(() => {
    if (hasLongPress) {
      setHasLongPress(false);
    }
  }, [hasLongPress]);

  const bind = useLongPress(
    useCallback(
      (
        event: LongPressReactEvents<Element>,
        meta: LongPressCallbackMeta<unknown>
      ) => {
        setHasLongPress(true);
        onLongPress(event, meta);
      },
      [onLongPress]
    ),
    {
      ...actualOptions,
      onCancel,
      onStart: onCancel,
    }
  );

  return useCallback(
    (payload) => ({
      ...bind(),
      onClick: () => {
        if (!hasLongPress) {
          onPress(payload);
        }
      },
    }),
    [bind, hasLongPress, onPress]
  );
}
