import { BounceLoader } from "react-spinners";

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

export default function BlurLoader({ isLoading, children }: Props) {
  return (
    <>
      {isLoading && (
        <BounceLoader
          color="#00AB55"
          speedMultiplier={1.5}
          css="position: fixed; top: 50%; left: calc(50% - 30px); z-index: 9999;"
        />
      )}
      <div
        style={{
          filter: isLoading ? "blur(5px) grayscale(1)" : "none",
          pointerEvents: isLoading ? "none" : "auto",
          transition: "filter 0.1s",
        }}
      >
        {children}
      </div>
    </>
  );
}
