import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------
type LogoProps = {
  sx?: SxProps<Theme>;
};

export default function Logo({ sx }: LogoProps) {
  // make sure on mobile the logo is not too big

  return (
    <Box
      component="img"
      src="/logo.png"
      sx={{ width: [100, 160], mt: ["17px", 0], ...sx }}
    />
  );
}
