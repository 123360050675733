import { Amplify, Auth, I18n } from "aws-amplify";
import { setDefaultOptions } from "date-fns";
import it from "date-fns/locale/it";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import awsExports from "./aws-exports";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import { store } from "./store";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

import "c3/c3.css";
import "simplebar/dist/simplebar.min.css";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { setCurrentConsortium } from "./store/appConfigSlice";
import { getConsortium } from "./store/queries";

setDefaultOptions({ locale: it });

I18n.setLanguage("it");
Amplify.configure(awsExports);

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});
function App() {
  return (
    <ThemeConfig>
      <SnackbarProvider maxSnack={3}>
        <ConfirmProvider>
          <ScrollToTop />
          <GlobalStyles />
          <Router />
        </ConfirmProvider>
      </SnackbarProvider>
    </ThemeConfig>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);

window.addEventListener("visibilitychange", async () => {
  if (document.visibilityState === "visible") {
    // check if current path is /admin, then reload the page, otherwise only reload current consortium
    if (window.location.pathname === "/admin") {
      // window.location.reload();
    } else if (window.location.pathname.includes("dashboard/")) {
      const consortiumId = store.getState().appConfig.currentConsortium?.id;
      console.log("Reloading consortium", consortiumId);
      store.dispatch(setCurrentConsortium(await getConsortium(consortiumId!)));
    }
  }
});

if (!("serviceWorker" in navigator)) {
  alert("Installa l'applicazione sul tuo dispositivo per ricevere notifiche.");
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
