/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { Icon } from "@iconify/react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as datefns from "date-fns";
import { memo } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link } from "react-router-dom";
import { Node, NodeStatus } from "../../../API";
import { sendMessage } from "../../../graphql/queries";
import { gqlOperation } from "../../../store/queries";
import { tensioMeterToColor, waterMarkToColor } from "../../../utils";
import useShowMessage, { useRoleChecker } from "../../../utils/hooks";
import Scrollbar from "../../Scrollbar";
import { SENSOR_LABELS, SENSOR_UNITS } from "../../../utils/constants";

type Props = {
  node: Node;
  hideView?: boolean;
};

export const StatStyle = styled(Card)(({ theme }: any) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2),
  height: 76,
  width: "100%",
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
}));

/*
Tensiometri: 
-100<Celeste
-100>Verde<-500
-500>Giallo<-700
-700>Rosso<-1000



WM:
0-10 Blu
10-30 Verde
30-60 Giallo
60-100 Arancio  
100-200 Rosso
*/

// check if property is tensiometer or watermark, colorize it

const SingleNodeView = memo(({ node, hideView: isNodePage }: Props) => {
  const isForUser = useRoleChecker();
  const showMessage = useShowMessage();

  return !isNodePage ? (
    <Button variant="outlined">
      <Link
        to={`/dashboard/nodes/${node.id}`}
        style={{ textDecoration: "none", fontSize: 14 }}
      >
        <Icon icon="eva:eye-fill" fontSize="inherit" /> Pagina nodo
      </Link>
    </Button>
  ) : (
    <Card elevation={10}>
      <CardHeader
        title={`${node.idxSensor} - ${node.title}`}
        subheader={
          <>
            ID: <b style={{ marginRight: 5 }}>{node.id}</b>| Tipo nodo:{" "}
            <b>{node.nodeType === "SENSOR" ? "Sensore" : "Attuatore"}</b> |
            Ultima comunicazione:{" "}
            <b>
              {node.updatedAt === node.createdAt
                ? "-"
                : `${datefns.formatDistanceToNow(new Date(node.updatedAt!), {
                    includeSeconds: true,
                  })}`}
            </b>{" "}
            | Stato:{" "}
            {node.status === NodeStatus.ERROR && (
              <>
                <b style={{ color: "red" }}>Nodo in Errore</b> |{" "}
              </>
            )}
            <b>
              {!(
                node.isDataOngoing ||
                node.isClosingOngoing ||
                node.isOpeningOngoing
              ) && `Nessuna comunicazione in corso`}
            </b>
            <b>
              {node.isDataOngoing &&
                ` | RD in attesa da ${datefns.formatDistanceToNow(
                  new Date(node.dataStartTime!)
                )}`}
            </b>
            <b>
              {node.isOpeningOngoing &&
                ` | RA in attesa da ${datefns.formatDistanceToNow(
                  new Date(node.openingStartTime!)
                )}`}
            </b>
            <b>
              {node.isClosingOngoing &&
                ` | RC in attesa da ${datefns.formatDistanceToNow(
                  new Date(node.closingStartTime!)
                )}`}
            </b>
          </>
        }
        // action={
        //   <IconButton onClick={onClose} color="primary" size="medium">
        //     <Icon icon="bi:x-lg" />
        //   </IconButton>
        // }
      />
      <Divider sx={{ mt: 3 }} />
      <CardContent>
        <Scrollbar style={{ maxHeight: 400, paddingRight: 10 }}>
          <Stack direction="row" justifyContent="space-around">
            {/* <Typography>Comandi Manuali</Typography> */}
            {isForUser("manager") && (
              <ButtonGroup variant="outlined" size="large" fullWidth>
                <NodeManualOperationButton
                  node={node}
                  isLoading={node.isDataOngoing}
                  startTime={node.dataStartTime}
                  onClick={async () => {
                    const response = await gqlOperation(sendMessage, {
                      nodeID: node.id,
                      action: "requestData",
                      consortiumID: node.consortiumID,
                    });
                    showMessage("Richiesta dati inviata con successo.");
                  }}
                >
                  Richiesta Dati
                </NodeManualOperationButton>
                {node.nodeType === "VALVE" && (
                  <>
                    <NodeManualOperationButton
                      node={node}
                      isLoading={node.isOpeningOngoing}
                      startTime={node.openingStartTime}
                      onClick={async () => {
                        const response = await gqlOperation(sendMessage, {
                          nodeID: node.id,
                          action: "open",
                          consortiumID: node.consortiumID,
                        });
                        showMessage("Richiesta apertura inviata con successo.");
                      }}
                    >
                      Apri
                    </NodeManualOperationButton>
                    <NodeManualOperationButton
                      node={node}
                      isLoading={node.isClosingOngoing}
                      startTime={node.closingStartTime}
                      onClick={async () => {
                        const response = await gqlOperation(sendMessage, {
                          nodeID: node.id,
                          action: "close",
                          consortiumID: node.consortiumID,
                        });
                        showMessage("Richiesta chiusura inviata con successo.");
                      }}
                    >
                      Chiudi
                    </NodeManualOperationButton>
                  </>
                )}
                {/* <LoadingButton
                variant="outlined"
                color="error"
                startIcon={<Icon icon="dashicons:trash" />}
                sx={{ width: "200px" }}
                onClick={async () => {
                  const response = await gqlOperation(deleteNode, {
                    input: { id: node.id },
                  });
                }}
              >
                Elimina Nodo
              </LoadingButton> */}
              </ButtonGroup>
            )}
          </Stack>
          <Divider sx={{ mt: 2 }}>
            {node.nodeType === "VALVE" && (
              <Chip
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      icon="mdi:pipe-valve"
                      width="20"
                      style={{ marginRight: 10 }}
                    />
                    <Typography variant="caption">
                      Stato Valvola (ACK): {node.lastValveCommand ?? "-"} |
                      Stato Flussostato: {node.valveStatus ?? "-"}
                    </Typography>
                  </div>
                }
                color="secondary"
                variant="outlined"
                // sx={{ fontSize: 12, padding:  }}
              />
            )}
          </Divider>
          <Grid container spacing={1} marginTop={0}>
            {(
              (node.enabledSensors ||
                Object.keys(SENSOR_LABELS)) as (keyof typeof SENSOR_LABELS)[]
            ).map((sensorKey) => {
              let color;
              if (sensorKey.includes("tensiometer")) {
                color = tensioMeterToColor(node[sensorKey] as number);
              } else if (sensorKey.includes("waterMark")) {
                color = waterMarkToColor(node[sensorKey] as number);
              }
              return (
                <Grid key={sensorKey} item xs={4} md={4} lg={4}>
                  <StatStyle sx={{ background: color, height: "auto" }}>
                    <Typography variant="h6">
                      {node[sensorKey] ?? "-"}
                      {SENSOR_UNITS[sensorKey]}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ opacity: 0.72 }}
                      fontSize={[13]}
                    >
                      {SENSOR_LABELS[sensorKey]}
                    </Typography>
                  </StatStyle>
                </Grid>
              );
            })}
            {/* {renderSingleSensorValue(node, `${renderValue(node.battery)}%`)}
            {singleStat(
              "signal",
              "Segnale",
              `${renderValue(node.signal)} rssi`
            )}
            {singleStat(
              "temperature",
              "Aria",
              `${renderValue(node.temperature)}°C`
            )}
            {singleStat(
              "temperatureGround",
              "Terreno",
              `${node.temperatureGround || "-"}°C`
            )}
            {singleStat("humidity", "Umidità", `${node.humidity || "-"}%`)}
            {singleStat(
              "tensiometer1",
              "T1",
              `${renderValue(node.tensiometer1)} hPa`
            )}
            {singleStat(
              "tensiometer2",
              "T2",
              `${renderValue(node.tensiometer2)} hPa`
            )}
            {singleStat(
              "tensiometer3",
              "T3",
              `${renderValue(node.tensiometer3)} hPa`
            )}
            {singleStat(
              "waterMark1",
              "WM 1",
              `${renderValue(node.waterMark1)} kPa`
            )}
            {singleStat(
              "waterMark2",
              "WM 2",
              `${renderValue(node.waterMark2)} kPa`
            )}
            {singleStat(
              "waterMark3",
              "WM 3",
              `${renderValue(node.waterMark3)} kPa`
            )}
            {singleStat(
              "waterMark4",
              "WM 4",
              `${renderValue(node.waterMark4)} kPa`
            )} */}
          </Grid>
        </Scrollbar>
      </CardContent>
    </Card>
  );
});

function NodeManualOperationButton({
  node,
  isLoading,
  startTime,
  children,
  onClick,
}: {
  node: Node;
  isLoading?: boolean;
  startTime?: string | null;
  children: any;
  onClick?: () => void;
}) {
  const waitTime =
    Math.max(
      300 -
        datefns.differenceInSeconds(new Date(), datefns.parseISO(startTime!)),
      0
    ) || 0;
  return (
    <Button
      startIcon={
        isLoading && (
          <CountdownCircleTimer
            isPlaying
            duration={waitTime}
            colors={[
              ["#A30000", 0.33],
              ["#004777", 0.33],
              ["#F7B801", 0.33],
            ]}
            strokeWidth={2}
            size={55}
          >
            {({ remainingTime }) => <span>{remainingTime}</span>}
          </CountdownCircleTimer>
        )
      }
      disabled={isLoading && waitTime > 0}
      sx={{ height: isLoading ? "60px" : "auto", fontSize: 13 }}
      onClick={onClick}
    >
      <div>{children}</div>
    </Button>
  );
}

export default SingleNodeView;
