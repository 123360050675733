import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as datefns from "date-fns";
import { useEffect, useState } from "react";
import {
  Consortium,
  CreateNotificationMutation,
  CreateNotificationMutationVariables,
  DeleteNotificationMutation,
  Notification,
  NotificationPriority,
  NotificationStatus,
  NotificationsByStatusQuery,
  NotificationsByStatusQueryVariables,
} from "../../../API";
import { PRIORITY_LABELS } from "../../../components/_dashboard/notifications/NotificationsList";
import { notificationsByStatus } from "../../../graphql/queries";
import { gqlOperation } from "../../../store/queries";
import { DATETIME_FORMAT_UI } from "../../../utils/constants";
import {
  createNotification,
  deleteNotification,
} from "../../../graphql/mutations";
import useShowMessage from "../../../utils/hooks";

const columns: GridColDef[] = [
  {
    field: "message",
    headerName: "Messaggio",
    align: "center",
    headerAlign: "center",
    minWidth: 300,
    flex: 2,
  },

  {
    field: "createdAt",
    headerName: "Data creazione",
    align: "center",
    headerAlign: "center",
    width: 150,
    renderCell: ({ row }) =>
      datefns.format(new Date(row.createdAt), DATETIME_FORMAT_UI),
  },
  // {
  //   field: "consortiumID",
  //   headerName: "Consorzio",
  //   align: "center",
  //   headerAlign: "center",
  //   width: 150,
  //   renderCell: ({ row }) => row.consortiumID,
  // },
  {
    field: "priority",
    headerName: "Priorità",
    align: "center",
    headerAlign: "center",
    width: 100,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        color={row.priority === NotificationPriority.HIGH ? "error" : "warning"}
        label={PRIORITY_LABELS[row.priority as NotificationPriority]}
      />
    ),
  },
];

interface Props {
  consortiums: { [key: string]: Consortium };
}

export default function CardGlobalNotifications({ consortiums }: Props) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    gqlOperation(
      notificationsByStatus as NotificationsByStatusQuery,
      {
        status: NotificationStatus.UNREAD,
        filter: {
          priority: { eq: NotificationPriority.HIGH },
        },
      } as NotificationsByStatusQueryVariables
    )
      .then((res) => {
        setNotifications(
          (res?.notificationsByStatus?.items as Notification[]) || []
        );
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Notifiche Globali ({notifications.length})
        </Typography>
      </Box>
      <Card>
        <CardHeader subheader="Qui puoi visualizzare le notifiche globali di alta priorità" />
        <CardContent>
          <DataGrid
            loading={loading}
            rows={notifications}
            columns={[
              {
                field: "consortiumID",
                headerName: "Consorzio",
                align: "center",
                headerAlign: "center",
                width: 150,
                renderCell: ({ row }) => (
                  <>
                    <Button
                      sx={{ flex: 2 }}
                      startIcon={<Icon icon="el:dashboard" />}
                      onClick={() => {
                        // save consortium id in local storage
                        localStorage.setItem("consortiumId", row.consortiumID);
                        // navigate to dashboard force reload
                        window.location.href = "/dashboard";
                      }}
                    >
                      {consortiums[row.consortiumID]?.name || "N/D"}
                    </Button>

                    <Button
                      sx={{ flex: 2 }}
                      startIcon={<Icon icon="clarity:check-line" />}
                      onClick={async () => {
                        setLoading(true);
                        try {
                          // First create a new notification with the same message and priority but with marked as read
                          await gqlOperation(
                            createNotification as CreateNotificationMutation,
                            {
                              input: {
                                message: row.message,
                                priority: row.priority,
                                status: NotificationStatus.READ,
                                consortiumID: row.consortiumID,
                                nodeID: row.nodeID,
                              },
                            } as CreateNotificationMutationVariables
                          );
                          // Then delete the notification
                          await gqlOperation(
                            deleteNotification as DeleteNotificationMutation,
                            {
                              input: { id: row.id },
                            }
                          );
                          setNotifications(
                            notifications.filter((n) => n.id !== row.id)
                          );
                        } catch (e) {
                          showMessage(
                            "Errore durante l'eliminazione della notifica",
                            "error"
                          );
                          console.error(e);
                        }
                        setLoading(false);
                      }}
                    />
                  </>
                ),
              },
              ...columns,
            ]}
            pageSize={30}
            disableColumnFilter
            disableColumnMenu
            autoHeight
            disableColumnSelector
            disableSelectionOnClick
            disableDensitySelector
            showCellRightBorder
            scrollbarSize={2}
            disableExtendRowFullWidth
          />
        </CardContent>
      </Card>
    </Box>
  );
}
