/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import type {
  Consortium,
  CreateConsortiumMutation,
  CreateConsortiumMutationVariables,
  UpdateConsortiumMutation,
  UpdateConsortiumMutationVariables,
} from "../../../API";
import MapLocationSelector from "../../../components/_dashboard/map/MapLocationSelector";
import { createConsortium, updateConsortium } from "../../../graphql/mutations";
import {
  addUserToGroup,
  createIoTConsortium,
  deleteConsortium,
  forceConfirmUser,
  getUser,
  gqlOperation,
} from "../../../store/queries";
import useShowMessage from "../../../utils/hooks";

interface Props {
  outlinedObject?: Partial<Consortium>;
  open: boolean;
  onClose: any;
}

interface IoTConsortiumResponse {
  message: string;
  consortiumId: string;
  email: string;
  password: string;
  certificate: {
    certificateArn: string;
    certificateId: string;
    certificatePem: string;
    keyPair: {
      PublicKey: string;
      PrivateKey: string;
    };
  };
}

export default function NewConsortiumDrawer({
  outlinedObject,
  open,
  onClose,
}: Props) {
  const showMessage = useShowMessage();
  const [response, setResponse] = useState<IoTConsortiumResponse | undefined>();
  const ConsortiumSchema = Yup.object().shape({
    name: Yup.string().required("La denominazione è obbligatoria"),
    contact: Yup.string()
      .required("Inserire un contatto di riferimento")
      .email("Inserire un indirizzo email valido"),
    lat: Yup.number().required("Inserire la latitudine del gateway"),
    lng: Yup.number().required("Inserire la longitudine del gateway"),
  });

  const formik = useFormik<Clear<Consortium>>({
    initialValues: {
      name: "",
      contact: "",
      lat: 46.4263191,
      lng: 10.8710145,
      ...outlinedObject,
    },
    validationSchema: ConsortiumSchema,
    onSubmit: async (values, actions) => {
      console.log("Submitted  form", values);

      // console.log("created consortium", created);
      let createdId;
      if (values.id) {
        await gqlOperation(
          updateConsortium as UpdateConsortiumMutation,
          {
            input: {
              id: values.id,
              name: values.name,
              lat: values.lat,
              lng: values.lng,
            },
          } as UpdateConsortiumMutationVariables
        );
      } else {
        try {
          // check if user exists
          const foundUser = await getUser(values.contact);
          if (foundUser) {
            showMessage(
              `L'utente ${values.contact} esiste già. Non è possibile creare un consorzio con lo stesso contatto`,
              "error"
            );
            return;
          }

          showMessage("Creazione risorse consorzio...", "info");

          const password = Math.random().toString(36).slice(-8);
          // create cognito user
          const created = await gqlOperation(
            createConsortium as CreateConsortiumMutation,
            {
              input: {
                name: values.name,
                contact: values.contact,
                lat: values.lat,
                lng: values.lng,
              },
            } as CreateConsortiumMutationVariables
          );
          createdId = created?.createConsortium?.id!;
          await Auth.signUp({
            username: values.contact,
            password,
            attributes: {
              name: values.name,
              "custom:consortiumID": createdId,
            },
          });

          await addUserToGroup(values.contact, "manager");
          await forceConfirmUser(values.contact);

          const consortiumResults = await createIoTConsortium(createdId);
          console.log("IoT resources: ", consortiumResults);
          setResponse({
            ...consortiumResults,
            createdId,
            email: values.contact,
            password,
          });
        } catch (err) {
          console.error(err);

          showMessage(`Error creazione risorse consorzio ${err}`, "error");
          // if anything went wrong, delete the consortium
          if (createdId) await deleteConsortium(createdId);
          return;
        }
      }

      showMessage("Consorzio memorizzato con successo", "success");
      formik.setSubmitting(false);
      formik.resetForm();
      onClose();
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    formik;

  const [isGeoWorking, setIsGeoWorking] = useState(false);
  return response ? (
    <Dialog onClose={() => setResponse(undefined)} open maxWidth="lg">
      <CardHeader
        title="Chiavi crittografiche per consorzio"
        subheader="Queste chiavi permettono al Gateway di comunicare con il sistema. Alla chiusura di questa finestra, questi dati non saranno più accessibili. Copiare ed inserire questi dati nel codice del Gateway."
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider>Codice firmware con certificati per il consorzio</Divider>
            <TextField
              // label="Certificato PEM"
              multiline
              fullWidth
              maxRows={10}
              // style={{}}
              InputProps={{
                style: { fontSize: 11, background: "black", color: "white" },
              }}
              value={fimwareTemplate(response)}
            />
            <Button
              style={{ marginTop: 5 }}
              variant="contained"
              color="primary"
              startIcon={<Icon icon="bi:clipboard" />}
              onClick={() => {
                navigator.clipboard.writeText(fimwareTemplate(response));
              }}
            >
              Copia negli appunti
            </Button>
            <Divider style={{ marginTop: 10 }} variant="middle">
              Credenziali accesso portale
            </Divider>
            <Typography variant="caption">
              <b>Attenzione:</b> le credenziali sono visibili solo una volta.
            </Typography>
            <TextField
              fullWidth
              size="small"
              multiline
              value={`Email: ${response.email} Password: ${response.password}`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Dialog>
  ) : (
    <Drawer variant="temporary" anchor="right" open={open} keepMounted={false}>
      <CardHeader
        title="Nuovo Consorzio"
        subheader="Inserire il nome del consorzio e il contatto di riferimento"
        action={
          <IconButton onClick={onClose} color="primary" size="medium">
            <Icon icon="bi:x-lg" />
          </IconButton>
        }
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Denominazione"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                label="Contatto"
                {...getFieldProps("contact")}
                error={Boolean(touched.contact && errors.contact)}
                helperText={touched.contact && errors.contact}
                disabled={!!outlinedObject?.contact}
              />
              <Stack direction="row" spacing={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Latitudine"
                  {...getFieldProps("lat")}
                  error={Boolean(touched.lat && errors.lat)}
                  helperText={touched.lat && errors.lat}
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Longitudine"
                  {...getFieldProps("lng")}
                  error={Boolean(touched.lng && errors.lng)}
                  helperText={touched.lng && errors.lng}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                onClick={() => {
                  setIsGeoWorking(true);

                  navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                      formik.setFieldValue("lat", position.coords.latitude);
                      formik.setFieldValue("lng", position.coords.longitude);
                      setIsGeoWorking(false);
                      showMessage("Posizione caricata correttamente.");
                    },
                    (error: GeolocationPositionError) => {
                      showMessage(
                        `Impossibile caricare posizione GPS: ${error.message}`,
                        "error"
                      );
                      setIsGeoWorking(false);
                    }
                  );
                }}
                loading={isGeoWorking}
                startIcon={<Icon icon="bx:bx-current-location" />}
              >
                Carica posizione
              </LoadingButton>
              <MapLocationSelector
                position={{ lat: values.lat!, lng: values.lng! }}
                onMove={(map) => {
                  formik.setFieldValue("lat", map.getCenter().lat);
                  formik.setFieldValue("lng", map.getCenter().lng);
                }}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salva
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Drawer>
  );
}
function fimwareTemplate(response: IoTConsortiumResponse): string {
  return `// INIZIO CODICE GENERATO DA APP ABIOT 
#define CONSORTIUM_ID "${response.consortiumId}"
static const char AWS_CERT_CRT[] PROGMEM = R"KEY(
${response.certificate.certificatePem}
)KEY";

// Device Private Key
static const char AWS_CERT_PRIVATE[] PROGMEM = R"KEY(
${response.certificate.keyPair.PrivateKey}
)KEY";
// FINE CODICE GENERATO DA APP ABIOT`;
}
