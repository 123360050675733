/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const sendMessage = /* GraphQL */ `query SendMessage(
  $consortiumID: ID
  $nodeID: ID
  $action: String!
  $payload: String
) {
  sendMessage(
    consortiumID: $consortiumID
    nodeID: $nodeID
    action: $action
    payload: $payload
  )
}
` as GeneratedQuery<
  APITypes.SendMessageQueryVariables,
  APITypes.SendMessageQuery
>;
export const getConsortium = /* GraphQL */ `query GetConsortium($id: ID!) {
  getConsortium(id: $id) {
    id
    name
    lat
    lng
    contact
    nodes {
      items {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    programs {
      items {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    alarms {
      items {
        id
        consortiumID
        name
        property
        operator
        value
        currentlyFiringNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    endUsers {
      items {
        id
        firstName
        lastName
        email
        userType
        consortiumID
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    defaultProgram {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    gatewayLatestComm
    gatewayStatus
    gatewayBattery
    gatewaySignal
    gatewayConnectionType
    gatewayLocalTime
    gatewayLocalFirmware
    gatewayAutotest
    gatewaySolarTension
    gatewaySolarCurrent
    gatewayBatteryTension
    gatewayBatteryCurrent
    createdAt
    updatedAt
    consortiumDefaultProgramId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConsortiumQueryVariables,
  APITypes.GetConsortiumQuery
>;
export const listConsortiums = /* GraphQL */ `query ListConsortiums(
  $filter: ModelConsortiumFilterInput
  $limit: Int
  $nextToken: String
) {
  listConsortiums(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      lat
      lng
      contact
      nodes {
        nextToken
        __typename
      }
      programs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      alarms {
        nextToken
        __typename
      }
      endUsers {
        nextToken
        __typename
      }
      defaultProgram {
        id
        title
        isRunning
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      gatewayLatestComm
      gatewayStatus
      gatewayBattery
      gatewaySignal
      gatewayConnectionType
      gatewayLocalTime
      gatewayLocalFirmware
      gatewayAutotest
      gatewaySolarTension
      gatewaySolarCurrent
      gatewayBatteryTension
      gatewayBatteryCurrent
      createdAt
      updatedAt
      consortiumDefaultProgramId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConsortiumsQueryVariables,
  APITypes.ListConsortiumsQuery
>;
export const getNode = /* GraphQL */ `query GetNode($id: ID!) {
  getNode(id: $id) {
    id
    idxSensor
    title
    lat
    lng
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    humidity
    signal
    flowState
    enabledSensors
    status
    valveStatus
    lastValveCommand
    nodeType
    isLogEnabled
    isOpeningOngoing
    openingStartTime
    isClosingOngoing
    closingStartTime
    isDataOngoing
    dataStartTime
    consortiumID
    sensorsHistory {
      items {
        id
        nodeID
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        flowState
        humidity
        signal
        createdAt
        updatedAt
        ttl
        __typename
      }
      nextToken
      __typename
    }
    lastLogs {
      items {
        id
        nodeID
        rawMessage
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        priority
        message
        createdAt
        updatedAt
        consortiumID
        nodeID
        status
        ttl
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNodeQueryVariables, APITypes.GetNodeQuery>;
export const listNodes = /* GraphQL */ `query ListNodes(
  $filter: ModelNodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNodesQueryVariables, APITypes.ListNodesQuery>;
export const nodesByConsortium = /* GraphQL */ `query NodesByConsortium(
  $consortiumID: ID!
  $idxSensor: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNodeFilterInput
  $limit: Int
  $nextToken: String
) {
  nodesByConsortium(
    consortiumID: $consortiumID
    idxSensor: $idxSensor
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NodesByConsortiumQueryVariables,
  APITypes.NodesByConsortiumQuery
>;
export const getNodeDataRecord = /* GraphQL */ `query GetNodeDataRecord($id: ID!) {
  getNodeDataRecord(id: $id) {
    id
    nodeID
    battery
    temperature
    temperatureGround
    pluvioMeter
    tensiometer1
    tensiometer2
    tensiometer3
    waterMark1
    waterMark2
    waterMark3
    waterMark4
    flowState
    humidity
    signal
    createdAt
    updatedAt
    ttl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeDataRecordQueryVariables,
  APITypes.GetNodeDataRecordQuery
>;
export const listNodeDataRecords = /* GraphQL */ `query ListNodeDataRecords(
  $filter: ModelNodeDataRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodeDataRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nodeID
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      flowState
      humidity
      signal
      createdAt
      updatedAt
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNodeDataRecordsQueryVariables,
  APITypes.ListNodeDataRecordsQuery
>;
export const historyByNode = /* GraphQL */ `query HistoryByNode(
  $nodeID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNodeDataRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  historyByNode(
    nodeID: $nodeID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nodeID
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      flowState
      humidity
      signal
      createdAt
      updatedAt
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HistoryByNodeQueryVariables,
  APITypes.HistoryByNodeQuery
>;
export const getNodeStatisticsDaily = /* GraphQL */ `query GetNodeStatisticsDaily(
  $id: ID!
  $year: String!
  $month: String!
  $day: String!
) {
  getNodeStatisticsDaily(id: $id, year: $year, month: $month, day: $day) {
    id
    year
    month
    day
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeStatisticsDailyQueryVariables,
  APITypes.GetNodeStatisticsDailyQuery
>;
export const listNodeStatisticsDailies = /* GraphQL */ `query ListNodeStatisticsDailies(
  $id: ID
  $yearMonthDay: ModelNodeStatisticsDailyPrimaryCompositeKeyConditionInput
  $filter: ModelNodeStatisticsDailyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNodeStatisticsDailies(
    id: $id
    yearMonthDay: $yearMonthDay
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      year
      month
      day
      battery {
        min
        max
        avg
        count
        __typename
      }
      temperature {
        min
        max
        avg
        count
        __typename
      }
      temperatureGround {
        min
        max
        avg
        count
        __typename
      }
      pluvioMeter {
        min
        max
        avg
        count
        __typename
      }
      tensiometer1 {
        min
        max
        avg
        count
        __typename
      }
      tensiometer2 {
        min
        max
        avg
        count
        __typename
      }
      tensiometer3 {
        min
        max
        avg
        count
        __typename
      }
      waterMark1 {
        min
        max
        avg
        count
        __typename
      }
      waterMark2 {
        min
        max
        avg
        count
        __typename
      }
      waterMark3 {
        min
        max
        avg
        count
        __typename
      }
      waterMark4 {
        min
        max
        avg
        count
        __typename
      }
      humidity {
        min
        max
        avg
        count
        __typename
      }
      signal {
        min
        max
        avg
        count
        __typename
      }
      flowState {
        min
        max
        avg
        count
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNodeStatisticsDailiesQueryVariables,
  APITypes.ListNodeStatisticsDailiesQuery
>;
export const getNodeStatisticsMonthly = /* GraphQL */ `query GetNodeStatisticsMonthly($id: ID!, $year: String!, $month: String!) {
  getNodeStatisticsMonthly(id: $id, year: $year, month: $month) {
    id
    year
    month
    battery {
      min
      max
      avg
      count
      __typename
    }
    temperature {
      min
      max
      avg
      count
      __typename
    }
    temperatureGround {
      min
      max
      avg
      count
      __typename
    }
    pluvioMeter {
      min
      max
      avg
      count
      __typename
    }
    tensiometer1 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer2 {
      min
      max
      avg
      count
      __typename
    }
    tensiometer3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark1 {
      min
      max
      avg
      count
      __typename
    }
    waterMark2 {
      min
      max
      avg
      count
      __typename
    }
    waterMark3 {
      min
      max
      avg
      count
      __typename
    }
    waterMark4 {
      min
      max
      avg
      count
      __typename
    }
    humidity {
      min
      max
      avg
      count
      __typename
    }
    signal {
      min
      max
      avg
      count
      __typename
    }
    flowState {
      min
      max
      avg
      count
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeStatisticsMonthlyQueryVariables,
  APITypes.GetNodeStatisticsMonthlyQuery
>;
export const listNodeStatisticsMonthlies = /* GraphQL */ `query ListNodeStatisticsMonthlies(
  $id: ID
  $yearMonth: ModelNodeStatisticsMonthlyPrimaryCompositeKeyConditionInput
  $filter: ModelNodeStatisticsMonthlyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNodeStatisticsMonthlies(
    id: $id
    yearMonth: $yearMonth
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      year
      month
      battery {
        min
        max
        avg
        count
        __typename
      }
      temperature {
        min
        max
        avg
        count
        __typename
      }
      temperatureGround {
        min
        max
        avg
        count
        __typename
      }
      pluvioMeter {
        min
        max
        avg
        count
        __typename
      }
      tensiometer1 {
        min
        max
        avg
        count
        __typename
      }
      tensiometer2 {
        min
        max
        avg
        count
        __typename
      }
      tensiometer3 {
        min
        max
        avg
        count
        __typename
      }
      waterMark1 {
        min
        max
        avg
        count
        __typename
      }
      waterMark2 {
        min
        max
        avg
        count
        __typename
      }
      waterMark3 {
        min
        max
        avg
        count
        __typename
      }
      waterMark4 {
        min
        max
        avg
        count
        __typename
      }
      humidity {
        min
        max
        avg
        count
        __typename
      }
      signal {
        min
        max
        avg
        count
        __typename
      }
      flowState {
        min
        max
        avg
        count
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNodeStatisticsMonthliesQueryVariables,
  APITypes.ListNodeStatisticsMonthliesQuery
>;
export const getNodeOpenings = /* GraphQL */ `query GetNodeOpenings($id: ID!) {
  getNodeOpenings(id: $id) {
    id
    node {
      id
      idxSensor
      title
      lat
      lng
      battery
      temperature
      temperatureGround
      pluvioMeter
      tensiometer1
      tensiometer2
      tensiometer3
      waterMark1
      waterMark2
      waterMark3
      waterMark4
      humidity
      signal
      flowState
      enabledSensors
      status
      valveStatus
      lastValveCommand
      nodeType
      isLogEnabled
      isOpeningOngoing
      openingStartTime
      isClosingOngoing
      closingStartTime
      isDataOngoing
      dataStartTime
      consortiumID
      sensorsHistory {
        nextToken
        __typename
      }
      lastLogs {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nodeProgram
    programID
    createdAt
    updatedAt
    nodeOpeningsNodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeOpeningsQueryVariables,
  APITypes.GetNodeOpeningsQuery
>;
export const listNodeOpenings = /* GraphQL */ `query ListNodeOpenings(
  $filter: ModelNodeOpeningsFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodeOpenings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      node {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nodeProgram
      programID
      createdAt
      updatedAt
      nodeOpeningsNodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNodeOpeningsQueryVariables,
  APITypes.ListNodeOpeningsQuery
>;
export const nodeOpeningsByProgram = /* GraphQL */ `query NodeOpeningsByProgram(
  $programID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNodeOpeningsFilterInput
  $limit: Int
  $nextToken: String
) {
  nodeOpeningsByProgram(
    programID: $programID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      node {
        id
        idxSensor
        title
        lat
        lng
        battery
        temperature
        temperatureGround
        pluvioMeter
        tensiometer1
        tensiometer2
        tensiometer3
        waterMark1
        waterMark2
        waterMark3
        waterMark4
        humidity
        signal
        flowState
        enabledSensors
        status
        valveStatus
        lastValveCommand
        nodeType
        isLogEnabled
        isOpeningOngoing
        openingStartTime
        isClosingOngoing
        closingStartTime
        isDataOngoing
        dataStartTime
        consortiumID
        createdAt
        updatedAt
        __typename
      }
      nodeProgram
      programID
      createdAt
      updatedAt
      nodeOpeningsNodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NodeOpeningsByProgramQueryVariables,
  APITypes.NodeOpeningsByProgramQuery
>;
export const getProgram = /* GraphQL */ `query GetProgram($id: ID!) {
  getProgram(id: $id) {
    id
    title
    isRunning
    nodesOpenings {
      items {
        id
        nodeProgram
        programID
        createdAt
        updatedAt
        nodeOpeningsNodeId
        __typename
      }
      nextToken
      __typename
    }
    consortiumID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProgramQueryVariables,
  APITypes.GetProgramQuery
>;
export const listPrograms = /* GraphQL */ `query ListPrograms(
  $filter: ModelProgramFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProgramsQueryVariables,
  APITypes.ListProgramsQuery
>;
export const programsByConsortium = /* GraphQL */ `query ProgramsByConsortium(
  $consortiumID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProgramFilterInput
  $limit: Int
  $nextToken: String
) {
  programsByConsortium(
    consortiumID: $consortiumID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      isRunning
      nodesOpenings {
        nextToken
        __typename
      }
      consortiumID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProgramsByConsortiumQueryVariables,
  APITypes.ProgramsByConsortiumQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    priority
    message
    createdAt
    updatedAt
    consortiumID
    nodeID
    status
    ttl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      priority
      message
      createdAt
      updatedAt
      consortiumID
      nodeID
      status
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const notificationsByPriority = /* GraphQL */ `query NotificationsByPriority(
  $priority: NotificationPriority!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByPriority(
    priority: $priority
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      priority
      message
      createdAt
      updatedAt
      consortiumID
      nodeID
      status
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByPriorityQueryVariables,
  APITypes.NotificationsByPriorityQuery
>;
export const notificationsByConsortium = /* GraphQL */ `query NotificationsByConsortium(
  $consortiumID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByConsortium(
    consortiumID: $consortiumID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      priority
      message
      createdAt
      updatedAt
      consortiumID
      nodeID
      status
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByConsortiumQueryVariables,
  APITypes.NotificationsByConsortiumQuery
>;
export const notificationsByNode = /* GraphQL */ `query NotificationsByNode(
  $nodeID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByNode(
    nodeID: $nodeID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      priority
      message
      createdAt
      updatedAt
      consortiumID
      nodeID
      status
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByNodeQueryVariables,
  APITypes.NotificationsByNodeQuery
>;
export const notificationsByStatus = /* GraphQL */ `query NotificationsByStatus(
  $status: NotificationStatus!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      priority
      message
      createdAt
      updatedAt
      consortiumID
      nodeID
      status
      ttl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByStatusQueryVariables,
  APITypes.NotificationsByStatusQuery
>;
export const getNodeLog = /* GraphQL */ `query GetNodeLog($id: ID!) {
  getNodeLog(id: $id) {
    id
    nodeID
    rawMessage
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeLogQueryVariables,
  APITypes.GetNodeLogQuery
>;
export const listNodeLogs = /* GraphQL */ `query ListNodeLogs(
  $filter: ModelNodeLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodeLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nodeID
      rawMessage
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNodeLogsQueryVariables,
  APITypes.ListNodeLogsQuery
>;
export const logsByNode = /* GraphQL */ `query LogsByNode(
  $nodeID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNodeLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByNode(
    nodeID: $nodeID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nodeID
      rawMessage
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogsByNodeQueryVariables,
  APITypes.LogsByNodeQuery
>;
export const getAlarm = /* GraphQL */ `query GetAlarm($id: ID!) {
  getAlarm(id: $id) {
    id
    consortiumID
    name
    property
    operator
    value
    currentlyFiringNodes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAlarmQueryVariables, APITypes.GetAlarmQuery>;
export const listAlarms = /* GraphQL */ `query ListAlarms(
  $filter: ModelAlarmFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      consortiumID
      name
      property
      operator
      value
      currentlyFiringNodes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlarmsQueryVariables,
  APITypes.ListAlarmsQuery
>;
export const alarmsByConsortium = /* GraphQL */ `query AlarmsByConsortium(
  $consortiumID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlarmFilterInput
  $limit: Int
  $nextToken: String
) {
  alarmsByConsortium(
    consortiumID: $consortiumID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      consortiumID
      name
      property
      operator
      value
      currentlyFiringNodes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlarmsByConsortiumQueryVariables,
  APITypes.AlarmsByConsortiumQuery
>;
export const notificationSubscribersByConsortium = /* GraphQL */ `query NotificationSubscribersByConsortium(
  $consortiumID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationSubscriberFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationSubscribersByConsortium(
    consortiumID: $consortiumID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      subscription
      userGroup
      consortiumID
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationSubscribersByConsortiumQueryVariables,
  APITypes.NotificationSubscribersByConsortiumQuery
>;
export const getEndUser = /* GraphQL */ `query GetEndUser($id: ID!) {
  getEndUser(id: $id) {
    id
    firstName
    lastName
    email
    userType
    consortiumID
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEndUserQueryVariables,
  APITypes.GetEndUserQuery
>;
export const listEndUsers = /* GraphQL */ `query ListEndUsers(
  $filter: ModelEndUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listEndUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      userType
      consortiumID
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEndUsersQueryVariables,
  APITypes.ListEndUsersQuery
>;
export const endUsersByConsortium = /* GraphQL */ `query EndUsersByConsortium(
  $consortiumID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEndUserFilterInput
  $limit: Int
  $nextToken: String
) {
  endUsersByConsortium(
    consortiumID: $consortiumID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      email
      userType
      consortiumID
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EndUsersByConsortiumQueryVariables,
  APITypes.EndUsersByConsortiumQuery
>;
