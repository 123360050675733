/* eslint-disable react/jsx-props-no-spreading */
import { LoadingButton } from "@mui/lab";
// material
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Icon } from "@iconify/react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import useShowMessage from "../../utils/hooks";

export default function PasswordForgotForm() {
  const showMessage = useShowMessage();
  const [isSubmittingNewPassword, setIsSubmittingNewPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email deve essere valida")
      .required("Inserire l'indirizzo email"),
  });

  const formikEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, fk) => {
      try {
        await Auth.forgotPassword(values.email);
        showMessage(
          "Controllare la tua email per il reset della password",
          "success"
        );
        setIsSubmittingNewPassword(true);
        setShowOtp(true);
      } catch (error: any) {
        console.log(error);
        showMessage("Email non riconosciuta, riprovare", "error");
      }
      fk.setSubmitting(false);
    },
  });

  return (
    <Stack spacing={3}>
      {!showOtp ? (
        <FormikProvider value={formikEmail}>
          <Form noValidate onSubmit={formikEmail.handleSubmit}>
            <Stack spacing={1}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Indirizzo email"
                {...formikEmail.getFieldProps("email")}
                error={Boolean(
                  formikEmail.touched.email && formikEmail.errors.email
                )}
                helperText={
                  formikEmail.touched.email && formikEmail.errors.email
                }
              />
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                disabled={isSubmittingNewPassword}
                variant="contained"
                loading={formikEmail.isSubmitting}
              >
                Invia codice di verifica
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      ) : (
        <FormOtp email={formikEmail.values.email} />
      )}
    </Stack>
  );
}

function FormOtp({ email }: { email: string }) {
  const showMessage = useShowMessage();
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    code: Yup.string().required("Inserire il codice di verifica"),
    password: Yup.string()
      .required("Inserire la nuova password")
      .min(8, "Deve contenere almeno 8 caratteri"),
  });
  const formikNewPassword = useFormik({
    initialValues: {
      code: "",
      password: "",
    },
    validationSchema: formSchema,
    onSubmit: async (values, fk) => {
      try {
        const response = await Auth.forgotPasswordSubmit(
          email,
          values.code,
          values.password
        );
        showMessage("Password aggiornata con successo", "success");
        navigate("/login", { replace: true });
      } catch (error: any) {
        console.log(error);
        switch (error.code) {
          case "CodeMismatchException":
            showMessage("Codice di verifica inserito errato", "error");
            break;
          case "InvalidPasswordException":
            showMessage(
              "Password inserita deve contenere numeri e lettere",
              "error"
            );
            break;
          default:
            showMessage(error.message, "error");
        }
        fk.setSubmitting(false);
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormikProvider value={formikNewPassword}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={formikNewPassword.handleSubmit}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="code-verify"
            label="Codice"
            {...formikNewPassword.getFieldProps("code")}
            error={Boolean(
              formikNewPassword.touched.code && formikNewPassword.errors.code
            )}
            helperText={
              formikNewPassword.touched.code && formikNewPassword.errors.code
            }
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...formikNewPassword.getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(true)} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              formikNewPassword.touched.password &&
                formikNewPassword.errors.password
            )}
            helperText={
              formikNewPassword.touched.password &&
              formikNewPassword.errors.password
            }
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // disabled={!isSubmittingNewPassword}
            loading={formikNewPassword.isSubmitting}
          >
            Cambia password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
